import React from "react";
import { Star } from "../../../../config/common/common";
import moment from "moment/moment";

export default function LocaliteProfile({ guideDetailData }) {
  const hostYear = moment()
    .subtract(
      guideDetailData?.guideDetails?.user_detail?.host_since_years,
      "years"
    )
    .format("YYYY");

  return (
    <div>
      <div className="row g-3">
        <div className="col-md-2">
          <div className="prfilePhoto">
            <img
              src={guideDetailData?.guideDetails?.user_detail?.profile_picture}
              alt="img"
            />
          </div>
        </div>
        <div className="col-md-10">
          <h4 className="m-0">
            {guideDetailData?.guideDetails?.last_name
              ? `${guideDetailData?.guideDetails?.name} ${guideDetailData?.guideDetails?.last_name}`
              : guideDetailData?.guideDetails?.name}
          </h4>
          <p className="m-0">Hosted since {hostYear}</p>
          {guideDetailData?.AvgRatings > 0 && (
            <Star stars={guideDetailData?.AvgRatings} />
          )}

          <div className="pt-4">
            <p>{guideDetailData?.guideDetails?.user_detail?.bio}</p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
