import React from 'react';
import Banner from './section/banner/Banner';
import BlogWrapper from './section/blogwrapper/BlogWrapper';
import ContactUs from '../../homePage/section/contactUs/ContactUs';
import './DetailedBlog.css';

const DetailedBlog = () => {
  return (
    <>
      <Banner />
      <BlogWrapper />
      <ContactUs />
    </>
  )
}

export default DetailedBlog;
