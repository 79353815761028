import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CancelTripModal from "./modal/CancelTripModal";
import { useState } from "react";
import { getApi } from "../../../../../../../config/apiFunctions";
import {
  traveler_dashboard_count,
  traveler_todays_trip,
} from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import { Modal } from "react-bootstrap";

const Userdashboardpage = () => {
  const [showCancelModal, setCancelModal] = useState(false);
  const [allActivities, setAllActivities] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [data, setData] = useState({});
  const [count, setCount] = useState(null);
  const [id, setId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [PageLoading, setPageLoading] = useState(true);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const showPopup = (id) => {
    setCancelModal(true);
    setId(id);
  };
  const ScheduledTrip = () => {
    setLoading(true);
    getApi(`${traveler_todays_trip}?number_of_rows=5`, {}, access_token).then(
      (res) => {
        setData(res.data.data);
        setLoading(false);
      }
    );
  };

  const handleDetailModal = (list) => {
    setShowDetailsModal(true);
    setAllActivities(list);
  };

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 300);
    ScheduledTrip();
    getCounts();
  }, [showCancelModal]);
  const getCounts = () => {
    getApi(traveler_dashboard_count, {}, access_token).then((res) => {
      setCount(res.data.data);
    });
  };

  return (
    <>
      {PageLoading === true && (
        <div
          className="loader_wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            // marginTop: "auto",
            // marginBottom: "auto",
            margin: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {PageLoading === false && (
        <div className="custom_box">
          <div className="stats_boxes d-flex justify-content-start">
            <div
              className="stats_box bg-green d-flex align-items-start justify-content-start flex-column me-3"
              data-aos="fade-up"
            >
              <div className="stats_icon ms-auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.4098 4C18.3498 4 19.9098 5.57 19.9098 7.5C19.9098 9.39 18.4098 10.93 16.5398 11C16.4598 10.99 16.3698 10.99 16.2798 11"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.16021 14.56C1.74021 16.18 1.74021 18.82 4.16021 20.43C6.91021 22.27 11.4202 22.27 14.1702 20.43C16.5902 18.81 16.5902 16.17 14.1702 14.56C11.4302 12.73 6.92021 12.73 4.16021 14.56Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="stats_content">
                <h6 className="small-text text-black mb-1">Total Trips</h6>
                <span className="stat_price text-black f-700">
                  {count?.totalTrips}
                </span>
              </div>
            </div>
            <div
              className="stats_box bg-purple d-flex align-items-start justify-content-start flex-column mb-0"
              data-aos="fade-up"
            >
              <div className="stats_icon ms-auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.92969 15.8792L15.8797 3.9292"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.1011 18.2791L12.3011 17.0791"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.793 15.5887L16.183 13.1987"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 21.9985H22"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="stats_content">
                <h6 className="small-text text-black mb-1">
                  Total Money Spend
                </h6>
                <span className="stat_price text-black f-700">
                  ${count?.totalMoneySpend}
                </span>
              </div>
            </div>
          </div>
          {Object.keys(data).length !== 0 && (
            <div className="row mt-4">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="booking_header mb-4">
                      <div className="row d-flex align-items-center justify-content-between">
                        <div className="col-md-12 col-12">
                          <h4 className="text-secondary mb-0 f-700">
                            Scheduled Trips
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isLoading === true && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    <Oval
                      height={80}
                      width={80}
                      color="#1f74ca"
                      wrapperStyle={{}}
                      wrapperclassName=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#1f74ca8f"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  </div>
                )}
                {isLoading === false && (
                  <div className="booking_innerbox border-allbox">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="overflow-table" data-aos="fade-up">
                          {data ? (
                            <table className="table custom-design">
                              <thead>
                                <tr className="border-bottom w-100">
                                  <th className="text-secondary f-700">
                                    Tourist Guides
                                  </th>
                                  {/* <th className="text-secondary f-700">
                                    Destination
                                  </th> */}
                                  <th className="text-secondary f-700">
                                    Country
                                  </th>
                                  <th className="text-secondary f-700">
                                    State
                                  </th>
                                  <th className="text-secondary f-700">City</th>
                                  {/* <th className="text-secondary f-700">Time</th> */}
                                  <th className="text-secondary f-700">
                                    Start Date
                                  </th>
                                  <th className="text-secondary f-700">
                                    End Date
                                  </th>
                                  <th className="text-secondary f-700">
                                    No. of Days
                                  </th>
                                  <th></th>
                                  <th className="text-secondary f-700">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.rows?.map((list, i) => (
                                  <tr key={i} className="border-bottom w-100">
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="message_profile d-flex align-items-center justify-content-between me-2">
                                          <img
                                            className="img-fluid"
                                            src={
                                              list.user.user_detail
                                                .profile_picture
                                            }
                                            alt="message-user"
                                          />
                                        </div>
                                        <h6 className="text-secondary mb-0 f-600">
                                          {list.user.last_name
                                            ? `${list.user.name} ${list.user.last_name}`
                                            : list.user.name}
                                        </h6>
                                      </div>
                                    </td>
                                    {/* <td>
                                      <div className="d-flex align-items-center">
                                        <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                              stroke="#1F74CA"
                                              strokeWidth="1.5"
                                            ></path>
                                            <path
                                              d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                              stroke="#1F74CA"
                                              strokeWidth="1.5"
                                            ></path>
                                          </svg>
                                        </span>
                                        <p className="text-secondary mb-0 f-600 text-capitalize">
                                          {list.destination}
                                        </p>
                                      </div>
                                    </td> */}
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {list.country || "---"}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {list.state || "---"}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {list.city || "---"}
                                      </p>
                                    </td>
                                    {/* <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {list.booking_slot_start}
                                      </p>
                                    </td> */}
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {/* {list.booking_start} */}
                                        {moment(
                                          list.booking_start,
                                          "MM-DD-YYYY"
                                        ).format("MM-DD-YYYY")}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {/* {list.booking_end} */}
                                        {moment(
                                          list.booking_end,
                                          "MM-DD-YYYY"
                                        ).format("MM-DD-YYYY")}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="text-secondary mb-0 f-600">
                                        {list.NoOfDays}
                                      </p>
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="icon_box d-flex align-items-center justify-content-center"
                                        onClick={() => handleDetailModal(list)}
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                            stroke="#475467"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                          <path
                                            d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                                            stroke="#475467"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </button>
                                    </td>
                                    {[1, 3, 5, 7, 9].includes(list.status) ? (
                                      <td className="d-flex align-items-center justify-content-between">
                                        <button
                                          className="btn-main btn-red w-100 mw-100"
                                          disabled
                                        >
                                          Cancelled
                                        </button>
                                      </td>
                                    ) : (
                                      <td className="d-flex align-items-center justify-content-between">
                                        {/* {[0, 6].includes(list.status) ? ( */}
                                        <button
                                          className="btn-main btn-red w-100 mw-100"
                                          onClick={() => showPopup(list.id)}
                                        >
                                          Cancel Trip
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <h3 className="d-flex justify-content-center mt-5 mb-5 text-primary">
                              There are no trip scheduled for today.
                            </h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="col-md-4 d-flex">
              <div
                className="white_box_wrapper d-flex align-items-center w-100"
                data-aos="fade-up"
              >
                <div className="heading_box d-flex align-items-center justify-content-between flex-column text-center">
                  <svg
                    width="54"
                    height="55"
                    viewBox="0 0 54 55"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3243 43.714L21.3243 43.714L21.3206 43.7093C21.0716 43.3869 20.7067 43.119 20.333 42.9322C19.9602 42.7458 19.5284 42.615 19.125 42.615H15.75C9.81418 42.615 5 37.8194 5 31.91V29.66V16.205C5 10.2956 9.81418 5.5 15.75 5.5H38.25C44.1858 5.5 49 10.2956 49 16.205V31.91C49 37.8194 44.1858 42.615 38.25 42.615H34.875C34.0189 42.615 33.1975 43.0274 32.6769 43.7125L32.6757 43.714L29.3007 48.1915L29.3 48.1925C28.6381 49.0751 27.7996 49.4775 27 49.4775C26.2004 49.4775 25.3619 49.0751 24.7 48.1925L24.6993 48.1915L21.3243 43.714ZM15.25 25.25C15.25 26.7604 16.4606 28 18 28C19.5394 28 20.75 26.7604 20.75 25.25C20.75 23.7364 19.5136 22.5 18 22.5C16.4864 22.5 15.25 23.7364 15.25 25.25ZM24.25 25.25C24.25 26.7604 25.4606 28 27 28C28.5394 28 29.75 26.7604 29.75 25.25C29.75 23.7364 28.5136 22.5 27 22.5C25.4864 22.5 24.25 23.7364 24.25 25.25ZM33.25 25.25C33.25 26.7604 34.4606 28 36 28C37.5394 28 38.75 26.7604 38.75 25.25C38.75 23.7364 37.5136 22.5 36 22.5C34.4864 22.5 33.25 23.7364 33.25 25.25Z"
                      fill="#1F74CA"
                      stroke="#1F74CA"
                    />
                  </svg>
                  <h4 className="small-text text-black-light my-2 f-600">
                    Message
                  </h4>
                  <p className="extra-small-text text-grey-scale mb-2">
                    Connect, Communicate, Collaborate: Your Gateway to Seamless
                    Interaction!
                  </p>
                  <Link
                    to="/dashboard/messages"
                    className="btn-main btn-primary w-100 mw-100"
                  >
                    Chat
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-8 d-flex mt-4 mt-md-0">
              <div
                className="white_box_wrapper schedule_inner w-100"
                data-aos="fade-up"
              >
                <div className="heading_box d-flex align-items-center justify-content-between">
                  <h5 className="text-secondary mb-0 f-700">All Trips</h5>
                  <Link className="text-grey small-text" to="my-bookings">
                    See All{" "}
                    <i className="extra-small-text ms-1 fa-solid fa-angle-right"></i>
                  </Link>
                </div>
                {Object.keys(data).length !== 0 ? (
                  <div className="schedule_list mt-4">
                    {data?.rows?.map((list, i) => (
                      <div
                        key={i}
                        className="d-flex align-items-center justify-content-start m-1"
                      >
                        <span className="space_bar"></span>
                        <div className="schedule_inner_content min-0">
                          <h6 className="small-text text-dark-light mb-1 f-700">
                            {list.user.last_name
                              ? `${list.user.name} ${list.user.last_name}`
                              : list.user.name}
                          </h6>
                          <p className="extra-small-text text-grey text-overflow mb-1">
                            {/* {list.bookingStart}{" "} */}
                            {moment(list.booking_start, "MM-DD-YYYY").format(
                              "MM-DD-YYYY"
                            )}
                          </p>
                          <p className="extra-small-text text-grey text-overflow mb-0">
                            {list.country}
                            {/* {list.destination} */}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="schedule_list mt-4">
                    <div className="schedule_inner_content min-0">
                      <h6 className="large-text text-dark-light mb-1 f-700 d-flex justify-content-center mt-5">
                        There are no trips scheduled for today.
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <CancelTripModal
            show={showCancelModal}
            onHide={() => setCancelModal(false)}
            id={id}
            access_token={access_token}
          />
        </div>
      )}

      <Modal
        show={showDetailsModal}
        onHide={() => setShowDetailsModal(false)}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <div>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Details</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDetailsModal(false)}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={
                          allActivities?.user?.last_name
                            ? `${allActivities?.user?.name} ${allActivities?.user?.last_name}`
                            : allActivities?.user?.name
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Country
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.country || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        State
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.state || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        City
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.city || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Type
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.family_type || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Activities
                      </label>
                      <textarea
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.activities || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Start Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities?.booking_start,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        End Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities?.booking_end,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Userdashboardpage;
