import React from "react";
import moment from "moment/moment";

export default function WhatYouDo({ postData }) {
  const infoitems = [
    {
      title: `$${postData?.price}`,
      class: "price",
      Heading: "price",
    },
    {
      title: `${postData?.min_people} - ${postData?.max_people}`,
      class: "people",
      Heading: "people",
    },
    {
      title: postData?.transport_type,
      class: "transport",
      Heading: "transport",
    },
    {
      title: postData?.accessibility === true ? "Yes" : "No",
      class: "access",
      Heading: "Accessibility",
    },
    {
      title: postData?.duration,
      class: "duration",
      Heading: "duration",
    },
    {
      title: moment(postData?.starting_time).format("LT"),
      class: "time",
      Heading: "time",
    },
    {
      title: postData?.meeting_point,
      class: "time",
      Heading: "pick-up",
    },
    {
      title: postData?.drop_off_point,
      class: "time",
      Heading: "Drop-off",
    },
  ];

  return (
    <div className="mt-4 mt-md-0">
      <div className="row flex-column-reverse flex-md-row justify-content-between">
        <div className="col-md-7">
          <div className="pt-5 pb-5">
            <h6>What you’ll do</h6>
            <p>{postData?.description}</p>
            <p>{postData?.schedule}</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-5 info_card pt-5">
          <div className="inner_single_card">
            {infoitems.map((item, i) => (
              <div key={i} className={`${item.class}-blk`}>
                <p className="text-capitalize mb-0">{item.Heading}</p>
                <p className="m-0"> {item.title}</p>
              </div>
            ))}
            {/* <div class="pick-drop">
              <div className={`pick-blk`}>
                <p className="mb-2"></p>
                <p className="m-0">{postData?.meeting_point}</p>
              </div>
              <div className={`drop-blk text-end`}>
                <p className="mb-2"></p>
                <p className="m-0">{postData?.drop_off_point}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <hr /> */}
    </div>
  );
}
