import React, { useEffect, useState } from "react";
import AddReviewModal from "../../findGuide/sections/modals/AddReviewModal";
import CancelTripModal from "../../../pages/dashboardPage/userDashboard/modal/CancelTripModal";
import { getApi } from "../../../../../../../config/apiFunctions";
import { mybookings } from "../../../../../../../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import no_result from "../../../../../../../assets/images/no-result.png";
import Pagination from "../../../../../../../config/pagination/Pagination";
import { Oval } from "react-loader-spinner";
import profile from "../../../../../../../assets/images/profile-placeholder1.jpg";
import { TRAVELLER_BOOKINGLIST } from "../../../../../../../redux/slices/TravellerReducer/travellerSlice";
import { Star } from "../../../../../../../config/common/common";
import { ADDREVIEW } from "../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { Link } from "react-router-dom";
import ModalViewbooking from "./ModalViewbooking.js";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const MybookingTable = () => {
  const addReview = useSelector((state) => state.touristGuide.addReview);
  const User = useSelector((state) => state.auth.SignupUser);
  const [showAddReview, setshowAddReview] = useState(false);
  const [showCancelModal, setCancelModal] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [reviewmodalShow, setReviewModalShow] = useState(false);
  const [mybooking, setMybooking] = useState();
  const [bookingID, setbookingID] = useState([]);
  const [squery, setSquery] = useState("");
  const [ReviewId, setReviewId] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [allActivities, setAllActivities] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [minPageLimit, setMinPageLimit] = useState(0);
  //pagination...........
  const [countPage, setCountPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const currentRecords = mybooking ? mybooking?.slice(0, 11) : [];
  const nPages = countPage ? Math.ceil(countPage / recordsPerPage) : [];

  const dispatch = useDispatch();

  const showPopup = (id) => {
    setbookingID(id);
    setCancelModal(true);
  };
  const viewdata = (data) => {
    //  setShow(true);
    //  setData(data);
  };

  const openModal = (data) => {
    setShowModal(true);
    setAllActivities(data);
  };
  const getMybookings = () => {
    setLoading(true);
    const access_token = User.access_token;
    getApi(
      `${mybookings}?page_no=${currentPage}&booking_status=[0,1,3,4]&search_text=${encodeURIComponent(
        squery
      )}`,
      {},
      access_token
    )
      .then((res) => {
        setLoading(false);
        setCountPage(res.data.data.count);
        setMybooking(res.data.data.rows);
        dispatch(TRAVELLER_BOOKINGLIST(res.data.data.rows));
      })
      .catch((err) => {});
    dispatch(ADDREVIEW(0));
  };
  useEffect(() => {
    setReviewId(null);
    getMybookings();
  }, [showCancelModal, showAddReview, currentPage, squery, addReview]);

  //search query
  // const filterbyDestinations = () => {
  //   const filter = mybooking?.filter((item) =>
  //     item.destination.toLowerCase().includes(squery.toLowerCase())
  //   );
  //   if (squery) {
  //     setMybooking(filter);
  //   }
  // };
  // useEffect(() => {
  //   filterbyDestinations();
  //   if (squery.length === 0) {
  //     getMybookings();
  //   }
  // }, [squery]);
  const handleReview = (id) => {
    setReviewId(id);
    setReviewModalShow(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="p-20 border-bottom">
            <div className="position-relative">
              <input
                className="form-control"
                type="text"
                name="search"
                placeholder="Search by Country, State, City"
                maxLength="10"
                onChange={(e) => {
                  setSquery(e.target.value);
                  setCurrentPage(1);
                }}
              />
              <button className="btn-search" style={{ cursor: "unset" }}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M22 22.5L20 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div
            className={
              isLoading === true
                ? "overflow-table show_booking_table loader"
                : "overflow-table show_booking_table"
            }
            style={
              countPage > 10
                ? { height: "calc(100vh - 274px)" }
                : { height: "calc(100vh - 215px)" }
            }
          >
            {isLoading === true ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#1f74ca"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1f74ca8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : (
              <>
                {mybooking ? (
                  <>
                    <table
                      className="table custom-design mb-0"
                      data-aos="fade-up"
                    >
                      <thead>
                        <tr className="border-bottom w-100">
                          <th className="text-secondary f-700">
                            Tourist Guides
                          </th>
                          {/* <th className="text-secondary f-700">Destination</th> */}
                          <th className="text-secondary f-700">Country</th>
                          <th className="text-secondary f-700">State</th>
                          <th className="text-secondary f-700">City</th>
                          {/* <th className="text-secondary f-700">
                            Time Duration{" "}
                          </th> */}
                          <th className="text-secondary f-700">Start Date</th>
                          <th className="text-secondary f-700">End Date</th>
                          <th className="text-secondary f-700">No. of Days</th>
                          <th className="text-secondary f-700"></th>
                          <th className="text-secondary f-700">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentRecords?.map((data, i) => {
                          return (
                            <tr key={i} className="border-bottom w-100">
                              <td onClick={() => viewdata(data)}>
                                <div className="d-flex align-items-center">
                                  <div className="message_profile d-flex align-items-center justify-content-between me-2">
                                    <img
                                      className="img-fluid"
                                      src={
                                        data.user.user_detail.profile_picture
                                          ? data.user.user_detail
                                              .profile_picture
                                          : profile
                                      }
                                      alt="tourist-guide"
                                    />
                                  </div>
                                  <h6 className="text-secondary mb-0 f-600">
                                    {data.user.last_name
                                      ? `${data.user.name} ${data.user.last_name}`
                                      : data.user.name}
                                  </h6>
                                </div>
                              </td>
                              {/* <td onClick={() => viewdata(data)}>
                                <div className="d-flex align-items-center  text-capitalize">
                                  <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                        stroke="#1F74CA"
                                        strokeWidth="1.5"
                                      ></path>
                                      <path
                                        d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                        stroke="#1F74CA"
                                        strokeWidth="1.5"
                                      ></path>
                                    </svg>
                                  </span>
                                  <p className="text-secondary mb-0 f-600">
                                    {data.destination}
                                  </p>
                                </div>
                              </td> */}
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {data.country || "---"}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {data.state || "---"}
                                </p>
                              </td>
                              <td>
                                <p className="text-secondary mb-0 f-600">
                                  {data.city || "---"}
                                </p>
                              </td>
                              {/* <td>
                                <p className="text-secondary mb-0 f-600">
                                  {` ${data.booking_slot_start.replace(
                                    ":00",
                                    ""
                                  )} - ${data.booking_slot_end.replace(
                                    ":00",
                                    ""
                                  )}`}
                                </p>
                              </td> */}
                              <td onClick={() => viewdata(data)}>
                                <p className="text-secondary mb-0 f-600">
                                  {/* {data.booking_start} */}
                                  {moment(
                                    data.booking_start,
                                    "MM-DD-YYYY"
                                  ).format("MM-DD-YYYY")}
                                </p>
                              </td>
                              <td onClick={() => viewdata(data)}>
                                <p className="text-secondary mb-0 f-600">
                                  {/* {data.booking_end} */}
                                  {moment(
                                    data.booking_end,
                                    "MM-DD-YYYY"
                                  ).format("MM-DD-YYYY")}
                                </p>
                              </td>
                              <td onClick={() => viewdata(data)}>
                                <p className="text-secondary mb-0 f-600">
                                  {data.NoOfDays}
                                </p>
                              </td>

                              <td>
                                <button
                                  onClick={() => openModal(data)}
                                  className="icon_box d-flex align-items-center justify-content-center me-2"
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.3866 7.99995C10.3866 9.31995 9.31995 10.3866 7.99995 10.3866C6.67995 10.3866 5.61328 9.31995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C9.31995 5.61328 10.3866 6.67995 10.3866 7.99995Z"
                                      stroke="#475467"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                                      stroke="#475467"
                                      strokeWidth="1.2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              </td>

                              <td className="d-flex align-items-center justify-content-start">
                                {(data.status === 0 ||
                                  (data.is_completed === 0 &&
                                    data.status === 4)) && (
                                  <a
                                    className="btn-main btn-red w-100 mw-100"
                                    onClick={() => showPopup(data.id)}
                                  >
                                    Cancel Trip
                                  </a>
                                )}
                                {(data.status === 1 || data.status === 3) && (
                                  <a
                                    className="btn-main btn-red w-100 mw-100"
                                    onClick={() => viewdata(data)}
                                  >
                                    Cancelled
                                  </a>
                                )}
                                {data.status === 4 &&
                                  data.is_completed === 1 && (
                                    <td className="d-flex align-items-center justify-content-between flex-column">
                                      <h6
                                        className="text-green v-middle mb-2 f-500 d-flex align-items-center"
                                        onClick={() => viewdata(data)}
                                      >
                                        <svg
                                          onClick={() => viewdata(data)}
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z"
                                            stroke="#12B76A"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                          <path
                                            d="M5.8125 9L7.935 11.1225L12.1875 6.8775"
                                            stroke="#12B76A"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>
                                        </svg>
                                        <span className="ms-2">Completed</span>
                                      </h6>
                                      {data.user.givenRating !== null ? (
                                        <h6 onClick={() => viewdata(data)}>
                                          <Star
                                            stars={parseFloat(
                                              data.user.givenRating
                                            ).toFixed(1)}
                                          />
                                        </h6>
                                      ) : (
                                        <button
                                          onClick={() => handleReview(data.id)}
                                          className="btn-main btn-primary w-100 mw-100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#review_popup"
                                        >
                                          Write a review
                                        </button>
                                      )}
                                    </td>
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <AddReviewModal
                        show={reviewmodalShow}
                        onHide={() => setReviewModalShow(false)}
                        id={ReviewId}
                      />
                    </table>
                  </>
                ) : (
                  <section className="no-result">
                    <div className="container spacer-y pt-0">
                      <div className="row justify-content-center text-center">
                        <div className="col-sm-6 col-lg-4">
                          <img
                            src={no_result}
                            className="mb-3"
                            alt=""
                            data-aos="fade-up"
                          />
                          <h2
                            className="primary__color mb-3 p-0"
                            data-aos="fade-up"
                            data-aos-delay="100"
                          >
                            No Results Found
                          </h2>
                          <p data-aos="fade-up" data-aos-delay="200">
                            Try again.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {isLoading === false && (
        <div className="row">
          <div className="col-md-12">
            {countPage > 10 && (
              <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                <div className="data_count p-20 text-grey small-text f-400">
                  <span>
                    Showing
                    <span className="mx-1 f-500">{10 * currentPage - 9}</span>to
                    <span className="mx-1 f-500">
                      {10 * currentPage - (10 - mybooking?.length)}
                    </span>
                    of
                    <span className="mx-1 f-500">{countPage}</span>entries
                  </span>
                </div>
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageNumberLimit={pageNumberLimit}
                  maxPageLimit={maxPageLimit}
                  minPageLimit={minPageLimit}
                  setMinPageLimit={setMinPageLimit}
                  setMaxPageLimit={setMaxPageLimit}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <div>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Details</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Name
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={
                          allActivities?.user?.last_name
                            ? `${allActivities?.user?.name} ${allActivities?.user?.last_name}`
                            : allActivities?.user?.name
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Country
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.country || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        State
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.state || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        City
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.city || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Type
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.family_type || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        No of Days
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.NoOfDays || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Activities
                      </label>
                      <textarea
                        disabled
                        className={`form-control `}
                        defaultValue={allActivities?.activities || "---"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Start Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities?.booking_start,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        End Date
                      </label>
                      <input
                        disabled
                        className={`form-control `}
                        defaultValue={moment(
                          allActivities.booking_end,
                          "MM-DD-YYYY"
                        ).format("MM-DD-YYYY")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <AddReviewModal
        show={showAddReview}
        onHide={() => setshowAddReview(false)}
      />
      <CancelTripModal
        show={showCancelModal}
        onHide={() => setCancelModal(false)}
        id={bookingID}
        access_token={User.access_token}
      />
      {/* <ModalViewbooking show={show} onHide={() => setShow(false)} data={data}/> */}
    </>
  );
};

export default MybookingTable;
