import React, { useEffect, useState } from "react";
import DragDrop from "../../../assets/images/drag_drop.svg";
import DefaultPicture from "../../../assets/images/defaultUserImg.svg";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteApi, getApi, putApi } from "../../../config/apiFunctions";
import { Oval } from "react-loader-spinner";
import {
  delete_cover_image,
  editGuideProfilePic,
  get_activities,
  guide_get_profile,
  updateGuideProfile,
  upload_cover_image,
} from "../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { SIGNUP_USER_DETAILS } from "../../../redux/slices/auth/LoginSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Multiselect from "multiselect-react-dropdown";

export default function EditGuideProfile() {
  const User = useSelector((state) => state.auth.SignupUser);
  const [loading, setLoading] = useState(false);
  const [guideDetailData, setGuideDetailData] = useState();
  const [btndisable, setBtnDisable] = useState(false);
  const [hostYear, setHostYear] = useState();
  const [hostMonth, setHostMonth] = useState();
  const [acitvityList, setActivityList] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedActivityID, setSelectedActivityID] = useState();
  const [selectedActivityValueID, setSelectedActivityValueID] = useState();
  const [selectedActivityValue, setSelectedActivityValue] = useState();
  const [activityError, setActivityError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [state, setStateSelect] = useState([]);
  const [city, setCity] = useState([]);
  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });

  const [addressState, setAddressState] = useState({
    country: User.country,
    state: User.state,
    city: User.city,
  });

  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const pronouns = [{ name: "He/Him" }, { name: "She/Her" }];
  const formOptions = {
    resolver: yupResolver(Schema),
    defaultValues: {
      country: User.country,
      state: User.state,
      city: User.city,
    },
  };
  const { register, handleSubmit, formState, reset, control, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const getActivitiesList = () => {
    getApi(get_activities).then((res) => {
      if (res.status === 200) {
        setActivityList(res?.data?.data);
      }
    });
  };

  const options = acitvityList?.rows;

  const [selectedValue, setSelectedValue] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
    setSelectedActivity(selectedList?.map((item) => item.name));
    setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
    setSelectedActivity(selectedList?.map((item) => item.name));
    setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  useEffect(() => {
    // Map API response to options format
    const defaultSelectedValues = selectedActivityValue
      ?.map((item) => options.find((option) => option.name === item?.title))
      .filter(Boolean); // Filter out any undefined values

    setSelectedValue(defaultSelectedValues);
  }, [selectedActivityValue, options]);

  const updateCoverImage = (e) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("cover_picture", e.target.files[0]);
    putApi(upload_cover_image, formdata, User.access_token).then((res) => {
      if (res.status === 200) {
        toast({
          title: res?.data?.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
        getGuideDetails();
      }
    });
  };

  const handleRemoveCover = () => {
    setLoading(true);
    deleteApi(`${delete_cover_image}`, {}, User.access_token).then((res) => {
      if (res.status === 200) {
        toast({
          title: res?.data?.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
        getGuideDetails();
      }
    });
  };

  const handleChangeProfile = (e) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("profile_picture", e.target.files[0]);
    putApi(editGuideProfilePic, formdata, User.access_token).then((res) => {
      toast({
        title: res.data.message,
        status: "success",
        duration: 30000,
        isClosable: true,
        position: "top-right",
      });
      const updatedUser = {
        ...User,
        profile_picture: res?.data?.data?.image_url,
      };
      dispatch(SIGNUP_USER_DETAILS(updatedUser));
      getGuideDetails();
      setLoading(false);
    });
  };

  const getGuideDetails = () => {
    setLoading(true);
    getApi(guide_get_profile, {}, User.access_token).then((res) => {
      if (res.status === 200) {
        setGuideDetailData(res?.data?.data);
        setLoading(false);
        const { data } = res?.data?.data;

        updateAddress(data.country, data.state);
        setAddressState({
          ...addressState,
          country: data.country,
          state: data.state,
          city: data.city,
        });
      }
    });
  };

  const updateAddress = async (con, stat) => {
    await GetCountries().then(async (countries) => {
      const country = countries.find((country) => country.name === con);
      setCountryId(country.id);
      await GetState(country.id).then(async (stateList) => {
        setStateSelect(stateList);
        const state = stateList.find((state) => state.name === stat);
        await GetCity(country.id, state.id).then((result) => {
          setCity(result);
        });
      });
    });
  };

  const setCityList = async (event) => {
    const { value } = event.target;
    setAddressState({
      ...addressState,
      city: value,
    });
  };
  const getCityList = async (event) => {
    setGetLoader({ ...getCityLoader, ["city"]: true });
    const { value } = event.target;
    const selectedState = state.find((state) => state.name === value);
    await GetCity(countryId, selectedState.id).then((result) => {
      setCity(result);
      setAddressState({
        ...addressState,
        state: value,
        city: result[0]?.name,
      });
    });
    setGetLoader({ ...getCityLoader, ["city"]: false });
  };
  const getStateList = async (event) => {
    // setAddressState({...addressState,})
    // console.log(event, "heyyyyyyy");
    setGetLoader({ ...getCityLoader, ["state"]: true });
    const { value } = event.target;
    // setCity([]);
    const country = countries.find((country) => country.name === value);
    setCountryId(country.id);
    await GetState(country.id).then(async (stateList) => {
      setStateSelect(stateList);
      setGetLoader({ ...getCityLoader, ["city"]: true });
      await GetCity(country.id, stateList[0]?.id).then((cityList) => {
        setCity(cityList);
        setAddressState({
          ...addressState,
          country: value,
          state: stateList[0]?.name,
          city: cityList[0]?.name,
        });
      });
      setGetLoader({ ...getCityLoader, ["city"]: false });
    });
    setGetLoader({ ...getCityLoader, ["state"]: false });
  };

  const getDefaultAddress = async () => {
    await GetCountries().then(async (countries) => {
      setCountries(countries);
      let countryQuery = User.country ? User.country : "United States";
      const country = countries.find(
        (country) => country.name === countryQuery
      );
      setCountryId(country.id);
      await GetState(country.id).then(async (states) => {
        setStateSelect(states);
        let stateQuery = User.state ? User.state : states[0].name;
        const state = states.find((state) => state.name === stateQuery);
        await GetCity(country.id, state.id).then((result) => {
          setCity(result);
        });
      });
    });
  };

  const incrementHostSince = () => {
    setHostYear((prevYear) => prevYear + 1);
    setValue("hostSinceYear", hostYear + 1);
  };

  const incrementHostSinceMonth = () => {
    if (hostMonth < 11) {
      setHostMonth((prevMonth) => prevMonth + 1);
      setValue("hostSinceMonth", hostMonth + 1);
    }
  };
  const decrementHostSince = () => {
    if (hostYear > 0) {
      setHostYear((prevYear) => prevYear - 1);
      setValue("hostSinceYear", hostYear - 1);
    }
  };
  const decrementHostSinceMonth = () => {
    if (hostMonth > 0) {
      setHostMonth((prevMonth) => prevMonth - 1);
      setValue("hostSinceMonth", hostMonth - 1);
    }
  };
  const handleInputChangeYear = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setHostYear(value);
    }
  };
  const handleInputChangeMonth = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setHostMonth(value);
    }
  };

  const onSubmit = (e) => {
    setBtnDisable(true);
    const access_token = User?.access_token;
    if (selectedActivityID?.length === 0) {
      setBtnDisable(false);
      setActivityError(true);
    } else {
      const formData = new FormData();
      formData.append("name", e.name);
      if (e.last_name !== undefined) {
        formData.append("last_name", e.last_name);
      }
      formData.append("phone", User?.phone);
      formData.append("country_code", User?.country_code);
      formData.append("country_code_iso", User?.country_code_iso);
      formData.append("country", e.country);
      formData.append("state", e.state);
      formData.append("city", e.city);
      formData.append("bio", e.bio);
      // formData.append("pronouns", e.pronouns);
      if (e.pronouns) {
        formData.append("pronouns", e.pronouns);
      }
      formData.append("host_since_years", hostYear);
      formData.append("host_since_months", hostMonth);

      if (selectedActivityID) {
        for (let i = 0; i < selectedActivityID.length; i++) {
          formData.append("activities", selectedActivityID[i]);
        }
      } else {
        for (let i = 0; i < selectedActivityValueID.length; i++) {
          formData.append("activities", selectedActivityValueID[i]);
        }
      }

      formData.append("price", User?.price || 23);
      formData.append("currency", "USD");
      formData.append("pincode", User?.pincode);
      putApi(updateGuideProfile, formData, access_token)
        .then((res) => {
          const updatedUser = {
            ...User,
            name: e.name,
            last_name: e.last_name,
            bio: e.bio,
            country: e.country,
            state: e.state,
            city: e.city,
            pronouns: e.pronouns,
            hostYear: hostYear,
            hostMonth: hostMonth,
            activities: selectedActivityValueID || selectedActivityID,
          };
          dispatch(SIGNUP_USER_DETAILS(updatedUser));
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
          setBtnDisable(false);
          navigate(`/guide_detailed_page/${User?.id}`);
        })
        .catch((err) => {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
          setBtnDisable(false);
        });
      setBtnDisable(true);
    }
  };

  useEffect(() => {
    getDefaultAddress();
    getGuideDetails();
    getActivitiesList();
  }, []);

  useEffect(() => {
    setValue("name", guideDetailData?.guideDetails?.name);
    setValue("last_name", guideDetailData?.guideDetails?.last_name);
    setValue("bio", guideDetailData?.guideDetails?.user_detail?.bio);
    setValue(
      "hostSinceYear",
      parseInt(guideDetailData?.guideDetails?.user_detail?.host_since_years)
    );
    setValue(
      "hostSinceMonth",
      parseInt(guideDetailData?.guideDetails?.user_detail?.host_since_months)
    );
    setValue(
      "pronouns",
      guideDetailData?.guideDetails?.user_detail?.pronouns
        ? guideDetailData?.guideDetails?.user_detail?.pronouns
        : ""
    );
    setHostMonth(
      parseInt(guideDetailData?.guideDetails?.user_detail?.host_since_months)
    );
    setHostYear(
      parseInt(guideDetailData?.guideDetails?.user_detail?.host_since_years)
    );

    setSelectedActivityValue(
      guideDetailData?.guideDetails?.guide_activities?.map(
        (item) => item.activity
      )
    );
    setSelectedActivityID(
      guideDetailData?.guideDetails?.guide_activities?.map(
        (item) => item.activity?.id
      )
    );
    setSelectedActivityValueID(
      guideDetailData?.guideDetails?.guide_activities?.map(
        (item) => item.activity?.id
      )
    );
  }, [guideDetailData, setValue]);

  useEffect(() => {
    if (selectedActivityID?.length > 0 || selectedActivityValueID?.length > 0) {
      setActivityError(false);
    }
  }, [selectedActivityID, selectedActivityValueID]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            margin: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div className="edit_localite_banner">
            {guideDetailData?.guideDetails?.user_detail?.cover_picture ? (
              <>
                <img
                  src={guideDetailData.guideDetails.user_detail.cover_picture}
                  alt="BannerImg"
                />
                {User.role_name === "GUIDE" && (
                  <div className="delete_img" onClick={handleRemoveCover}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="rgba(136,22,22,1)"
                    >
                      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                    </svg>
                  </div>
                )}
              </>
            ) : User.role_name === "GUIDE" ? (
              <label
                className="upload_media_label w-100 text-center d-flex flex-column align-items-center justify-content-center p-30 rounded-0 h-100"
                htmlFor="coverpicture"
              >
                <img src={DragDrop} className="drag_icon" alt="" />
                <p className="small-text fw-bold lh-24 m-0">
                  Click to upload cover picture
                </p>
                <input
                  name="coverpicture"
                  id="coverpicture"
                  className="d-none"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={updateCoverImage}
                />
              </label>
            ) : (
              <img src={DefaultPicture} alt="BannerImg" />
            )}

            <div className="edit_host_img">
              <img
                src={
                  guideDetailData?.guideDetails?.user_detail?.profile_picture ||
                  DefaultPicture
                }
                alt="Host Img"
              />
              {User.role_name === "GUIDE" && (
                <label
                  className=" w-100 text-center d-flex flex-column align-items-center justify-content-center p-30"
                  htmlFor="profilePicture"
                >
                  <div className="edit_hero_imgbtn" role="button">
                    <span className="edit_hero_img">
                      <i className="fa-regular fa-camera"></i>
                      <input
                        name="profilePicture"
                        id="profilePicture"
                        className="d-none"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleChangeProfile}
                      />
                    </span>
                  </div>
                </label>
              )}
            </div>
          </div>

          <div className="container spacer-y contact-wrapper">
            <form className="traveler-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        First Name
                      </label>

                      <input
                        {...register("name")}
                        type="text"
                        name="name"
                        placeholder="First Name"
                        className={`form-control theme-controls ${
                          errors.name ? "is-invalid" : ""
                        } `}
                      />
                      <div className="invalid-feedback">
                        {errors.name?.message}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Last Name
                      </label>

                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        {...register("last_name")}
                        className={`form-control theme-controls ${
                          errors.last_name ? "is-invalid" : ""
                        } `}
                      />
                      <div className="invalid-feedback">
                        {errors.last_name?.message}
                      </div>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Host Since
                      </label>
                      <div className="row g-2">
                        <div className="col-md-6">
                          <div className="position-relative">
                            <input
                              id="numberInput2"
                              type="number"
                              name="hostSinceYear"
                              placeholder="0"
                              {...register("hostSinceYear", {
                                onChange: handleInputChangeYear,
                              })}
                              className={`form-control theme-controls ${
                                errors.hostSinceYear ? "is-invalid" : ""
                              }`}
                              maxLength={10}
                              // onPaste={(event) => preventPaste(event)}
                            />
                            <div className="invalid-feedback">
                              {errors?.hostSinceYear?.message}
                            </div>
                            <span className="placeholder__text host-button">
                              Years
                            </span>
                            <div
                              onClick={decrementHostSince}
                              type="button"
                              className="host-button decrement"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </div>
                            <div
                              onClick={incrementHostSince}
                              type="button"
                              className="host-button increment"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="position-relative">
                            <input
                              id="numberInput2"
                              type="number"
                              name="hostSinceMonth"
                              placeholder="0"
                              {...register("hostSinceMonth", {
                                onChange: handleInputChangeMonth,
                              })}
                              className={`form-control theme-controls ${
                                errors.hostSinceMonth ? "is-invalid" : ""
                              }`}
                              // onPaste={(event) => preventPaste(event)}
                            />
                            <div className="invalid-feedback">
                              {errors?.hostSinceMonth?.message}
                            </div>
                            <span className="placeholder__text host-button">
                              Months
                            </span>
                            <div
                              onClick={decrementHostSinceMonth}
                              type="button"
                              className="host-button decrement"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </div>
                            <div
                              onClick={incrementHostSinceMonth}
                              type="button"
                              className="host-button increment"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Pronouns(optional)
                      </label>

                      <div className="position-relative">
                        <select
                          {...register("pronouns")}
                          defaultValue=""
                          type="text"
                          className={`form-control theme-controls ${
                            errors.pronouns ? "is-invalid" : ""
                          }`}
                        >
                          <option hidden value="" disabled>
                            Select
                          </option>
                          {pronouns.map((item, index) => {
                            return (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="host-button increment">
                          <i className="fa-solid fa-angle-down"></i>
                        </div>

                        <div className="invalid-feedback">
                          {errors.pronouns?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Bio
                      </label>
                      <textarea
                        type="text"
                        name="bio"
                        rows={4}
                        placeholder="Enter bio"
                        {...register("bio")}
                        className={`form-control theme-controls ${
                          errors.bio ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.bio?.message}
                      </div>
                    </div>

                    <div className="col-lg-12 mb-4">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Activities
                      </label>

                      <Multiselect
                        options={options}
                        selectedValues={selectedValue}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        showCheckbox={true}
                      />

                      {activityError && (
                        <div className="location_error">
                          Activity is required
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12 mb-4">
                      <label
                        className="form-label theme-label color__paragraph"
                        htmlFor="email"
                      >
                        Country
                      </label>
                      <select
                        {...register("country")}
                        onChange={(e) => getStateList(e)}
                        id="user-profile"
                        name="country"
                        value={addressState.country}
                        className={`form-control theme-controls  ${
                          errors.country ? "is-invalid" : ""
                        }`}
                      >
                        {getCityLoader.country && (
                          <option value={""}>Loading...</option>
                        )}

                        {/* <option defaultValue={User?.country}>
                          {User?.country}
                        </option> */}
                        {countries.length > 0 &&
                          countries.map((country, index) => {
                            return (
                              <option value={country.name} key={index}>
                                {country.name}
                              </option>
                            );
                          })}
                      </select>
                      <div className="invalid-feedback">
                        {errors.country?.message}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div
                        id="user-profile"
                        className="input-box d-flex align-items-start justify-content-start flex-column mb-4"
                      >
                        <label
                          className="form-label theme-label color__paragraph"
                          htmlFor="name"
                        >
                          State
                        </label>
                        <select
                          {...register("state")}
                          id="user-profile"
                          onChange={(e) => getCityList(e)}
                          name="state"
                          value={addressState?.state}
                          className={`form-control theme-controls  ${
                            errors.state ? "is-invalid" : ""
                          }`}
                        >
                          {getCityLoader.state && (
                            <option value={""}>Loading...</option>
                          )}
                          {/* 
                          <option defaultValue={User?.state}>
                            {User?.state}
                          </option> */}

                          {state.length > 0 &&
                            state.map((state, index) => {
                              return (
                                <option value={state.name} key={index}>
                                  {state.name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          {errors.state?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                        <label
                          className="form-label theme-label color__paragraph"
                          htmlFor="Last name"
                        >
                          City
                        </label>
                        <select
                          {...register("city")}
                          id="user-profile"
                          onChange={setCityList}
                          value={addressState.city}
                          name="city"
                          className={`form-control theme-controls  ${
                            errors.city ? "is-invalid" : ""
                          }`}
                        >
                          {getCityLoader.city && (
                            <option value={""}>Loading...</option>
                          )}

                          {/* <option defaultValue={User?.city}>
                            {User?.city}
                          </option> */}
                          {city.length > 0 &&
                            city.map((city, index) => {
                              return (
                                <option value={city.name} key={index}>
                                  {city.name}
                                </option>
                              );
                            })}
                        </select>
                        <div className="invalid-feedback">
                          {errors.city?.message}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 text-center pt-4">
                      {btndisable === true ? (
                        <button
                          type="submit"
                          disabled={btndisable}
                          className="button-primary email-send-trigger w-100 d-flex justify-content-center"
                        >
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={btndisable}
                          className="button-primary email-send-trigger w-100"
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <div className="col-lg-6 text-center pt-4">
                      <Link
                        to={`/guide_detailed_page/${User?.id}`}
                        className="button-primary primary-outline w-100"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}

const Schema = Yup.object().shape({
  name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  last_name: Yup.string()
    .required("Last name is required")
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s]*$/, "Please enter letters only"),
  bio: Yup.string().required("Bio is required"),
  pronouns: Yup.string(),
  activities: Yup.string(),
  hostSinceYear: Yup.string()
    .required("This field is required")
    .test("is-positive", "The year must be set above zero", (value) => {
      return parseFloat(value) > 0;
    }),
  hostSinceMonth: Yup.string()
    .required("This field is required")
    .test("not-above", "The months are not more than 11", (value) => {
      return parseFloat(value) <= 11;
    })
    .test("is-positive", "The month must be set zero or above", (value) => {
      return parseFloat(value) >= 0;
    }),
  country: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
});
