import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";

function LogOutModal(props) {
  const { isModalOpen, handleModalClose, handleLogout, isSubmit } = props;

  return (
    <Modal
      show={isModalOpen}
      onHide={handleModalClose}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={isModalOpen === true ? "" : "modal fade"}
        id="cancel_trip"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className=" ">
          <div className=" text-center p-5">
            <div className="modal_header position-relative">
              <h4 className="text-grey-500 f-700 mb-2">
                Are you sure you want to log out?
              </h4>
            </div>
            <div className="modal-footer justify-content-center border-0 p-0 pt-3">
              <a className="btn-main btn-red min-w " onClick={handleModalClose}>
                Not now
              </a>
              <button
                className="btn-main btn-primary min-w button-primary"
                type="submit"
                onClick={handleLogout}
              >
                {isSubmit ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "  Logout"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LogOutModal;
