const ReviewsHeader = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="booking_header p-20 border-bottom">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col">
              <h3 className="text-secondary mb-0 f-700">Rating & Reviews</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsHeader;
