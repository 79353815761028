import React, { useEffect, useState } from "react";
import "../header/Header.css";
// import logo from "../../../assets/images/siestalogo.svg";
import profileIcon from "../../../assets/images/profile-icon.svg";
import Icon from "../../../assets/images/profile-placeholder1.jpg";
import logoutIcon from "../../../assets/images/logout-icon.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SIGNUP_USER_DETAILS } from "../../../redux/slices/auth/LoginSlice";
import { persistor } from "../../../redux/store/store";
import { getApi, postApi } from "../../../config/apiFunctions";
import {
  guide_logout,
  traveler_logout,
  website_content,
} from "../../../config/endpoints";
import LogOutModal from "../../../dashboard/LogOutModal";
import {
  HEADER,
  // HEADER_FOOTER,
} from "../../../redux/slices/content/ContentSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const location = useLocation();
  const User = useSelector((state) => state.auth.SignupUser);
  const token = User?.access_token;
  const access_token = token === "undefined" ? undefined : token;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);
  const data = useSelector((state) => state.content.header);
  const waiting_status = useSelector((state) => state.auth.waiting_status);

  const getHeaderData = () => {
    getApi(`${website_content}?key=HEADER_FOOTER&section=LOGO`).then((res) => {
      dispatch(HEADER(res?.data?.data));
    });
  };

  useEffect(() => {
    getHeaderData();
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlechange = () => {
    setToggle(!toggle);
  };

  const logout = () => {
    setisSubmit(true);
    setBtnDisable(true);
    // persistor.purge();
    // navigate("/login");
    let api;
    if (User?.role_name === "GUIDE") {
      api = guide_logout;
    } else if (User?.role_name === "TRAVELLER") {
      api = traveler_logout;
    }
    postApi(api, {}, User.access_token)
      .then(async (res) => {
        setIsModalOpen(false);
        setisSubmit(false);
        // persistor.pause();
        await persistor.purge();
        dispatch(SIGNUP_USER_DETAILS(res.data.data));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/login");
        }, 50);
      })
      .catch(async (error) => {
        setisSubmit(false);
        // persistor.pause();
        await persistor.purge();
        dispatch(SIGNUP_USER_DETAILS({}));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/login");
        }, 50);
      });
  };

  useEffect(() => {
    if (window.innerWidth < 992) {
      if (toggle === true) {
        document.body.classList.toggle("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  });
  return (
    <>
      <header className="navigation primary__bg">
        <div className="container">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <Link to="/" className="navbar-brand">
              <img
                src={data && data?.value?.header?.upload_image_url}
                alt="logo"
              />
            </Link>

            {location?.pathname !== "/join-localites" &&
              location.pathname !== "/login" &&
              location.pathname !== "/signup" &&
              location.pathname !== "/create_guide_profiles" &&
              location.pathname !== "/create_traveler_profile" &&
              location.pathname !== "/create-your-profile" ? (
              <>
                <button
                  className={
                    toggle === true
                      ? "navbar-toggler  active"
                      : "navbar-toggler"
                  }
                  type="button"
                  onClick={handlechange}
                >
                  <span className="navbar-toggler-icon "></span>
                </button>

                <div
                  className={
                    toggle === true
                      ? "navbar-collapse nav-open"
                      : "navbar-collapse"
                  }
                  onClick={handlechange}
                >
                  <ul className="navbar-nav ms-auto mb-3 mb-lg-0 gap-lg-0">
                    {(User?.role_name === "TRAVELLER" || !access_token) && (
                      <li className="nav-item">
                        <Link
                          to="/traveler"
                          className={
                            location.pathname === "/traveler"
                              ? "nav-link text-white me-lg-4 active"
                              : "nav-link text-white me-lg-4"
                          }
                          aria-current="page"
                        >
                          {/* Traveller */}
                          {/* For Travelers */}
                          Traveling?
                        </Link>
                      </li>
                    )}
                    {/* {User.role_name === "GUIDE" && token && (
                      <li className="nav-item">
                        <Link
                          to="/"
                          className={
                            location.pathname === "/"
                              ? "nav-link color__paragraph active"
                              : "nav-link color__paragraph"
                          }
                        >
                          For Localites
                        </Link>
                      </li>
                    )} */}
                    {/* {
                      !access_token && (
                        <li className="nav-item">
                          <Link
                            to="/join-localites"
                            className={
                              location.pathname.includes("/tourist-guide")
                                ? "nav-link color__paragraph active"
                                : "nav-link color__paragraph"
                            }
                          >
                          
                            For Localites
                          </Link>
                        </li>
                      )
                    } */}

                    {User.role_name === "GUIDE" && token && (
                      <>
                        <li className="nav-item">
                          <Link
                            to="/contact-us"
                            className={
                              location.pathname === "/contact-us"
                                ? "nav-link color__paragraph me-lg-4 active"
                                : "nav-link color__paragraph me-lg-4"
                            }
                            aria-current="page"
                          >
                            Contact Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/about-us"
                            className={
                              location.pathname === "/about-us"
                                ? "nav-link color__paragraph active"
                                : "nav-link color__paragraph"
                            }
                            aria-current="page"
                          >
                            About Us
                          </Link>
                        </li>
                      </>
                    )}
                    {waiting_status === false
                      ? User.role_name === "TRAVELLER" && (
                        <li className="nav-item">
                          <Link
                            to="/find-tourist-guide"
                            className={
                              location.pathname === "/find-tourist-guide"
                                ? "nav-link color__paragraph active"
                                : "nav-link color__paragraph"
                            }
                          >
                            {/* Find Tourist Guide */}
                            {/* Find Guides */}
                            Find Localites
                          </Link>
                        </li>
                      )
                      : ""}

                    {!access_token && (
                      <></>
                      // <li className="nav-item">
                      //   <Link
                      //     to="/login"
                      //     className={
                      //       location.pathname === "/find-tourist-guide"
                      //         ? "nav-link color__paragraph active"
                      //         : "nav-link color__paragraph"
                      //     }
                      //   >
                      //     {/* Find Tourist Guide */}
                      //     {/* Find Guides */}
                      //     Find Localites
                      //   </Link>
                      // </li>
                    )}

                    {access_token && (
                      <>
                        <li className="nav-item mobile_link_hide">
                          <Link
                            className="nav-link color__paragraph"
                            to="/dashboard"
                          >
                            Dashboard
                          </Link>
                        </li>
                        {User.role_name === "GUIDE" && (
                          <li className="nav-item mobile_link_hide">
                            <Link
                              className="nav-link color__paragraph"
                              to={`/guide_detailed_page/${User.id}`}
                            >
                              Profile
                            </Link>
                          </li>
                        )}
                        <li className="nav-item mobile_link_hide">
                          <a>
                            <button
                              // onClick={logout}
                              onClick={() => setIsModalOpen(true)}
                              className="profile-view-text"
                              disabled={btndisable}
                            >
                              Log out
                            </button>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>

                  {/* <!-- login signup buttons add "d-none" class to hide --> */}

                  {!access_token ? (
                    <div className="header_btn">
                      <Link
                        to="/traveller-waiting-list"
                        className="button-primary"
                      >
                        Join Our Panel
                      </Link>
                    </div>
                  ) : (
                    <div
                      className={
                        !access_token && !access_token
                          ? "profile-view d-none"
                          : "profile-view"
                      }
                    >
                      <div className="profile-view-image">
                        <img
                          src={
                            !User.profile_picture
                              ? Icon
                              : User?.profile_picture?.toString()
                          }
                          alt=""
                        />
                      </div>
                      <div className="profile-view-content">
                        <p className="mb-1 fw-bold text-white">
                          Hello, {!User ? "" : User.name}
                        </p>
                        <p className="text-sm text-white mb-0 user-role user-role-title">
                          {!User
                            ? ""
                            : User.role_name === "TRAVELLER"
                              ? "Traveler"
                              : "Localite"}
                        </p>
                      </div>
                      <div className="profile-dropdown">
                        <ul className="list-unstyled m-0">
                          {User?.is_verified !== 0 &&
                            User.role_name === "GUIDE" && (
                              <li>
                                <a href="/dashboard">
                                  <div className="profile-view-icon">
                                    <i class="fa-light fa-file-user"></i>
                                  </div>
                                  <div className="profile-view-text">
                                    Account
                                  </div>
                                </a>
                              </li>
                            )}

                          {User.role_name === "TRAVELLER" && (
                            <li>
                              <a href="/dashboard">
                                <div className="profile-view-icon">
                                  <i class="fa-light fa-file-user"></i>
                                </div>
                                <div className="profile-view-text">Account</div>
                              </a>
                            </li>
                          )}

                          {User.role_name === "GUIDE" && (
                            <li>
                              <Link to={`/guide_detailed_page/${User.id}`}>
                                <div className="profile-view-icon">
                                  {/* <img src={profileIcon} alt="" /> */}
                                  <i class="fa-light fa-user"></i>
                                </div>
                                <div className="profile-view-text">Profile</div>
                              </Link>
                            </li>
                          )}
                          <li>
                            <a>
                              <div className="profile-view-icon">
                                <img src={logoutIcon} alt="" />
                              </div>
                              <button
                                // onClick={logout}
                                onClick={() => setIsModalOpen(true)}
                                className="profile-view-text"
                                disabled={btndisable}
                              >
                                Log out
                              </button>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </nav>
        </div>
      </header>
      <LogOutModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleModalClose={handleModalClose}
        handleLogout={logout}
        isSubmit={isSubmit}
      />
    </>
  );
};

export default Header;
