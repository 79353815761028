import { createSlice } from "@reduxjs/toolkit";
// import { current } from "@reduxjs/toolkit";

const initialState = {
  travellerBookingList: [], // Initial state for travellerBookingList
  bookings: 0, // Initial state for bookings
  allNotifications: [],
  unreadNotificationTravller: 0,
  paymentMethodList: [],
};

const travellerSlice = createSlice({
  name: "traveller_slice",
  initialState,
  reducers: {
    TRAVELLER_BOOKINGLIST: (state, action) => {
      return {
        ...state,
        travellerBookingList: action.payload,
      };
    },
    BOOKING: (state, action) => {
      return {
        ...state,
        bookings: { booking: action.payload },
      };
    },
    ALLNOTIFICATIONS: (state, action) => {
      return {
        ...state,
        allNotifications: { Notifications: action.payload },
      };
    },
    UNREAD_NOTIFICATIONS_TRAVELLER: (state, action) => {
      return {
        ...state,
        unreadNotificationTravller: action.payload,
      };
    },
    PAYMENT_METHOD_LIST: (state, action) => {
      return {
        ...state,
        paymentMethodList: action.payload,
      };
    },
  },
});

export const {
  TRAVELLER_BOOKINGLIST,
  BOOKING,
  ALLNOTIFICATIONS,
  UNREAD_NOTIFICATIONS_TRAVELLER,
  PAYMENT_METHOD_LIST,
} = travellerSlice.actions;

export default travellerSlice.reducer;
