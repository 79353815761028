import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import reset_pass from "../../../assets/images/reset_password.png";
import email_send from "../../../assets/images/email-send.svg";
import "../LoginSignup.css";
import { reset_password } from "../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { postApi } from "../../../config/apiFunctions";
import { useNavigate } from "react-router-dom";

const ResetPassword = (props) => {
  
  const navigate = useNavigate();
  const toast = useToast();
  const [togglePass, setTogglePass] = useState(true)
  const [toggleCPass, setCTogglePass] = useState(true)
  const [hidePopup, setHidePopup] = useState(false)
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const onFormSubmit = (e) => {
    delete e.cpassword;
    const obj ={
      ...e,
      token:props.token
    }
    props.setBtnDisable(true);
    postApi(reset_password, obj)
      .then((res) => {
        if (res.status === 200) {
          setHidePopup(true);
          props.setShowReset(false)
          props.setBtnDisable(false);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => props.setBtnDisable(false),
          });
          navigate ('/login')
          reset();
        }
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => props.setBtnDisable(false),

        });
      });
  };
  return (
    <>
    <div
      className={
        props.show === true ? "forgot-pass-popup active" : "forgot-pass-popup"
      }
    >
      <div className="forgot-pass-wrapper zindex-up text-center">
        <div
          className=
          {
            props.hidePopup === true 
              ? "forgot-pass-form d-none"
              : "forgot-pass-form"
          }
        >
          <img src={reset_pass} className="mb-3 mx-auto" alt="" />
          <h4 className="mb-3 p-0">Create New Password</h4>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <div className="row gy-3 gy-md-4 text-start">
            <div className="col-lg-12">
          <label
            htmlFor=""
            className="form-label theme-label color__paragraph"
          >
            Password
          </label>
          <div className="input-icon-grp">
            <input
              type={togglePass === true ? "password" : "text"}
              name="password"
              placeholder="8 character or more"
              {...register("password")}
              className={`form-control theme-controls ${
                errors.password ? "is-invalid" : ""
              }`}
            />
            <i
              className={
                togglePass === true
                  ? "fa-light  fa-eye-slash show-pass"
                  : "fa-light  fa-eye show-pass"
              }
              onClick={()=>setTogglePass(!togglePass)}
            ></i>
            <div className="invalid-feedback">
              {errors.password?.message}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <label
            htmlFor=""
            className="form-label theme-label color__paragraph"
          >
            Confirm Password
          </label>
          <div className="input-icon-grp">
            <input
              type={toggleCPass === true ? "password" : "text"}
              name="cpassword"
              placeholder="8 character or more"
              {...register("cpassword")}
              className={`form-control theme-controls ${
                errors.cpassword ? "is-invalid" : ""
              }`}
            />
            <i
              className={
                toggleCPass === true
                  ? "fa-light  fa-eye-slash show-pass"
                  : "fa-light  fa-eye show-pass"
              }
              onClick={()=>setCTogglePass(!toggleCPass)}
            ></i>
            <div className="invalid-feedback">
              {errors.cpassword?.message}
            </div>
          </div>
        </div>
              <div className="col-lg-12 text-center">
                <button
                  type="submit"
                  disabled={props.btndisable}
                  className="button-primary w-100 email-send-trigger"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="forgot-overlay"></div>
    </div>
    
  </>
  )
}

export default ResetPassword
const Schema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at 8 char long")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    cpassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });