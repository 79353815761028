import React, { useEffect } from 'react'
import ItineraryHeader from './itineraryHeader/ItineraryHeader'
import ItineraryTable from './itineraryTable/ItineraryTable'

const Itinerary = ({onLoadComponent,setOnLoadComponent}) => {
  
  useEffect(()=>{
    setOnLoadComponent(!onLoadComponent)
  },[])

  return (
    <div className="table_fixed table_m itinerary_table booking_innerbox" >
    <ItineraryHeader />
   {/* <ItineraryTable /> */}
</div>
  )
}

export default Itinerary
