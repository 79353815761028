import React, { useEffect } from "react";
import BookinghistoryHeader from "./sections/BookinghistoryHeader";
import BookinghistoryTable from "./sections/BookinghistoryTable";
import { useDispatch } from "react-redux";
import { BOOKING_HISTORY } from "../../../../../../redux/slices/TouristGuideReducer/guideSlice";

const BookingHistory = () => {
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(BOOKING_HISTORY(0))    
  },[])

  return (
    <div>
      <div className="table_fixed booking_innerbox">
        <BookinghistoryHeader />
        {/* <BookinghistoryTable /> */}
      </div>
    </div>
  );
};

export default BookingHistory;
