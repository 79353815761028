import React, { useEffect, useState } from "react";
import "./index.css";
import LocaliteProfile from "./section/LocaliteProfile";
import SpecialExperienceList from "./section/SpecialExperienceList";
import { getApi, postApi } from "../../../config/apiFunctions";
import {
  get_experience_post,
  get_guide_profile_detail,
  guide_get_profile,
  like_post,
  unlike_post,
} from "../../../config/endpoints";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

export default function SpecialExpPage() {
  const User = useSelector((state) => state.auth.SignupUser);
  const [guideDetailData, setGuideDetailData] = useState();
  const [experiencePostData, setExperiencePostData] = useState();
  const [experiencePageNo, setExperiencePageNo] = useState(1);
  const [totalExperiencePageNo, setTotalExperiencePageNo] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const handleLikeUnlike = (id, liked) => {
    const obj = {
      post_id: id,
      liked_by_user_id: User.id,
    };
    const apiCall = liked === 1 ? unlike_post : like_post;

    postApi(apiCall, obj).then((res) => {
      if (res.status === 200) {
        getGuideDetails();
        getExperiencePosts();
      }
    });
  };

  const handleNextExperiencePage = () => {
    setExperiencePageNo((prevPageNo) => prevPageNo + 1);
  };

  const handlePreviousExperiencePage = () => {
    if (experiencePageNo > 1) {
      setExperiencePageNo((prevPageNo) => prevPageNo - 1);
    }
  };

  const getGuideDetails = () => {
    setLoading(true);
    // if (User.role_name === "GUIDE") {
    //   getApi(guide_get_profile, {}, User.access_token).then((res) => {
    //     if (res.status === 200) {
    //       setGuideDetailData(res?.data?.data);
    //       setLoading(false);
    //     }
    //   });
    // } else {
      getApi(
        `${get_guide_profile_detail}?guideId=${id}&userId=${User.id}`,
        {},
        User.access_token
      ).then((res) => {
        if (res.status === 200) {
          setGuideDetailData(res?.data?.data);
          setLoading(false);
        }
      });
    // }
  };

  const getExperiencePosts = () => {
    setLoading(true);
    User.id
      ? getApi(
          `${get_experience_post}?page_no=${experiencePageNo}&number_of_rows=10&id=${id}&user_id=${User.id}`
        ).then((res) => {
          setExperiencePostData(res?.data?.data);
          setTotalExperiencePageNo(res?.data?.data?.totalCounts / 4);
          setLoading(false);
        })
      : getApi(
          `${get_experience_post}?page_no=${experiencePageNo}&number_of_rows=10&id=${id}`
        ).then((res) => {
          setExperiencePostData(res?.data?.data);
          setTotalExperiencePageNo(res?.data?.data?.totalCounts / 4);
          setLoading(false);
        });
  };

  useEffect(() => {
    getGuideDetails();
    getExperiencePosts();
  }, [experiencePageNo]);


  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <section className="hero">
          <div className="container spacer-y">
            <LocaliteProfile guideDetailData={guideDetailData} />
            <SpecialExperienceList
              experiencePostData={experiencePostData}
              guideDetailData={guideDetailData}
              handleNextPage={handleNextExperiencePage}
              handlePreviousPage={handlePreviousExperiencePage}
              pageNo={experiencePageNo}
              total={totalExperiencePageNo}
              handleLikeUnlike={handleLikeUnlike}
            />
          </div>
        </section>
      )}
    </>
  );
}
