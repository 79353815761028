import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../../../../../../node_modules/pikaday/css/pikaday.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { emailRegExp } from "../../../../../../../config/common/common";
import DatePicker from "react-datepicker";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { postApi } from "../../../../../../../config/apiFunctions";
import {
  guide_Advance_withdraw,
  guide_total_withdraw,
  guide_withdraw,
} from "../../../../../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import Popup from "./modals/Popup";
import { useNavigate } from "react-router-dom";

const WithdrawModal = (props) => {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });
  const navigate = useNavigate();
  const toast = useToast();
  const User = useSelector((state) => state.auth.SignupUser);
  const [date, setDate] = useState(new Date());
  const [btndisable, setBtnDisable] = useState(false);
  const [ShowPop, setShowPop] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const startDtaehandler = (date) => {
    setStartDate(date);
    setEndDate(null);
  };
  useEffect(() => {
    let endDate = moment(new Date(props?.data?.createdAt)).format("YYYY-MM-DD");
    setStartDate(
      new Date(moment(props?.viewData?.booking_start).toISOString())
    );
    setEndDate(new Date(moment(endDate).toISOString()));
    let defaultValues = {};
    defaultValues.name = User?.last_name
      ? `${User?.name} ${User?.last_name}`
      : User?.name;
    defaultValues.email = User?.email;
    defaultValues.amount = props.amount;
    reset({ ...defaultValues });
  }, [props.show]);
  const onSubmit = () => {
    setBtnDisable(true);
    setSubmit(true);
    const obj = {
      booking_id: props.viewData?.id,
      // withdraw_amount_type: props.withdraw_amount_type,
    };
    let api;
    if (props.withdraw_amount_type === "INITIAL") {
      api = guide_Advance_withdraw;
    } else {
      api = guide_total_withdraw;
    }
    postApi(api, obj, User.access_token)
      .then((res) => {
        setSubmit(false);
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.onHide();
        props.onHide2();
        navigate("/dashboard/transactions");
      })
      .catch((err) => {
        setSubmit(false);
        if (
          err.response.data.message === "Bank account details does not exist"
        ) {
          setBtnDisable(false);
          setShowPop(true);
          props.onHide();
        } else {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        }
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content p-30">
          <div className="modal_header position-relative">
            <h3 className="text-secondary f-700 mb-2">Withdraw Payment</h3>
            <p className="text-grey mb-2">
              Effortlessly Withdraw Payments Whenever You Need
            </p>
            <button
              type="button"
              className="btn-close"
              onClick={props.onHide}
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body p-0 mt-3">
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  disabled
                  {...register("name")}
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>
              {/* <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="email"
                >
                  Email Id
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email id"
                  disabled
                  {...register("email")}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </div> */}
              <div className="input-box date_selector d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="book_date"
                >
                  Date Of Booking
                </label>
                <div className="position-relative w-100">
                  <div className="position-relative w-100" id="datePickerDiv">
                    <DatePicker
                      disabled
                      id="datepicker"
                      className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                      selected={startDate}
                      onChange={(date) => startDtaehandler(date)}
                      dateFormat="MM-dd-yyyy"
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date()}
                      showDisabledMonthNavigation
                      required
                      onFocus={(e) => e.target.blur()}
                      // showIcon
                    />
                    <svg
                      className="date-icon"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33301 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.667 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2.77051 7.19629H16.2288"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.4247 10.846H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.4247 13.221H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.49598 10.846H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.49598 13.221H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.56629 10.846H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.56629 13.221H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="input-box date_selector d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="pay_date"
                >
                  Payment Date
                </label>
                <div className="position-relative w-100">
                  <div className="position-relative w-100">
                    <DatePicker
                      disabled
                      className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="MM-dd-yyyy"
                      required
                      onFocus={(e) => e.target.blur()}
                      // isClearable
                      // showIcon
                    />
                    <svg
                      className="date-picker-icon"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33301 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.667 1.5835V3.9585"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2.77051 7.19629H16.2288"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.4247 10.846H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.4247 13.221H12.4318"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.49598 10.846H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.49598 13.221H9.50309"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.56629 10.846H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.56629 13.221H6.5734"
                        stroke="#475467"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="amount"
                >
                  Amount
                </label>
                <input
                  disabled
                  type="text"
                  name="amount"
                  placeholder="Enter total amount"
                  defaultValue={props.amount}
                  {...register("amount")}
                  className={`form-control ${
                    errors.amount ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.amount?.message}</div>
              </div>
            </div>
            <div className="modal-footer border-0 p-0">
              <button
                disabled={btndisable}
                className="btn-main btn-primary w-100 mw-100"
              >
                {isSubmit ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  "  Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Popup show={ShowPop} onHide={() => setShowPop(false)} />
    </>
  );
};

export default WithdrawModal;
const Schema = Yup.object().shape({
  name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(30, "Please enter 30 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  email: Yup.string()
    .required("Email Id is required")
    .matches(emailRegExp, "Please enter valid email address"),
  amount: Yup.string().required("Time slot is required."),
});
