import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { cancelTrip } from "../../../../../../../../config/endpoints";
import { postApi } from "../../../../../../../../config/apiFunctions";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";

const CancelTripModal=(props)=>{
const [btndisabled, setBtnDisable]=useState(false);
const toast= useToast();
    const {
        register,
        setValue,
        handleSubmit,
        reset,
        trigger,
        formState: { errors },
      } = useForm({
        mode: "all",
        resolver: yupResolver(Schema),
      });
      const onSubmit=(e)=>{
setBtnDisable(true)
    const obj={
        booking_id:props.id,
        description:e.description
    }
    const access_token=props.access_token
        postApi(cancelTrip, obj, access_token).then(res=>{
            if (res.data.statusCode === 200) {
                toast({
                  title: res.data.message,
                  status: "success",
                  duration: 30000,
                  isClosable: true,
                  position: "top-right",
                  onCloseComplete: () => setBtnDisable(false),
                });
            props.onHide(false);
            }
        }).catch(err=>{
            toast({
                title: err.response.data.message,
                status: "error",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
        })
        reset();
      }
    return(
        // <!-- Modal Window -->
        <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
<div className={props.show === true ? "": "modal fade"} id="cancel_trip" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className=" ">
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content p-20">
            <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Cancel Trip</h3>
                <p className="text-grey mb-2">Are you sure you want to cancel your request for this trip?<br></br>Please let us know why.</p>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={props.onHide}></button>
            </div>
            <div className="modal-body p-0">
                <div>
                    <div className="form" action=""> 
                        <div className="my-3">  
                            <div className="input-box mt-3">
                                <textarea name="description" id="" cols="30" rows="4" placeholder="Please write your reason here"
                               {...register("description")}
                        className={` form-control ${
                            errors.description ? "is-invalid" : ""
                          }`}
                                />
                                 <div className="invalid-feedback">
                        {errors.description?.message}
                      </div>
                               
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0 p-0">
                <a  className="btn-main text-red" onClick={props.onHide}>Not Now</a>
                <button disabled={btndisabled} className="btn-main btn-red" type="submit" >
                {btndisabled ? (
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          "Cancel Trip"
                        )}
                    </button>
            </div>
        </div>
        </form>
    </div>
</div>
</Modal>
    )
}
export default CancelTripModal;

const Schema = Yup.object().shape({
    description: Yup.string()
      .required("This field is required*")
      .max(150, "Please enter 150 characters only")
      .min(5, "Too Short!"),
    //   .matches(/^(?=.*[a-zA-Z])(?!.*[^ a-zA-Z]).*$/, "Please enter valid characters"),
 
  });