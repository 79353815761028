import React, { useEffect } from "react";
import BookingHeader from "./section/bookingHeader/BookingHeader";
import BookingTable from "./section/bookingTable/BookingTable";
import { useDispatch } from "react-redux";
import { RECIEVED_BOOKINGS } from "../../../../../../redux/slices/TouristGuideReducer/guideSlice";

const Booking = () => {

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(RECIEVED_BOOKINGS(0))
  },[])

  return (
    <div className="table_fixed booking_innerbox">
      <BookingHeader />
      {/* <BookingTable /> */}
    </div>
  );
};

export default Booking;
