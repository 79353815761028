import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import WithdrawModal from "../WithdrawModal";
import defaultPicture from "../../../../../../../../assets/images/profile-placeholder1.jpg";
import { Oval } from "react-loader-spinner";
import { AiOutlineConsoleSql } from "react-icons/ai";

const ViewDetails = (props) => {
  const [ShowWithdraw, setShowWithdraw] = useState(false);
  const [amount, setAmount] = useState(null);
  // console.log(props, 4444);
  const [withdraw_amount_type, setWithdraw_amount_type] = useState(null);
  const Advance_payment = props.viewData?.payments.find(
    (obj) => obj.payment_type === "INITIAL" && obj.payment_status === 1
  );
  const Remaining_payment = props?.viewData?.payments.find(
    (obj) => obj.payment_type === "FINAL" && obj.payment_status === 1
  );
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="withdraw_modal modal-content">
        {props.viewData === null && (
          <div
            className="loader"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {props.viewData !== null && (
          <>
            <div className="modal_header position-relative">
              <div className="d-flex align-items-center">
                <div className="image_wrapper me-3">
                  <img
                    alt="Guide"
                    src={
                      props.viewData?.travellerDetails.user_detail
                        ?.profile_picture
                        ? props.viewData?.travellerDetails.user_detail
                            ?.profile_picture
                        : defaultPicture
                    }
                  />
                </div>
                <div>
                  <h4 className="text-black-light mb-2 lh-24 f-700">
                    {props.viewData?.travellerDetails.last_name
                      ? `${props.viewData?.travellerDetails.name} ${props.viewData?.travellerDetails.last_name}`
                      : props.viewData?.travellerDetails.name}
                  </h4>
                  <div className="d-flex align-items-center">
                    <p className="text-grey mb-0 f-500">
                      {/* {props.viewData?.travellerDetails.email} */}
                    </p>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={props.onHide}
              ></button>
            </div>
            <div className="modal-body p-0 mt-3">
              <div>
                {/* <div className="py-2 px-2 border-bottom">
                  <p className="text-secondary mb-0 f-500">
                    <span className="text-black-light f-700 me-1">
                      Location:
                    </span>
                    <span>{props.viewData?.destination}</span>
                  </p>
                </div> */}
                <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                  {/* <div className="py-3 px-2">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Price:
                      </span>
                      <span>
                        ${props.viewData?.user.user_detail.price} per hour
                      </span>
                    </p>
                  </div>
                  <div className="py-2 px-2 border-bottom text-capitalize">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Location:
                      </span>
                      <span>{props.viewData?.country}</span>
                    </p>
                  </div> */}
                  {/* <div className="py-3 px-2">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Total Counted Hours:
                      </span>
                      <span>{props.viewData?.TotalCountedHours} hour</span>
                    </p>
                  </div> */}
                </div>
                {props.transactionData === "INITIAL" && (
                  <div className="payment_row d-flex align-items-end justify-content-between border-bottom">
                    <div className="py-3 px-2">
                      <p className="text-secondary mb-0 f-500">
                        <span className="text-black-light f-700 me-1">
                          Advance Payment:
                        </span>
                        <span>${Advance_payment?.amount}</span>
                      </p>
                    </div>

                    <div className="py-3 px-2">
                      {Advance_payment?.withdraw_payment_status === null ||
                      Advance_payment?.withdraw_payment_status ===
                        "REJECTED" ? (
                        <>
                          {Advance_payment?.withdraw_payment_status ===
                            "REJECTED" && (
                            <p className="small-text btn-main btn-red f-400 px-4 py-1">
                              {Advance_payment?.withdraw_payment_status}
                            </p>
                          )}
                          <button
                            className="small-text btn-main btn-primary f-400 px-4 py-1"
                            onClick={() => {
                              setShowWithdraw(true);
                              setAmount(Advance_payment?.amount);
                              setWithdraw_amount_type(
                                Advance_payment.payment_type
                              );
                            }}
                          >
                            Withdraw
                          </button>
                        </>
                      ) : (
                        <button
                          disabled
                          // className=" dis small-text btn-main btn-primary f-400 px-4 py-1"
                          className="small-text btn-main btn-primary f-400 px-4 py-1"
                          onClick={() => {
                            setShowWithdraw(true);
                            setAmount(Advance_payment?.amount);
                            setWithdraw_amount_type(
                              Advance_payment.payment_type
                            );
                          }}
                        >
                          {Advance_payment?.withdraw_payment_status}
                        </button>
                      )}
                      {/* {Advance_payment?.withdraw_payment_status==="PENDING" &&

<button
disabled
className=" dis small-text btn-main btn-primary f-400 px-4 py-1"
onClick={() => {setShowWithdraw(true); setAmount(Advance_payment?.amount); setWithdraw_amount_type(Advance_payment.payment_type)}}
>
Pending
</button>
} */}
                    </div>
                  </div>
                )}
                {/* <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                  <div className="py-3 px-2">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Remaining Payment:
                      </span>
                      <span>
                        $
                        {props.viewData?.remainingAmount
                          ? props.viewData?.remainingAmount
                          : Remaining_payment?.amount}
                      </span>
                    </p>
                  </div>
                  <div className="py-3 px-2">
                    {props.viewData?.remainingAmountStatus === "PENDING" && (
                      <span className="payment_status pending_badge d-flex align-items-center justify-content-end f-600 text-blue">
                        <span className="bg-sky"></span>Pending
                      </span>
                    )}
                    {props.viewData?.remainingAmountStatus === "COMPLETED" && (
                      <span className="payment_status completed_badge d-flex align-items-center justify-content-end f-600 text-success">
                        <span className="btn-secondary-dark"></span>Completed
                      </span>
                    )}{" "}
                  </div>
                </div> */}
                {props.viewData?.remainingAmountStatus === "COMPLETED" &&
                  props.transactionData === "FINAL" && (
                    <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                      <div className="py-3 px-2">
                        <p className="text-secondary mb-0 f-500">
                          <span className="text-black-light f-700 me-1">
                            Total Payment:
                          </span>
                          <span>${props.viewData?.final_paid}</span>
                        </p>
                      </div>
                      {/* <div className="py-3 px-2">
                  <button
                            className="small-text btn-main btn-primary f-400 px-4 py-1"
                            onClick={() => {
                              setShowWithdraw(true);
                              setAmount(
                                Advance_payment?.withdraw_payment_status ===
                                  null ||
                                  Advance_payment?.withdraw_payment_status ===
                                    "REJECTED"
                                  ? props.viewData?.totalAmountTopay
                                  : props.viewData?.final_paid
                              );
                              setWithdraw_amount_type(
                                Remaining_payment.payment_type
                              );
                            }}
                          >
                            Withdraw
                          </button>
                  </div> */}
                      <div className="py-3 px-2">
                        {props.viewData?.remainingAmountStatus ===
                          "COMPLETED" && (
                          <>
                            {props.viewData?.totalAmountWithdrawStatus ===
                              null ||
                            props.viewData?.totalAmountWithdrawStatus ===
                              "REJECTED" ? (
                              <button
                                className="small-text btn-main btn-primary f-400 px-4 py-1"
                                onClick={() => {
                                  setShowWithdraw(true);
                                  setAmount(
                                    Advance_payment?.withdraw_payment_status ===
                                      null ||
                                      Advance_payment?.withdraw_payment_status ===
                                        "REJECTED"
                                      ? props.viewData?.final_paid
                                      : props.viewData?.final_paid
                                  );
                                  setWithdraw_amount_type(
                                    Remaining_payment.payment_type
                                  );
                                }}
                              >
                                Withdraw
                              </button>
                            ) : (
                              <button
                                disabled
                                className="small-text btn-main btn-primary f-400 px-4 py-1"
                                onClick={() => {
                                  setShowWithdraw(true);
                                  setAmount(props.viewData?.totalAmountTopay);
                                  setWithdraw_amount_type(
                                    Remaining_payment.payment_type
                                  );
                                }}
                              >
                                {props.viewData?.totalAmountWithdrawStatus}
                              </button>
                            )}
                          </>
                        )}
                        {props.viewData?.remainingAmountStatus ===
                          "PENDING" && (
                          <button
                            disabled
                            className=" dis small-text btn-main btn-primary f-400 px-4 py-1"
                            onClick={() => {
                              setShowWithdraw(true);
                              setAmount(props.viewData?.totalAmountTopay);
                              setWithdraw_amount_type(
                                Remaining_payment.payment_type
                              );
                            }}
                          >
                            Withdraw
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                <div className="pt-2 px-2 pb-0">
                  {/* <p className="text-secondary mb-0 f-500">
                    <span className="text-black-light f-700 me-1">Note:</span>
                    <span>
                      Total payment will reflect after completion of the tour{" "}
                    </span>
                  </p> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <WithdrawModal
        show={ShowWithdraw}
        onHide={() => setShowWithdraw(false)}
        amount={amount}
        data={Advance_payment}
        data2={Remaining_payment}
        viewData={props.viewData}
        withdraw_amount_type={withdraw_amount_type}
        onHide2={props.onHide}
      />
    </Modal>
  );
};

export default ViewDetails;
