import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Email_Verifivation } from "../../../config/endpoints";
import { postApi } from "../../../config/apiFunctions";
import { useNavigate } from "react-router-dom";
import { SIGNUP_USER_DETAILS } from "../../../redux/slices/auth/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import "../emailverify/Error.css";
import { Oval } from "react-loader-spinner";
import { persistor } from "../../../redux/store/store";
import { PROFILE_PIC } from "../../../redux/slices/profilePicture/ProfilePictureSlice";
import WaitingPage from "../../main/waitingPage/WaitingPage";

const EmailVerify = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Userdata = useSelector((state) => state.auth.SignupUser);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const verifyemail = async () => {
    await postApi(Email_Verifivation, params)
      .then((res) => {
        try {
          dispatch(SIGNUP_USER_DETAILS(res.data?.data));
          if (res.data?.data.waiting_list === true) {
            navigate("/waiting-list");
            // setShow(true);
          } else {
            if (res.status === 201) {
              if (res.data?.data?.role === "GUIDE") {
                navigate("/create_guide_profiles");
              } else {
                navigate("/create_traveler_profile");
              }
            }
          }
        } catch (error) {}
      })
      .catch((error) => {
        persistor.purge();
        setError(error.response.data?.message);
      });
  };

  useEffect(() => {
    dispatch(SIGNUP_USER_DETAILS([]));
    dispatch(PROFILE_PIC([]));
    if (Userdata === undefined) {
      verifyemail();
    } else {
      persistor.purge();
      dispatch(PROFILE_PIC([]));
      dispatch(SIGNUP_USER_DETAILS([]));
      verifyemail();
    }
  }, []);

  return (
    <>
      {!error ? (
        <section className="page_404">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-sm-12 col-md-10 ">
                <div
                  className="container spacer-y"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                    marginBottom: "auto",
                    margin: "auto",
                  }}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#1f74ca"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#1f74ca8f"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="page_404">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-sm-12 col-md-10 ">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">Opps</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">
                    {error ? <>{error}</> : <>Look like you're lost</>}
                  </h3>
                  <Link to="/" className="link_404">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EmailVerify;
