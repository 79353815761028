import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import forgot_pass from "../../../assets/images/forgot-pass-vector.svg";
import email_send from "../../../assets/images/email-send.svg";
import "../LoginSignup.css";
import { forgot_password } from "../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { postApi } from "../../../config/apiFunctions";
import { ThreeDots } from "react-loader-spinner";

const ForgotPassword = (props) => {
  const toast = useToast();
  const [btndisable, setBtnDisable]= useState(false);
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const onFormSubmit = (e) => {
    setBtnDisable(true);
    postApi(forgot_password, e)
      .then((res) => {
        if (res.status === 200) {
          setBtnDisable(false);
           props.setConfirm(true);
           props.setshowpopup(false)
          reset();
        }
      })
      .catch((err) => {
        setBtnDisable(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };
  return (
    <>
      <div
        className={
          props.show === true ? "forgot-pass-popup active" : "forgot-pass-popup"
        }
      >
        <div className="forgot-pass-wrapper zindex-up text-center">
          <div
            className={
              props.showpopup === false 
                ? "forgot-pass-form d-none"
                : "forgot-pass-form"
            }
          >
            <img src={forgot_pass} className="mb-3 mx-auto" alt="" />
            <h4 className="mb-3 p-0">Forgot Password</h4>
            <p>
              Don't worry it happens. Please enter the address associated with
              your account.
            </p>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="row gy-3 gy-md-4 text-start">
                <div className="col-lg-12">
                  <label htmlFor="" className="form-label theme-label">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    {...register("email")}
                    className={`form-control theme-controls ${
                      errors.email ? "is-invalid" : ""
                    }`}
                  />
                </div>
                <div className="col-lg-12 text-center">
                {btndisable === true ? (
             <button
             type="submit"
             disabled={btndisable}
             className="button-primary email-send-trigger"
           >
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </button>
            ) : 
                  <button
                    type="submit"
                    disabled={btndisable}
                    className="button-primary email-send-trigger"
                  >
                    Submit
                  </button>
}
                </div>
              </div>
            </form>
          </div>
          <div
            //className="email-sent d-none"
            className={
              props.confirm === true ? "email-sent" : "email-sent d-none"
            }
          >
            <img src={email_send} className="mb-3" alt="send-emailErr" style={{display:"initial"}}/>
            <h4>Check Your E-mail</h4>
            <p>We have sent a password recover instruction to your email.</p>
            <button
              onClick={()=>props.handleOverlay()}
                // props.setshowpopup(false) 
              //  props.setConfirm(false)}}
              href=""
              className="button-primary forgot-popup-close"
            >
              Ok
            </button>
          </div>
        </div>
        <div className="forgot-overlay" onClick={props.handleOverlay}></div>
      </div>
      
    </>
  );
};

export default ForgotPassword;

const Schema = Yup.object().shape({
  email: Yup.string().required("email is required"),
});
