import React from 'react';
import no_result from "../../../assets/images/no-result.png";

const Error = ({errmsg}) => {
  
  return (
    <section className="no-result">
    <div className="container spacer-y pt-0">
      <div className="row justify-content-center text-center">
        <div className="col-sm-6 col-lg-4">
          <img
            src={no_result}
            className="mb-3"
            alt=""
            data-aos="fade-up"
          />
          <h2
            className="primary__color mb-3 p-0"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            No Results Found
          </h2>
          <p data-aos="fade-up" data-aos-delay="200">
           {errmsg}
          </p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Error
