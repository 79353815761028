import React from "react";
import DragDrop from "../../../../assets/images/drag_drop.svg";

export default function UploadMedia(props) {
  return (
    <div>
      <div className="input-box d-flex align-items-start justify-content-start flex-column">
        <label
          className="form-label theme-label color__paragraph"
          htmlFor="picture"
        >
          Add Primary Image{" "}
          <span style={{ fontSize: "12px" }} className="m-0">
            (File size must not exceed 50 MB)
          </span>
        </label>
        <label
          className="upload_media_label w-100 text- center d-flex flex-column align-items-center justify-content-center p-30"
          htmlFor="galleryImageUpload"
        >
          {!props.mainImg && (
            <>
              <img src={DragDrop} className="drag_icon" alt="" />
              <p className="text-center m-0 fw-bold lh-24">
                Click here <br />
                to upload
              </p>

              <input
                name="id_proof"
                id="galleryImageUpload"
                className="d-none"
                type="file"
                accept="image/*"
                multiple
                onChange={props.selectMainImg}
              />
            </>
          )}
          {props.mainImg && (
            <div className="exp_media_img">
              {props.mainImgType === "image" ? (
                <img src={props.mainImg} alt="Media" />
              ) : props.mainImgType === "video" ? (
                <video controls className="h-100 w-100">
                  <source src={props.mainImg} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : null}
              <span
                className="remove_media_imgbtn"
                onClick={() => props.setMainImg()}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 8 8"
                  fill="#f00"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.99935 7.33341C5.83268 7.33341 7.33268 5.83341 7.33268 4.00008C7.33268 2.16675 5.83268 0.666748 3.99935 0.666748C2.16602 0.666748 0.666016 2.16675 0.666016 4.00008C0.666016 5.83341 2.16602 7.33341 3.99935 7.33341Z"
                    stroke="white"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.05664 4.94331L4.94331 3.05664"
                    stroke="white"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.94331 4.94331L3.05664 3.05664"
                    stroke="white"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          )}
        </label>
      </div>
      <div className="mt-2">
        <label
          className="form-label theme-label color__paragraph"
          htmlFor="picture"
        >
          Add Additional Images or Videos{" "}
          <span style={{ fontSize: "12px" }} className="m-0">
            (File size must not exceed 50 MB)
          </span>
        </label>
        <div className="row g-2">
          {props.multipleImages.map((list, i) => (
            <div key={i} className="col-3">
              <div className="upload_small_media_label p-0">
                <div className="exp_media_img">
                  {list?.type?.startsWith("image/") ? (
                    <img className="" src={list.url} alt="media" />
                  ) : list?.type?.startsWith("video/") ? (
                    <video controls className="w-100 h-100">
                      <source src={list.url} type={list.type} />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                  <span
                    className="remove_media_imgbtn"
                    onClick={() => props.handleChangeRemove(i)}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 8 8"
                      fill="#f00"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.99935 7.33341C5.83268 7.33341 7.33268 5.83341 7.33268 4.00008C7.33268 2.16675 5.83268 0.666748 3.99935 0.666748C2.16602 0.666748 0.666016 2.16675 0.666016 4.00008C0.666016 5.83341 2.16602 7.33341 3.99935 7.33341Z"
                        stroke="white"
                        strokeWidth="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.05664 4.94331L4.94331 3.05664"
                        stroke="white"
                        strokeWidth="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.94331 4.94331L3.05664 3.05664"
                        stroke="white"
                        strokeWidth="0.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          ))}
          {props.multipleImages.length < 4 && (
            <div className="col-3">
              <label
                className="upload_small_media_label text-center d-flex flex-column align-items-center justify-content-center p-30"
                htmlFor="smallMedia"
              >
                {/* <svg
                  className=""
                  width="26"
                  height="21"
                  viewBox="0 0 26 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4878 6.95801C20.3103 2.03288 15.5535 -0.957466 10.8629 0.278856C7.19739 1.24504 4.53579 4.57156 4.26156 8.52935C1.65887 8.98003 -0.103108 11.5608 0.326105 14.2936C0.707655 16.7232 2.71257 18.5024 5.05759 18.4926H9.03777V16.821H5.05759C3.29905 16.821 1.87345 15.3241 1.87345 13.4776C1.87345 11.6311 3.29905 10.1342 5.05759 10.1342C5.49725 10.1342 5.85363 9.75998 5.85363 9.29833C5.84965 5.1437 9.05404 1.77231 13.0108 1.76818C16.4359 1.76457 19.3844 4.30712 20.0469 7.83559C20.1124 8.1879 20.3847 8.45705 20.7236 8.50427C22.8998 8.82968 24.4128 10.9459 24.1029 13.2309C23.8246 15.2828 22.1561 16.8106 20.1823 16.821H16.9981V18.4926H20.1823C23.2597 18.4829 25.747 15.8554 25.7376 12.624C25.7298 9.93415 23.9764 7.59633 21.4878 6.95801Z"
                    fill="#98A2B3"
                  ></path>
                  <path
                    d="M12.4537 10.3764L9.26953 13.7198L10.3919 14.8983L12.2228 12.9842V21H13.8149V12.9842L15.6378 14.8983L16.7602 13.7198L13.5761 10.3764C13.2656 10.0523 12.7642 10.0523 12.4537 10.3764Z"
                    fill="#98A2B3"
                  ></path>
                </svg> */}
                <p
                  className="text-center m-0 fw-bold"
                  style={{ fontSize: "12px", lineHeight: "12px" }}
                >
                  Click here <br />
                  to upload
                </p>
                <input
                  name="id_proof"
                  id="smallMedia"
                  className="d-none"
                  type="file"
                  accept="image/*, video/*"
                  multiple
                  onChange={props.changeMultipleFiles}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
