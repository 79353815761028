import React from "react";
// import blog_img from '../../../../../../assets/images/blog-image.png';
import calendar from "../../../../../../assets/images/calendar.svg";
import profile_icon from "../../../../../../assets/images/profile-icon.svg";
import { useSelector } from "react-redux";
import RecentPost from "../../../recentPosts/RecentPost";
import parse from "html-react-parser";
import moment from "moment";

const BlogWrapper = () => {
  const data = useSelector((state) => state.touristGuide.singleBlog);
  return (
    <section className="blog-detail-wrapper">
      <div className="container spacer-y pt-0">
        <div className="row g-3 gy-4 gy-lg-0 gx-lg-4">
          <div className="col-lg-8">
            <div className="dynamic-content">
              <h2>{data?.title}</h2>
              <div className="profile-date d-flex align-items-center gap-3 mb-3">
                <p className="d-flex align-items-center gap-2 m-0">
                  <img src={calendar} alt="" />
                  <span>
                    {moment.utc(data?.published_at).format("DD-MMM-YYYY")}
                  </span>
                </p>
                <p className="d-flex align-items-center gap-2 m-0">
                  <img src={profile_icon} alt="" />
                  <span>
                    {data?.user?.last_name
                      ? `${data?.user?.name} ${data?.user?.last_name}`
                      : data?.user?.name}
                  </span>
                </p>
              </div>
              <p>{parse(`${data?.content}`)}</p>
            </div>
          </div>
          <RecentPost />
        </div>
      </div>
    </section>
  );
};

export default BlogWrapper;
