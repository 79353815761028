import React, { useEffect } from "react";

const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  minPageLimit,
  maxPageLimit,
  pageNumberLimit,
  setMaxPageLimit,
  setMinPageLimit,
}) => {

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const nextPage = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    if (currentPage !== nPages) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo(1, 0);
  };
  const prevPage = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo(1, 0);
  };

  // page ellipses
  let pageIncrementEllipses = null;
  if (pageNumbers.length > maxPageLimit) {
    pageIncrementEllipses = <li onClick={nextPage}>&hellip;</li>;
  }
  let pageDecremenEllipses = null;
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <li onClick={prevPage}>&hellip;</li>;
  }

  useEffect(() => {
    //remove empty div from pagination count
    const allDivs = document.querySelectorAll(".val");
    const emptyTextDivs = Array.from(allDivs).filter(
      (div) => div.textContent.trim() === ""
    );
    for (var i = 0, len = emptyTextDivs.length; i < len; i++) {
      emptyTextDivs[i].setAttribute("hidden", "hidden");
    }
  }, []);
  return (
    <>
      <div className="theme-pagination mb-5">
        {pageNumbers?.length > 1 ? (
          <>
            <button
              id="prev"
              className="page-nav page-prev"
              onClick={prevPage}
              disabled={currentPage === pageNumbers[0]}
            >
              <i className="fa-solid fa-angle-left"></i>
            </button>
            <ul className="list-unstyled m-0 page-count">
              {pageDecremenEllipses}
              {pageNumbers?.map((pgNumber, i) => (
                <div key={i} className="val">
                  {pgNumber <= maxPageLimit && pgNumber > minPageLimit && (
                    <li
                      key={i}
                      className={currentPage === pgNumber ? "active" : ""}
                      onClick={() => {
                        setCurrentPage(pgNumber);
                        window.scrollTo(1, 0);
                      }}
                    >
                      <p
                        className="list-unstyled m-0 page-count xyz1"
                        style={{}}
                      >
                        {pgNumber}
                      </p>
                    </li>
                  )}
                </div>
              ))}
              {pageIncrementEllipses}{" "}
            </ul>
            <button
              id="next"
              className="page-nav page-next"
              onClick={nextPage}
              disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
            >
              <i className="fa-solid fa-angle-right"></i>
            </button>
          </>
        ) : (
          <div> </div>
        )}
      </div>
    </>
  );
};
export default Pagination;
