import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function MediaSection({ postData }) {
  const User = useSelector((state) => state.auth.SignupUser);
  const location = useLocation();
  const navigate = useNavigate();

  const activityArr = postData?.posts_activities?.map((item) => item.activity);

  return (
    <div>
      <div className="py-4">
        <div className="d-flex gap-4 justify-content-between">
          <h3 className="">{postData?.title}</h3>
          <div className="d-flex gap-2">
            {User?.role_name === "GUIDE" &&
              !location?.state?.fromPreview &&
              !location?.state?.formSocialProfile && (
                <Link
                  to={`/edit-experience/${postData?.id}`}
                  state={{ activeTab: 1, id: postData?.id }}
                  className="icon_button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    fill="white"
                  >
                    <path d="M12.8995 6.85453L17.1421 11.0972L7.24264 20.9967H3V16.754L12.8995 6.85453ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
                  </svg>
                </Link>
              )}
            <Link onClick={() => navigate(-1)} className="icon_button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="white"
              >
                <path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path>
              </svg>
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="currentColor"
          >
            <path d="M12 20.8995L16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13ZM12 15C9.79086 15 8 13.2091 8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15Z"></path>
          </svg>
          <p className="m-0 ps-2">
            <strong>{postData?.location || "USA"}</strong>
          </p>
        </div>
        <div className="d-flex align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="currentColor"
          >
            <path d="M8 4C8 5.10457 7.10457 6 6 6 4.89543 6 4 5.10457 4 4 4 2.89543 4.89543 2 6 2 7.10457 2 8 2.89543 8 4ZM5 16V22H3V10C3 8.34315 4.34315 7 6 7 6.82059 7 7.56423 7.32946 8.10585 7.86333L10.4803 10.1057 12.7931 7.79289 14.2073 9.20711 10.5201 12.8943 9 11.4587V22H7V16H5ZM6 9C5.44772 9 5 9.44772 5 10V14H7V10C7 9.44772 6.55228 9 6 9ZM19 5H10V3H20C20.5523 3 21 3.44772 21 4V15C21 15.5523 20.5523 16 20 16H16.5758L19.3993 22H17.1889L14.3654 16H10V14H19V5Z"></path>
          </svg>
          <p className="m-0 ps-2">
            <strong>
              {activityArr
                ?.map((item) => item?.title)
                .join(", ")
                .replace(/, ([^,]*)$/, " and $1")}
            </strong>
          </p>
        </div>
      </div>

      {postData?.post_images?.length === 4 && (
        <div className="exp_images_section">
          <div className="row g-3">
            <div className="col-sm-12 col-md-6">
              <div className="exp_hero_image">
                {/* {postData?.hero_image?.endsWith(".mp4") ||
                postData?.hero_image?.endsWith(".webm") ? (
                  <video controls className="w-100 h-100">
                    <source
                      src={postData?.hero_image}
                      type={postData?.hero_image.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : ( */}
                <img src={postData?.hero_image} alt="hero_image" />
                {/* )} */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row g-3">
                <div className="col-12">
                  <div className="exp_images_section_first">
                    {postData?.post_images[0]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[0].url}
                          type={postData?.post_images[0]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[0]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="exp_images_section_other">
                    {postData?.post_images[1]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[1].url}
                          type={postData?.post_images[1]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[1]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="exp_images_section_other">
                    {postData?.post_images[2]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[2].url}
                          type={postData?.post_images[2]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[2]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="exp_images_section_other">
                    {postData?.post_images[3]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[3].url}
                          type={postData?.post_images[3]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[3]?.url} alt="img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {postData?.post_images?.length === 3 && (
        <div className="exp_images_section">
          <div className="row g-3">
            <div className="col-sm-12 col-md-6">
              <div className="exp_hero_image">
                {/* {postData?.hero_image?.endsWith(".mp4") ||
                postData?.hero_image?.endsWith(".webm") ? (
                  <video controls className="w-100 h-100">
                    <source
                      src={postData?.hero_image}
                      type={postData?.hero_image.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : ( */}
                <img src={postData?.hero_image} alt="hero_image" />
                {/* )} */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row g-3">
                <div className="col-12">
                  <div className="exp_images_section_first">
                    {postData?.post_images[0]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[0].url}
                          type={postData?.post_images[0]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[0]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="exp_images_section_other">
                    {postData?.post_images[1]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[1].url}
                          type={postData?.post_images[1]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[1]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="exp_images_section_other">
                    {postData?.post_images[2]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[2].url}
                          type={postData?.post_images[2]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[2]?.url} alt="img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {postData?.post_images?.length === 2 && (
        <div className="exp_images_section">
          <div className="row g-3">
            <div className="col-sm-12 col-md-6">
              <div className="exp_hero_image">
                {/* {postData?.hero_image?.endsWith(".mp4") ||
                postData?.hero_image?.endsWith(".webm") ? (
                  <video controls className="w-100 h-100">
                    <source
                      src={postData?.hero_image}
                      type={postData?.hero_image.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : ( */}
                <img src={postData?.hero_image} alt="hero_image" />
                {/* )} */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row g-3">
                <div className="col-12">
                  <div className="exp_images_section_first">
                    {postData?.post_images[0]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[0].url}
                          type={postData?.post_images[0]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[0]?.url} alt="img" />
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="exp_images_section_other">
                    {postData?.post_images[1]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[1].url}
                          type={postData?.post_images[1]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[1]?.url} alt="img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {postData?.post_images?.length === 1 && (
        <div className="exp_images_section">
          <div className="row g-3">
            <div className="col-sm-12 col-md-6">
              <div className="exp_hero_image">
                {/* {postData?.hero_image?.endsWith(".mp4") ||
                postData?.hero_image?.endsWith(".webm") ? (
                  <video controls className="w-100 h-100">
                    <source
                      src={postData?.hero_image}
                      type={postData?.hero_image.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : ( */}
                <img src={postData?.hero_image} alt="hero_image" />
                {/* )} */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="row g-3">
                <div className="col-12">
                  <div className="exp_images_section_first_only">
                    {postData?.post_images[0]?.media_type === "video" ? (
                      <video controls className="w-100 h-100">
                        <source
                          src={postData?.post_images[0].url}
                          type={postData?.post_images[0]?.url?.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={postData?.post_images[0]?.url} alt="img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {postData?.post_images?.length === 0 && (
        <div className="exp_images_section">
          <div className="row g-3">
            <div className="col-sm-12 col-md-12">
              <div className="exp_hero_image">
                {/* {postData?.hero_image?.endsWith(".mp4") ||
                postData?.hero_image?.endsWith(".webm") ? (
                  <video controls className="w-100 h-100">
                    <source
                      src={postData?.hero_image}
                      type={postData?.hero_image.type}
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : ( */}
                <img src={postData?.hero_image} alt="hero_image" />
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
