import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../../../../../../config/apiFunctions";
import { search_guide } from "../../../../../../../../config/endpoints";
import { TOPRATED_GUIDE } from "../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import TableContent from "../tableContent/TableContent";
import no_result from "../../../../../../../../assets/images/no-result.png";
import { Oval } from "react-loader-spinner";

const TableHeader = () => {
  const dispatch = useDispatch();
  const [show, setshow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [squery, setSearchquery] = useState();

  const handleChange = (e) => {
    if (e) {
      setSearchquery(e);
    } else {
      setSearchquery(null);
      setLoading(false);
      setshow(true);
    }
    setLoading(false);
  };

  const searchdata = () => {
    setLoading(true);
    if (squery) {
      getApi(`${search_guide}?destination=${squery}&page_no=${currentPage}`)
        .then((res) => {
          setLoading(false);
          dispatch(TOPRATED_GUIDE(res.data.data));
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (squery?.length === 0) {
      setLoading(false);
      setshow(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchdata();
  }, [currentPage, squery]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="p-20 border-bottom">
            <div className="position-relative">
              <input
                type="text"
                name="search"
                maxLength="50"
                size="10"
                placeholder="Search by Country, State, City , Zipcode"
                onChange={(e) => handleChange(e.target.value)}
                className="form-control"
              />

              <button className="btn-search" style={{ cursor: "unset" }}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21.5C16.7467 21.5 21 17.2467 21 12C21 6.75329 16.7467 2.5 11.5 2.5C6.25329 2.5 2 6.75329 2 12C2 17.2467 6.25329 21.5 11.5 21.5Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M22 22.5L20 20.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <TableContent
        show={show}
        setShow={() => setshow(false)}
        setLoading={setLoading}
        isLoading={isLoading}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        squery={squery}
      />
    </>
  );
};

export default TableHeader;
