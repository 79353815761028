import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { emailRegExp } from "../../../../../../../../config/common/common";
// import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { postApi } from "../../../../../../../../config/apiFunctions";
import {
  // get_itinerary,
  traveler_booking,
} from "../../../../../../../../config/endpoints";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import axios from "axios";

const BooknowModal = (props) => {
  const { location } = props;
  const User = useSelector((state) => state.auth.SignupUser);
  const [slotStartTime, setSlotStartTime] = useState(null);
  const [slotEndTime, setSlotEndTime] = useState(null);
  const [optionalTimeslot, setOptionalTimeslot] = useState(new Date());
  const [optionalendTimeslot, setOptionalendTimeslot] = useState(new Date());
  const [slotErrmsg, setSlotErrmsg] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [btndisable, setBtnDisable] = useState(false);
  const [Options, setOptions] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [state, setStateSelect] = useState([]);
  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });

  const [city, setCity] = useState([]);

  const [getitineraryModal, setGetItineraryModal] = useState(false);
  const [viewItineraryModal, setViewItineraryModal] = useState(false);

  const [addressState, setAddressState] = useState({
    country: location?.country || "United States",
    state: location?.state,
    city: location?.city,
  });

  const [userInfo, setUserInfo] = useState({
    first_name: User?.name || "",
    last_name: User?.last_name || "",
    country: location?.country || "",
    state: location?.state || "",
    city: location?.city || "",
    family_type: User?.family_type || "Solo",
    activities: User?.activities || "Sightseeing",
  });

  const [itineraryAiBtn, setItineraryAiBtn] = useState(false);
  const [iteneraryData, setIteneraryData] = useState("");
  const [loading, setLoading] = useState(false);
  const [showIteneraryBox, setShowIteneraryBox] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
    defaultValues: {
      country: location?.country || "United States",
      state: location?.state,
      city: location?.city,
    },
  });

  const startDtaehandler = (date) => {
    setStartDate(date);
    setEndDate(null);
  };
  const [contactDetails, setContatDetails] = useState({
    phone: User.phone,
    isoCode: User.country_code_iso,
    dialCode: User.country_code,
  });
  const onContactNoChange = (value, country, e, formattedValue) => {
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: `+${country_code}`,
    });
    setValue("phone", _phone);
    trigger("phone");
  };

  useEffect(() => {
    setContatDetails({
      phone: User.phone,
      dialCode: User.country_code,
      isoCode: User.country_code_iso,
    });
    let defaultValues = {};
    defaultValues.first_name = User?.name;
    if (User.last_name) {
      defaultValues.last_name = User.last_name;
    }
    defaultValues.email = User?.email;
    defaultValues.phone = `${User.phone}`;
    reset({ ...defaultValues });
  }, [props.show]);

  const onSubmit = (e) => {
    setSubmit(true);
    setTimeout(() => {
      setBtnDisable(true);
      setSubmit(false);
    }, 100);
    const obj = {
      ...e,
      itinerary_text: iteneraryData,
      guide_id: props.id,
      country_code: contactDetails.dialCode,
      country_code_iso: contactDetails.isoCode,
      booking_start: moment(startDate).format("YYYY-MM-DD"),
      booking_end: moment(endDate).format("YYYY-MM-DD"),
      //  booking_slot_start: start,
      //  booking_slot_end: end,
    };

    const access_token = User.access_token;
    postApi(traveler_booking, obj, access_token)
      .then((res) => {
        setSubmit(false);
        if (res.data.statusCode === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        }
        setIteneraryData("");
        navigate("/dashboard/my-bookings");
        reset();
        props.onHide();
        setBtnDisable(false);
      })
      .catch((err) => {
        ///remove cheked from inputs
        const input = document.querySelectorAll(".slots");
        for (var i = 0; i < input.length; i++) {
          input[i].checked = false;
        }
        setSubmit(false);
        setBtnDisable(true);
        // setOptionalTimeslot(null);
        //setOptionalendTimeslot(null);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const datePicker = document.getElementById("datepicker");
  const dateIcon = document.getElementById("date-icon");
  dateIcon?.addEventListener("click", function () {
    datePicker.focus();
  });

  const setCityList = async (event) => {
    const { value } = event.target;
    setAddressState({
      ...addressState,
      city: value,
    });
  };
  const getCityList = async (event) => {
    setGetLoader({ ...getCityLoader, ["city"]: true });
    const { value } = event.target;
    const selectedState = state.find((state) => state.name === value);
    await GetCity(countryId, selectedState.id).then((result) => {
      setCity(result);
      setAddressState({
        ...addressState,
        state: value,
        city: result[0]?.name,
      });
    });
    setGetLoader({ ...getCityLoader, ["city"]: false });
  };
  const getStateList = async (event) => {
    setGetLoader({ ...getCityLoader, ["state"]: true });
    const { value } = event.target;
    // setCity([]);
    const country = countries.find((country) => country.name === value);
    setCountryId(country.id);
    await GetState(country.id).then(async (stateList) => {
      setStateSelect(stateList);
      setGetLoader({ ...getCityLoader, ["city"]: true });
      await GetCity(country.id, stateList[0]?.id).then((cityList) => {
        setCity(cityList);
        setAddressState({
          ...addressState,
          country: value,
          state: stateList[0]?.name,
          city: cityList[0]?.name,
        });
      });
      setGetLoader({ ...getCityLoader, ["city"]: false });
    });
    setGetLoader({ ...getCityLoader, ["state"]: false });
  };
  const getCountriesList = async () => {
    setGetLoader({ ...getCityLoader, ["countries"]: true });
    await GetCountries().then((result) => {
      setCountries(result);
    });
    setGetLoader({ ...getCityLoader, ["countries"]: false });
  };

  const getDefaultAddress = async () => {
    await GetCountries().then(async (countries) => {
      setCountries(countries);
      let countryQuery = location?.country
        ? location?.country
        : "United States";
      const country = countries.find(
        (country) => country.name === countryQuery
      );
      setCountryId(country.id);
      await GetState(country.id).then(async (states) => {
        setStateSelect(states);
        let stateQuery = location?.state ? location?.state : states[0].name;
        const state = states.find((state) => state.name === stateQuery);
        await GetCity(country?.id, state?.id).then((result) => {
          setCity(result);
        });
      });
    });
    setUserInfo({
      ...userInfo,
      country: location?.country,
      state: location?.state,
      city: location?.city,
    });
  };
  useEffect(() => {
    // getCountriesList();

    getDefaultAddress();
  }, []);

  const fetchData = async () => {
    localStorage.removeItem("itineraryDetail");
    setIteneraryData("");
    const obj = {
      guide_id: props.id,
      country_code: contactDetails.dialCode,
      country_code_iso: contactDetails.isoCode,
      booking_start: moment(startDate).format("YYYY-MM-DD"),
      booking_end: moment(endDate).format("YYYY-MM-DD"),
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      country: userInfo.country,
      state: userInfo.state,
      city: userInfo.city,
      family_type: userInfo.family_type,
      activities: userInfo.activities,
    };
    try {
      setItineraryAiBtn(true);
      const access_token = User.access_token;
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/traveller/generate-sample-itinerary`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            access_token: access_token,
            // Add any additional headers as needed
          },
          body: JSON.stringify(obj),
        }
      );
      if (!response.ok || !response.body) {
        throw response.statusText;
      }
      setGetItineraryModal(true);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setLoading(false);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        setIteneraryData((prevValue) => `${prevValue}${decodedChunk}`);
        let previousValue = localStorage.getItem("itineraryDetail");
        if (previousValue) {
          localStorage.setItem(
            "itineraryDetail",
            `${previousValue}${decodedChunk}`
          );
        } else {
          localStorage.setItem("itineraryDetail", `${decodedChunk}`);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content p-30">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal_header position-relative">
              <h3 className="text-grey-500 f-700 mb-2">Request Info</h3>
              <p className="text-grey mb-2">
                Your Dream Destination Awaits: Secure Your Adventure Today!
              </p>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setIteneraryData("");
                  setItineraryAiBtn(false);
                  setUserInfo({
                    first_name: User?.name || "",
                    last_name: User?.last_name || "",
                    country: location?.country || "",
                    state: location?.state || "",
                    city: location?.city || "",
                    family_type: User?.family_type || "Solo",
                    activities: User?.activities || "Sightseeing",
                  });
                  setLoading(false);
                  props.onHide();
                  reset();
                }}
              ></button>
            </div>
            <div className="modal-body p-0 mt-3">
              <div className="form w-100">
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        defaultValue={User.name}
                        placeholder={"Enter first name"}
                        {...register(
                          "first_name"
                          // (onchange = (e) => {
                          //   setUserInfo({
                          //     ...userInfo,
                          //     first_name: e.target.value,
                          //   });
                          //   setIteneraryData("");
                          // })
                        )}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            first_name: e.target.value,
                          });
                          setIteneraryData("");
                        }}
                        className={`small-text form-control ${
                          errors.first_name ? "is-invalid" : ""
                        }`}
                        disabled={loading}
                      />
                      <div className="invalid-feedback">
                        {errors.first_name?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="Last name"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        defaultValue={User.last_name}
                        placeholder="Enter last name"
                        {...register(
                          "last_name"
                          // (onchange = (e) =>
                          //   setUserInfo({
                          //     ...userInfo,
                          //     last_name: e.target.value,
                          //   }))
                        )}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            last_name: e.target.value,
                          });
                          setIteneraryData("");
                        }}
                        className={`small-text form-control ${
                          errors.last_name ? "is-invalid" : ""
                        }`}
                        disabled={loading}
                      />
                      <div className="invalid-feedback">
                        {errors.last_name?.message}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="email"
                      >
                        Email Id
                      </label>
                      <input
                        type="email"
                        name="email"
                        defaultValue={User.email}
                        placeholder="Enter email id"
                        {...register("email")}
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        // disabled
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-box index-fix d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="Phone number"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        // disableDropdown
                        // country="us"
                        value={`${contactDetails.dialCode}${contactDetails.phone}`}
                        placeholder="Enter contact number"
                        countryCodeEditable={false}
                        enableSearch={true}
                        onChange={(value, country, e, formattedValue) =>
                          onContactNoChange(value, country, e, formattedValue)
                        }
                        sx={{ width: "100%" }}
                        isValid={() => (!!errors.phone ? "" : true)}
                        // disabled
                      />
                      <input
                        {...register("phone")}
                        type="hidden"
                        value={contactDetails.phone}
                        // disabled
                      />
                      {errors.phone && (
                        <div
                          className="text-danger mt-1 fs-6"
                          sx={{ ml: "1px" }}
                          error={true}
                        >
                          <p style={{ fontSize: ".875em" }}>
                            Please enter valid number
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <p className="mb-2">Travel Destination</p>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="country"
                      >
                        Country
                      </label>
                      {/* <input
                      id="myInput"
                      name="destination"
                      placeholder="Enter Destination"
                      {...register("destination")}
                      className={`form-control ${
                        errors.destination ? "is-invalid" : ""
                      }`}
                    /> */}

                      {countries.length > 0 && (
                        // <select
                        //   {...register(
                        //     "country"
                        //     // (onchange = (e) =>
                        //     //   setUserInfo({
                        //     //     ...userInfo,
                        //     //     country: e.target.value,
                        //     //   }))
                        //   )}
                        //   onChange={(e) => {
                        //     handleAddress(e, "country");
                        //   }}
                        //   name="country"
                        //   defaultValue={"United States"}
                        //   // onChange={(e) => {
                        //   //   handleAddress(e, "country");
                        //   //   setUserInfo({
                        //   //     ...userInfo,
                        //   //     country: e.target.value,
                        //   //   });
                        //   // }}
                        //   className={`form-control theme-controls ${
                        //     errors.country ? "is-invalid" : ""
                        //   }`}
                        // >
                        //   {getCityLoader.country && (
                        //     <option value={""}>Loading...</option>
                        //   )}

                        //   {countries.length > 0 &&
                        //     countries.map((country, index) => {
                        //       return (
                        //         <option value={country.name} key={index}>
                        //           {country.name}
                        //         </option>
                        //       );
                        //     })}
                        // </select>
                        <select
                          {...register("country")}
                          name="country"
                          // defaultValue={User?.country}
                          value={addressState.country}
                          onChange={(e) => {
                            getStateList(e);
                            setUserInfo({
                              ...userInfo,
                              country: e.target.value,
                            });
                            setIteneraryData("");
                          }}
                          className={`small-text form-control ${
                            errors.country ? "is-invalid" : ""
                          }`}
                          disabled={loading}
                        >
                          {/* {getCityLoader.country && (
                    <option value={""}>Loading...</option>
                  )} */}

                          {countries.length > 0 &&
                            countries.map((country, index) => {
                              return (
                                <option value={country.name} key={index}>
                                  {country.name}
                                </option>
                              );
                            })}
                        </select>
                      )}
                      <div className="invalid-feedback">
                        {errors.country?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        State
                      </label>
                      {/* <input
                        type="text"
                        name="first_name"
                        defaultValue={User.name}
                        placeholder="Enter first name"
                        {...register("first_name")}
                        className={`small-text form-control ${
                          errors.first_name ? "is-invalid" : ""
                        }`}
                      /> */}
                      {state.length > 0 ? (
                        // <select
                        //   {...register("state")}
                        //   // onChange={(e) => {
                        //   //   setUserInfo({
                        //   //     ...userInfo,
                        //   //     state: e.target.value,
                        //   //   });
                        //   // }}
                        //   type="text"
                        //   // onChange={(e) => {
                        //   //   handleAddress(e, "state");
                        //   //   setUserInfo({
                        //   //     ...userInfo,
                        //   //     state: e.target.value,
                        //   //   });
                        //   // }}
                        //   className={`form-control theme-controls ${
                        //     errors.state ? "is-invalid" : ""
                        //   }`}
                        // >
                        //   {getCityLoader.state && (
                        //     <option value={""}>Loading...</option>
                        //   )}
                        //   {state.length > 0 &&
                        //     state.map((state, index) => {
                        //       return (
                        //         <option value={state.name} key={index}>
                        //           {state.name}
                        //         </option>
                        //       );
                        //     })}
                        // </select>
                        <select
                          {...register("state")}
                          type="text"
                          // defaultValue={User?.state}
                          value={addressState?.state}
                          onChange={(e) => {
                            getCityList(e);
                            setUserInfo({
                              ...userInfo,
                              state: e.target.value,
                            });
                            setIteneraryData("");
                          }}
                          className={`small-text form-control ${
                            errors.state ? "is-invalid" : ""
                          }`}
                          disabled={loading}
                        >
                          {/* {getCityLoader.state && (
                    <option value={""}>Loading...</option>
                  )} */}
                          {state.length > 0 &&
                            state.map((state, index) => {
                              return (
                                <option value={state.name} key={index}>
                                  {state.name}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <input
                          name="state"
                          placeholder="State"
                          {...register("state")}
                          className={`form-control theme-controls ${
                            errors.state ? "is-invalid" : ""
                          }`}
                          // disabled={loading}
                        />
                      )}
                      <div className="invalid-feedback">
                        {errors.state?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="Last name"
                      >
                        City
                      </label>
                      {/* <input
                        type="text"
                        name="last_name"
                        defaultValue={User.last_name}
                        placeholder="Enter last name"
                        {...register("last_name")}
                        className={`small-text form-control ${
                          errors.last_name ? "is-invalid" : ""
                        }`}
                      /> */}
                      {city.length > 0 ? (
                        // <select
                        //   {...register("city")}
                        //   // onChange={(e) => {
                        //   //   setUserInfo({
                        //   //     ...userInfo,
                        //   //     city: e.target.value,
                        //   //   });
                        //   // }}
                        //   type="text"
                        //   className={`form-control theme-controls ${
                        //     errors.city ? "is-invalid" : ""
                        //   }`}
                        // >
                        //   {getCityLoader.city && (
                        //     <option value={""}>Loading...</option>
                        //   )}
                        //   {city.length > 0 &&
                        //     city.map((city, index) => {
                        //       return (
                        //         <option value={city.name} key={index}>
                        //           {city.name}
                        //         </option>
                        //       );
                        //     })}
                        // </select>
                        <select
                          {...register("city")}
                          onChange={(e) => {
                            setCityList(e);
                            setUserInfo({
                              ...userInfo,
                              city: e.target.value,
                            });
                            setIteneraryData("");
                          }}
                          value={addressState.city}
                          name="city"
                          // defaultValue={User?.city}
                          type="text"
                          className={`small-text form-control ${
                            errors.city ? "is-invalid" : ""
                          }`}
                          disabled={loading}
                        >
                          {getCityLoader.city && (
                            <option value={""}>Loading...</option>
                          )}
                          {city.length > 0 &&
                            city.map((city, index) => {
                              return (
                                <option value={city.name} key={index}>
                                  {city.name}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <input
                          name="city"
                          placeholder="City"
                          {...register("city")}
                          className={`form-control theme-controls ${
                            errors.city ? "is-invalid" : ""
                          }`}
                          // disabled={loading}
                        />
                      )}
                      <div className="invalid-feedback">
                        {errors.city?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="number_of_people"
                      >
                        Type
                      </label>
                      {/* <input
                        type="text"
                        name="number_of_people"
                        defaultValue={User?.number_of_people}
                        placeholder="Enter no of people"
                        {...register("number_of_people")}
                        className={`small-text form-control ${
                          errors.number_of_people ? "is-invalid" : ""
                        }`}
                      /> */}
                      <select
                        {...register("family_type")}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            family_type: e.target.value,
                          });
                          setIteneraryData("");
                        }}
                        className={`small-text form-control ${
                          errors.family_type ? "is-invalid" : ""
                        }`}
                        disabled={loading}
                      >
                        <option value="Solo">Solo</option>
                        <option value="Couple">Couple</option>
                        <option value="Family">Family</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.family_type?.message}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="activities"
                      >
                        Activities
                      </label>
                      <textarea
                        {...register("activities")}
                        type="text"
                        name="activities"
                        // defaultValue={userInfo?.activities}
                        placeholder="Eg. hiking, restaurant, etc"
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            activities: e.target.value,
                          });
                          setIteneraryData("");
                          setShowIteneraryBox(true);
                        }}
                        disabled={loading}
                        className={`small-text form-control ${
                          errors.activities ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.activities?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="date_fixed input-box date_selector mb-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="position-relative w-100">
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="start_date"
                        >
                          Start Date
                        </label>
                        <div
                          className="position-relative w-100"
                          id="datePickerDiv"
                        >
                          <DatePicker
                            id="datepicker"
                            className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                            selected={startDate}
                            onChange={(date) => {
                              startDtaehandler(date);
                              setIteneraryData("");
                            }}
                            dateFormat="MM-dd-yyyy"
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            minDate={new Date()}
                            showDisabledMonthNavigation
                            required
                            onFocus={(e) => e.target.blur()}
                            disabled={loading}
                            // showIcon
                          />
                          <svg
                            className="date-icon"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.33301 1.5835V3.9585"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.667 1.5835V3.9585"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M2.77051 7.19629H16.2288"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.4247 10.846H12.4318"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.4247 13.221H12.4318"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.49598 10.846H9.50309"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.49598 13.221H9.50309"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.56629 10.846H6.5734"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.56629 13.221H6.5734"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="position-relative w-100 mt-3 mt-md-0">
                        <label
                          className="small-text text-grey-scale mb-1 f-600"
                          htmlFor="start_date"
                        >
                          End Date
                        </label>
                        <div className="position-relative w-100">
                          <DatePicker
                            className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setIteneraryData("");
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat="MM-dd-yyyy"
                            required
                            onFocus={(e) => e.target.blur()}
                            disabled={loading}
                            // isClearable
                            // showIcon
                          />
                          <svg
                            className="date-picker-icon"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.33301 1.5835V3.9585"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.667 1.5835V3.9585"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M2.77051 7.19629H16.2288"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.4247 10.846H12.4318"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M12.4247 13.221H12.4318"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.49598 10.846H9.50309"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.49598 13.221H9.50309"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.56629 10.846H6.5734"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.56629 13.221H6.5734"
                              stroke="#475467"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="time_field input-box d-flex align-items-start justify-content-start flex-column mb-3">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="time"
                  >
                    Select A Time
                  </label>
                  <div className="d-flex align-items-center select_time_badge_list flex-wrap">
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r1"
                        name="r1"
                        value="10"
                        tabIndex="-1"
                        onChange={() => handlechangee("10", "11")}
                        disabled
                      />
                      <label
                        htmlFor="r1"
                        className="badge_box text-secondary extra-small-text "
                      >
                        10am-11am
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r2"
                        name="r1"
                        value="11"
                        tabIndex="-1"
                        onChange={() => handlechangee("11", "12")}
                        disabled
                      />
                      <label
                        htmlFor="r2"
                        className="badge_box text-secondary extra-small-text"
                      >
                        11am-12pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r8"
                        name="r1"
                        value="12"
                        tabIndex="-1"
                        onChange={() => handlechangee("12", "13")}
                        disabled
                      />
                      <label
                        htmlFor="r8"
                        className="badge_box text-secondary extra-small-text"
                      >
                        12pm-1pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r3"
                        name="r1"
                        value="13"
                        tabIndex="-1"
                        onChange={() => handlechangee("13", "14")}
                        disabled
                      />
                      <label
                        htmlFor="r3"
                        className="badge_box text-secondary extra-small-text"
                      >
                        1pm-2pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r4"
                        name="r1"
                        value="14"
                        tabIndex="-1"
                        onChange={() => handlechangee("14", "15")}
                        disabled
                      />
                      <label
                        htmlFor="r4"
                        className="badge_box text-secondary extra-small-text"
                      >
                        2pm-3pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots bg-primary"
                        type="radio"
                        id="r5"
                        name="r1"
                        value="15"
                        tabIndex="-1"
                        onChange={() => handlechangee("15", "16")}
                        disabled
                      />
                      <label
                        htmlFor="r5"
                        className="badge_box text-secondary extra-small-text"
                      >
                        3pm-4pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r6"
                        name="r1"
                        value="16"
                        tabIndex="-1"
                        onChange={() => handlechangee("16", "17")}
                        disabled
                      />
                      <label
                        htmlFor="r6"
                        className="badge_box text-secondary extra-small-text"
                      >
                        4pm-5pm
                      </label>
                    </div>
                    <div id="disableslots" className="badge_wrapper">
                      <input
                        className="slots"
                        type="radio"
                        id="r7"
                        name="r1"
                        value="17"
                        tabIndex="-1"
                        onChange={() => handlechangee("17", "18")}
                        disabled
                      />
                      <label
                        htmlFor="r7"
                        className="badge_box text-secondary extra-small-text"
                      >
                        5pm-6pm
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor=""
                      className="badge_box btn-primary text-white extra-small-text"
                      onClick={toggleslots}
                    >
                      <svg
                        className="me-1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 8H12"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Add Manually Time
                    </label>
                  </div>
                  <div className={Options === true ? "row" : "d-none"}>
                    <div className="col-md-6 col-12 mt-2">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        Start Time
                      </label>
                      <div className="input-box time_inputwrapper position-relative justify-content-start flex-column text-lowercase">
                        <DatePicker
                          showIcon
                          placeholderText="Enter start time"
                          className="form-control w-fix dob-field small-text date-field mw-100 text-start ps-5 "
                          selected={optionalTimeslot}
                          onChange={(date) =>
                            optionalTimeSlothandlechange(date)
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          // minTime={new Date().setHours(0, 59, 59)}
                          minTime={
                            startDate.toDateString() ===
                            new Date().toDateString()
                              ? new Date().setHours(
                                  new Date().getHours(),
                                  new Date().getMinutes(),
                                  new Date().getSeconds()
                                )
                              : new Date().setHours(0, 59, 59)
                          }
                          maxTime={new Date().setHours(23, 59, 59)}
                          onFocus={(e) => e.target.blur()}
                        />
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4274 10.4896C16.4274 14.3133 13.3241 17.4167 9.50033 17.4167C5.67658 17.4167 2.57324 14.3133 2.57324 10.4896C2.57324 6.66583 5.67658 3.5625 9.50033 3.5625C13.3241 3.5625 16.4274 6.66583 16.4274 10.4896Z"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.5 6.33325V10.2916"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.125 1.58325H11.875"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mt-2">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="name"
                      >
                        End Time
                      </label>
                      <div className="input-box time_inputwrapper position-relative justify-content-start flex-column text-lowercase">
                        <DatePicker
                          name="option2"
                          className="form-control w-fix dob-field small-text date-field mw-100 text-start ps-5 "
                          selected={optionalendTimeslot}
                          placeholderText="Enter end time"
                          onChange={(date) =>
                            optionalTimeSlothandlechange2(date)
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          filterTime={filterPassedTime}
                          onFocus={(e) => e.target.blur()}
                        />
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4274 10.4896C16.4274 14.3133 13.3241 17.4167 9.50033 17.4167C5.67658 17.4167 2.57324 14.3133 2.57324 10.4896C2.57324 6.66583 5.67658 3.5625 9.50033 3.5625C13.3241 3.5625 16.4274 6.66583 16.4274 10.4896Z"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9.5 6.33325V10.2916"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.125 1.58325H11.875"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name="slottime"
                      {...register("slottime")}
                    />
                    <p
                      className="text-primary pt-2 mb-1"
                      style={{ fontSize: ".875em" }}
                    ></p>
                  </div>
                  <div
                    className="text-danger pt-2 mb-1"
                    style={{ fontSize: ".875em" }}
                  >
                    {errors.slottime?.message}
                  </div>
                  {slotErrmsg === 1 && (
                    <p
                      className="text-danger pt-2 mb-1"
                      style={{ fontSize: ".875em" }}
                    >
                      Time slot is required.
                    </p>
                  )}
                </div> */}
              </div>
            </div>

            <div className="modal-footer border-0 p-0">
              <button
                onClick={() => {
                  fetchData();
                }}
                type="button"
                className="btn-main btn-primary w-100 mw-100"
              >
                {loading ? (
                  <>
                    {" "}
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </>
                ) : (
                  // "Create Itinerary"
                  // "Create Sample Itinerary"
                  // "View Sample Itinerary"
                  "Create AI Itinerary"
                )}
              </button>
            </div>
            {localStorage.getItem("itineraryDetail") && (
              <div className="modal-footer border-0 p-0">
                <button
                  onClick={() => {
                    setViewItineraryModal(true);
                  }}
                  type="button"
                  className="btn-main btn-primary w-100 mw-100"
                  disabled={!localStorage.getItem("itineraryDetail")}
                >
                  {/* View Sample Itinerary */}
                  View Itinerary
                </button>
              </div>
            )}

            {/* <input className="small-text form-control" value={iteneraryData} /> */}
            {/* {itineraryAiBtn === true && (
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                <textarea
                  type="text"
                  className="w-100 small-text itinerary_textarea"
                  {...register("itinerary_text")}
                  defaultValue={iteneraryData}
                  onChange={(e) => {
                    setIteneraryData(e.target.value);
                  }}
                  name="compose_mail"
                  id=""
                  rows="10"
                  columns="30"
                />
              </div>
            )}

            {itineraryAiBtn === true &&
              iteneraryData !== "" &&
              loading === false && (
                <div className="modal-footer border-0 p-0">
                  <button
                    onClick={() => {
                      setGetItineraryModal(true);
                    }}
                    type="button"
                    className="btn-main btn-primary w-100 mw-100"
                  >
                    View Entire Itinerary
                  </button>
                </div>
              )} */}

            <div className="modal-footer border-0 p-0">
              <button
                disabled={btndisable}
                type="submit"
                className="btn-main btn-primary w-100 mw-100"
                iteneraryData-bs-toggle="modal"
                iteneraryData-bs-dismiss="modal"
                iteneraryData-bs-target="#payment_popup"
              >
                {btndisable ? (
                  <ThreeDots
                    height="20"
                    width="80"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  // "  Request Info"
                  // "Send to Guide"
                  "Connect with Guide"
                )}
              </button>
            </div>
          </form>
        </div>

        {/* <ViewItineraryModal
          show={getitineraryModal}
          onHide={() => {
            setIteneraryData(null);
            setGetItineraryModal(false);
          }}
          data={iteneraryData}
          change={(e) => {
            setIteneraryData(e.target.value);
          }}
        /> */}

        <Modal
          show={getitineraryModal}
          size="mw-600"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
            <div>
              <div className="modal-content p-30">
                <div className="modal_header position-relative">
                  <h3 className="text-grey-500 f-700 mb-2">AI Itinerary</h3>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setGetItineraryModal(false);
                    }}
                  ></button>
                  <textarea
                    type="text"
                    className="w-100 small-text itinerary_textarea"
                    // {...register("itinerary_text")}
                    value={iteneraryData && iteneraryData}
                    // value={localStorage.getItem("itineraryDetail")}
                    onChange={(e) => {
                      setIteneraryData(e.target.value);
                      localStorage.setItem("itineraryDetail", e.target.value);
                    }}
                    name="compose_mail"
                    id=""
                    rows="30"
                    columns="20"
                  />
                </div>
                <button
                  onClick={() => {
                    setGetItineraryModal(false);
                  }}
                  className="btn-main btn-primary w-100 mw-100"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={viewItineraryModal}
          size="mw-600"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div>
            <div>
              <div className="modal-content p-30">
                <div className="modal_header position-relative">
                  <h3 className="text-grey-500 f-700 mb-2">AI Itinerary</h3>

                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setViewItineraryModal(false);
                    }}
                  ></button>
                  <textarea
                    type="text"
                    className="w-100 small-text itinerary_textarea"
                    disabled
                    value={localStorage.getItem("itineraryDetail")}
                    name="compose_mail"
                    id=""
                    rows="30"
                    columns="20"
                  />
                </div>
                <button
                  onClick={() => {
                    setViewItineraryModal(false);
                  }}
                  className="btn-main btn-primary w-100 mw-100"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Modal>
    </>
  );
};

export default BooknowModal;

const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  last_name: Yup.string()
    .max(15, "Please enter 15 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  email: Yup.string()
    .required("Email Id is required")
    .matches(emailRegExp, "Please enter valid email address"),
  phone: Yup.string()
    .required("Phone number is required.")
    .min(10, "Enter valid phone number"),
  // destination: Yup.string()
  //   .required("Destination is required")
  //   .min(2, "Invalid Destination")
  //   .trim(" ")
  //   .max(30, "Maximum character length is 30")
  //   .matches(/^[0-9a-zA-Z\s]+$/, "Invalid Destination"),
  country: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "Country",
    // })
  ),
  state: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "State",
    // })
  ),
  city: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "City",
    // })
  ),
  family_type: Yup.string(),
  //   .required("This field is required")
  //   .matches(/^[0-9]/, "Please enter a valid value"),
  activities: Yup.string(),
  // slottime: Yup.string().required("Time slot is required."),
  // itinerary_text: Yup.string(),
});
