import axios from "axios";
import { persistor } from "../../redux/store/store";

const baseURL = process.env.REACT_APP_API_URL;

export const postApi = async (endpoint, elem, access_token) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "POST",
      headers: access_token ? { access_token: access_token } : "",
    };
    return await axios
      .post(baseURL + endpoint, elem, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
            persistor.purge();
            window.location.replace("/login");
          }, 3000);
        } else {
          reject(err);
        }
      });
  });
};

export const putApi = async (endpoint, elem, access_token) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "PUT",
      headers: access_token ? { access_token: access_token } : "",
    };
    return await axios
      .put(baseURL + endpoint, elem, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
            persistor.purge();
            window.location.replace("/login");
          }, 3000);
        } else {
          reject(err);
        }
      });
  });
};

export const getApi = async (endpoint, elem, access_token) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "GET",
      headers: access_token ? { access_token: access_token } : {},
      body: elem ? JSON.stringify(elem) : elem,
    };
    return await axios
      .get(baseURL + endpoint, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
            persistor.purge();
            window.location.replace("/login");
          }, 3000);
        } else {
          reject(err);
        }
      });
  });
};

export const deleteApi = async (endpoint, elem,access_token) => {
  return new Promise(async (resolve, reject) => {
    let config = {
      method: "DELETE",
      headers: access_token ? { access_token: access_token } : {},
      body: elem ? JSON.stringify(elem) : elem,
    };
    return await axios
      .delete(baseURL + endpoint, config)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
            persistor.purge();
            window.location.replace("/login");
          }, 3000);
        } else {
          reject(err);
        }
      });
  });
};

