import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi, postApi } from "../../../config/apiFunctions";
import {
  check_waiting_traveller,
  waitListPage_content,
} from "../../../config/endpoints";
import { WAIT_LIST_PAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";
import parse from "html-react-parser";
import "./index.css";
import logo from "../../../assets/images/waitinglist-logo";
import waitMobVec from "../../../assets/images/waiting_mob_vec.svg";
import waitWebVec from "../../../assets/images/waiting_web_vec.svg";
import { Link } from "react-router-dom";
import { WAITING_STATUS } from "../../../redux/slices/auth/LoginSlice";

export default function WaitingPage() {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.SignupUser);
  const data = useSelector((state) => state.content.waitListPage);
  const [loading, setLoading] = useState(false);

  const waitingListData = () => {
    setLoading(true);
    getApi(
      `${waitListPage_content}?key=WAITING_LIST&section=WAIT_LIST`,
      {},
      User.access_token
    )
      .then((res) => {
        dispatch(WAIT_LIST_PAGE(res?.data?.data?.value));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const checkingTravellerStatus = () => {
    const obj = {
      id: User.id,
      email: User.email,
    };
    if (User.id) {
      postApi(`${check_waiting_traveller}`, obj, User.access_token)
        .then((res) => {
          dispatch(WAITING_STATUS(res?.data?.data?.waiting_list));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (User) {
      checkingTravellerStatus();
    }
  }, [User]);

  useEffect(() => {
    waitingListData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div>
            <Link to="/">
              <img className="logo" style={{ margin: "35px" }} src={logo} />
            </Link>
          </div>
          <div className="waiting_list_page">
            <img src={waitMobVec} className="mx-auto" alt="" />
            <img src={waitWebVec} alt="" />
            <div className="waiting_wrap">
              <div className="mb-40">
                <h1>{data.title}</h1>
                {parse(`${data && data.description}`)}
                <p>
                  <Link to={`mailto:${data.email}`}>{data.email}</Link>
                </p>
              </div>
              {/* <div className="waitlist_form d-flex align-items-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                />
                <button>Join Waitlist</button>
              </div> */}
              <img
                src={data.banner_image?.upload_image_url}
                className="mx-auto"
                alt=""
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
