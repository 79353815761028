import React, { useEffect, useRef, useState } from "react";
import "./SlickSlider.css";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
import loction_ic from "../../../../../assets/images/loction_ic.svg";
// import popular_img2 from "../../../../../assets/images/popular_img2.png";
// import popular_img3 from "../../../../../assets/images/popular_img3.png";
import Slider from "react-slick";
import { isFloat } from "../../../../../config/common/common";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { user_popular_destination } from "../../../../../config/endpoints";
import { getApi } from "../../../../../config/apiFunctions";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

const PopularDestination = ({ dataList }) => {
  const customeSlider = useRef();
  const [hasSetPosition, setHasSetPosition] = useState(false);
  let initialSlide = 0;
  // const [index, setIndex] = useState(0);
  // const [nextIndex, setNextIndex] = useState(0);

  useEffect(() => {
    if (customeSlider.current && !hasSetPosition) {
      customeSlider.current.slickGoTo(initialSlide);
      setHasSetPosition(true);
    }
  }, [initialSlide, hasSetPosition, customeSlider]);

  const afterChange = (index) => {
    const highestIndex = customeSlider.current.innerSlider.state.slideCount - 1;
  };

  const beforeChange = (prev, next) => {
    var elementRight = document.getElementById("right_arrow");
    var elementLeft = document.getElementById("left_arrow");
    if (prev === 0 && next === 0) {
      elementLeft.classList.add("arrow-disabled");
    } else {
      elementLeft.classList.remove("arrow-disabled");
      elementRight.classList.remove("arrow-disabled");
    }
    let indexType = isFloat(next);
    if (indexType && prev !== 0 && next !== 0) {
      elementRight.classList.add("arrow-disabled");
    }
  };
  const previous = () => {
    customeSlider.current.slickNext();
  };

  const next = () => {
    customeSlider.current.slickPrev();
  };
  var settings = {
    beforeChange,
    afterChange,
    dots: false,
    infinite: false,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <i className="fa-regular fa-angle-left " />,
    prevArrow: <i className="fa-regular fa-angle-right" />,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data
    ? data.rows.slice(indexOfFirstRecord, indexOfLastRecord)
    : [];
  const nPages = data ? Math.ceil(data.count / recordsPerPage) : [];
  const destinationsList = () => {
    setLoading(true);
    getApi(`${user_popular_destination}?page_no=${currentPage}`).then((res) => {
      setLoading(false);
      setData(res.data.data);
    });
  };
  useEffect(() => {
    destinationsList();
  }, []);
  return (
    <section className="popular">
      {isLoading === true && (
        <div
          className="container spacer-y"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {isLoading === false && (
        <>
          <div className="container spacer-y pb-0">
            <div className="row heading align-items-center">
              <div className="col-sm-9">
                <h2 className="primary__color m-0" data-aos="fade-up">
                  {dataList && dataList[0]?.value?.title}
                </h2>
                <div className="m-0" data-aos="fade-up" data-aos-delay="200">
                  {parse(`${dataList && dataList[0]?.value?.description}`)}
                </div>
              </div>
              <div className="col-sm-3 popular_right text-end">
                <div className="slide_arrow d-flex gap-4 justify-content-sm-end justify-content-center">
                  <div
                    id="left_arrow"
                    className="arrow_left slick-arrow "
                    onClick={next}
                  >
                    <i className="fa-regular fa-angle-left"></i>
                  </div>
                  <div
                    id="right_arrow"
                    className="arrow_right slick-arrow "
                    onClick={previous}
                  >
                    <i className="fa-regular fa-angle-right"></i>
                  </div>
                </div>
                {/* <a
              href=""
              className="primary__color"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              See All
            </a> */}
              </div>
            </div>
          </div>
          <div className="container spacer-y pt-0">
            <div className="" data-aos="fade-up" data-aos-delay="300">
              <Slider
                className="popular_slider"
                {...settings}
                ref={customeSlider}
              >
                {data?.rows.map((list, i) => {
                  return (
                    <a key={i}>
                      <div className="popular_slide_item text-capitalize">
                        <img src={list.thumbnail_image_url} alt="popular" />
                        <div className="tag_line_slider">
                          <p className="text-white">{list.title}</p>
                          <span>
                            <img src={loction_ic} alt="icon" /> {list.city},{" "}
                            {list.country}
                          </span>
                        </div>
                      </div>
                    </a>
                  );
                })}
                {/* <a  >
              <div className="popular_slide_item">
                <img src={popular_img2} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">Deira Old Souk Abra Station</p>{" "}
                  <span>
                    <img src={loction_ic} alt="icon" /> United Arab Emirates
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img3} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">
                    Villa Callisto - Ocean Front Retreat
                  </p>
                  <span>
                    <img src={loction_ic} alt="icon" /> Thani, Thailand
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img1} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">Samabe Bali Suites & Villas</p>
                  <span>
                    <img src={loction_ic} alt="icon" /> Dua, Indonesia
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img2} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">Deira Old Souk Abra Station</p>
                  <span>
                    <img src={loction_ic} alt="icon" /> United Arab Emirates
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img3} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">
                    Villa Callisto - Ocean Front Retreat
                  </p>
                  <span>
                    <img src={loction_ic} alt="icon" /> Thani, Thailand
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img1} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">Samabe Bali Suites &amp; Villas</p>
                  <span>
                    <img src={loction_ic} alt="icon" /> Nusa Dua, Indonesia
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img2} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">Deira Old Souk Abra Station</p>{" "}
                  <span>
                    <img src={loction_ic} alt="icon" /> United Arab Emirates
                  </span>
                </div>
              </div>
            </a>
            <a  >
              <div className="popular_slide_item">
                <img src={popular_img3} alt="popular" />
                <div className="tag_line_slider">
                  <p className="text-white">
                    Villa Callisto - Ocean Front Retreat
                  </p>
                  <span>
                    <img src={loction_ic} alt="icon" /> Thani, Thailand
                  </span>
                </div>
              </div>
            </a> */}
              </Slider>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default PopularDestination;
