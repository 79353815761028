import React from "react";
import hostImg from "../../../../../assets/images/host.png";


export default function ReviewRating({postData}) {
  return (
    <div className="pt-5 pb-5">
      <h6>Review & Rating</h6>
      <div className="row">
        {postData?.user?.rating_and_reviews?.map((item, i) => (
          <div key={i} className="col-md-6 pb-4">
            <div className="review_card">
              <div className="row">
                <div className="col-sm-3">
                  <div className="review_img">
                    <img src={hostImg} alt="heroImg" />
                  </div>
                </div>
                <div className="col-sm-9 p-0">
                  <div className="hero_content">
                    <p>
                      <strong>{item.user_name}</strong>
                    </p>
                    <p>5 year ago</p>
                    <p>{item.review_message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
