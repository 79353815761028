import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  guide_deleteMSG,
  traveler_deleteMSG,
} from "../../../../../../../config/endpoints";
import { putApi } from "../../../../../../../config/apiFunctions";
import { useToast } from "@chakra-ui/react";
import { Modal } from "react-bootstrap";

const Delete = (props) => {
  const User = useSelector((state) => state.auth.SignupUser);
  const [btnDisabled, setBtnDisable] = useState(false);

  const toast = useToast();
  const canceledBooking = () => {
    setBtnDisable(true);
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_deleteMSG;
    } else {
      api = traveler_deleteMSG;
    }
    const obj = {
      thread_ids: props.checked,
    };
    putApi(api, obj, User.access_token)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.setDefList();
        props.onHide();
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          className={props.show === true ? "" : "modal fade"}
          id="cancel_trip"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          {/* <form onSubmit={canceledBooking}> */}
          <div className=" ">
            <div className="modal-content p-20">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">
                  Are you sure you want to delete?
                </h3>
                <p className="text-grey mb-2"></p>
                <div className="modal-body p-0">
                  <div>
                    <div className="form" action="">
                      <div className="my-3">
                        <div className="input-box mt-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="modal-footer border-0 p-0 pt-3">
                <a className="btn-main text-red" onClick={props.onHide}>
                  Not Now
                </a>
                <button
                  className="btn-main btn-red"
                  type="submit"
                  disabled={btnDisabled}
                  onClick={canceledBooking}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>
      </Modal>
    </>
  );
};

export default Delete;
