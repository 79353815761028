import ReviewsHeader from "./sections/ReviewsHeader";
import ReviewsList from "./sections/ReviewsList";

const Reviews = () => {
  return (
    <>
      <div className="table_fixed gallery_table booking_innerbox">
        <ReviewsHeader />
        <ReviewsList />
      </div>
    </>
  );
};

export default Reviews;
