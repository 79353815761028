import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../../../../config/apiFunctions";
import { all_blogs } from "../../../../../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { BLOG } from "../../../../../redux/slices/TouristGuideReducer/guideSlice";
import parse from "html-react-parser";


const RecentBlog = () => {
  const data = useSelector((state) => state.touristGuide?.blog?.rows);
  const [lastBlog, setblog] = useState();

  const dispatch = useDispatch();
  const recentBlog = () => {
    getApi(all_blogs).then((res) => {
      dispatch(BLOG(res.data.data));
    });
  };

  useEffect(() => {
    recentBlog();
  }, []);

  useEffect(() => {
    const sliceblog = data?.slice(0,1);
    setblog(sliceblog);
  }, [data]);


  return (
    <>
      <section className="hero">
        <div className="container spacer-y">
          <h2 className="primary__color mb-40 p-0" data-aos="fade-up">
            Recent Blog
          </h2>
          {lastBlog?.map((blog , i) => (
            <div
            key={i}
              className="row gx-lg-4 g-3 align-items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="col-md-6">
                <div className="blog-image">
                  <img src={blog.banner_image_url} className="w-100" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <h2>{blog.title}</h2>
                <p>{blog.content &&(
                 parse( blog.content.slice(0, 450))
                // {parse(`${blog.content}`)}
                )}</p>
                <Link
                  to={`/blog-detail/${blog.slug}`}
                  className="button-primary"
                  // onClick={() => setId(lastBlog.id)}
                >
                  Read More
                </Link>
                
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default RecentBlog;
