import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { useToast } from "@chakra-ui/react";
import { postApi } from "../../../config/apiFunctions";
import { like_gallery, like_post } from "../../../config/endpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

export default function LikeUnlikeModal(props) {
  const toast = useToast();
  const [btndisable, setBtnDisable] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (e) => {
    setBtnDisable(true);
    setIsSubmit(true);
    let obj;
    let apiCall;
    if (props?.postType === "GALLERY") {
      apiCall = like_gallery;
      obj = {
        gallery_id: props?.postId,
        email: e.email,
      };
    } else {
      apiCall = like_post;
      obj = {
        post_id: props?.postId,
        email: e.email,
      };
    }

    postApi(apiCall, obj).then((res) => {
      if (res.status === 200) {
        if (res.data.message === "Already liked") {
          toast({
            title: res.data.message,
            status: "info",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
          setBtnDisable(false);
        } else {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
          setBtnDisable(false);
        }
      }
      reset();
      setBtnDisable(false);
      setIsSubmit(false);
      props.onHide();
    });
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="modal-width-custom"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content p-30">
          <div className="modal-header position-relative">
            <div class="">
              <h3 className="f-700 mb-2">Join the Journey</h3>
              <p className="p-0 m-0">
              Like with Your Email
              </p>
            </div>

            <button
              type="button"
              className="btn-close"
              onClick={() => props.onHide()}
            ></button>
          </div>
          <div className="modal-body">
            <form className="traveler-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row gy-3 gy-lg-4">
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    {...register("email")}
                    className={`form-control theme-controls ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    placeholder="you@company.com"
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    disabled={btndisable}
                    className="button-primary w-50 next-step d-flex justify-content-center "
                  >
                    {isSubmit ? (
                      <ThreeDots
                        height="20"
                        width="80"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      "Like"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
const Schema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
});
