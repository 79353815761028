import React from "react";

const TransactionHeader = ({ totalPayment }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="booking_header p-20 border-bottom">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-md-4 col-12">
              <h3 className="text-secondary mb-0 f-700">Transactions</h3>
            </div>
            <div className="col-md-8 col-12">
              <div className="ammout_checkout text-end">
                <h6 className="text-secondary f-600 mb-0">
                  Total Wallet Balance:
                  <span className="total_ammout text-primary f-700 mx-2 align-middle">
                    ${totalPayment?.toFixed(2)}
                  </span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="d-inline-block"
                  >
                    <path
                      d="M8.90039 7.56023C9.21039 3.96023 11.0604 2.49023 15.1104 2.49023H15.2404C19.7104 2.49023 21.5004 4.28023 21.5004 8.75023V15.2702C21.5004 19.7402 19.7104 21.5302 15.2404 21.5302H15.1104C11.0904 21.5302 9.24039 20.0802 8.91039 16.5402"
                      stroke="#1F74CA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 12H14.88"
                      stroke="#1F74CA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.6504 8.6499L16.0004 11.9999L12.6504 15.3499"
                      stroke="#1F74CA"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHeader;
