import React from 'react';
import WhatsInApp from '../homePage/section/whatsInApp/WhatsInApp';
import ContactUs from '../homePage/section/contactUs/ContactUs';
import './index.css';
import LatestBlog from './sections/latestblog/LatestBlog';
import RecentBlog from './sections/recentposts/RecentBlog';

const Blog = () => {
  return (
    <>
    <RecentBlog />
    <LatestBlog />
       <WhatsInApp />
      <ContactUs />
    </>
  )
}

export default Blog
