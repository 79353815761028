import React, { useEffect, useState } from "react";
import search_icon from "../../../../../assets/images/search-icon.svg";
// import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { search_guide, toprated_guide } from "../../../../../config/endpoints";
import { getApi } from "../../../../../config/apiFunctions";
import { TOPRATED_GUIDE } from "../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { useDispatch } from "react-redux";
import NotFound from "../nodatafound/NotFound";
import TouristGuideList from "../touristguidelists/TouristGuideList";
import SearchMapFilter from "../searchMap/SearchMapFilter";

const Search = () => {
  const dispatch = useDispatch();
  const [show, setshow] = useState(false);
  const [pageCount, setPagecount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [squery, setSquery] = useState(null);
  const [defaultlist, setdefaultlist] = useState(false);
  const [isLoading, setLoading] = useState(false);

  ///search api...........................
  useEffect(
    () => {
      setCurrentPage(1);
    },
    [pageCount],
    squery
  );

  const handlechange = (e) => {
    setSquery(e);
  };
  useEffect(() => {
    if (squery !== null) {
      onSubmit();
    }
  }, [squery, currentPage]);

  const onSubmit = () => {
    setLoading(true);
    if (squery?.length !== 0) {
      getApi(
        `${search_guide}?destination=${squery}&page_no=${currentPage}&min_price=179&max_price=181&rating=`
      )
        .then((res) => {
          setLoading(false);
          setPagecount(res.data.data.counts);
          dispatch(TOPRATED_GUIDE(res.data.data));
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setshow(true);
    }
    setLoading(false);
  };

  return (
    <>
      <section className="hero">
        <div className="container spacer-y">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 text-center mb-40">
              <h1 className="pb-0 mb-3" data-aos="fade-up" data-aos-delay="300">
                Find Tourist guides
                {/* Experience */}
              </h1>
              <p data-aos="fade-up" data-aos-delay="200">
                Now, find the incredible tour guides at your ease with Siesta.
                Here, you will get Tourist guides with years of experience
                willing to serve you with the best traveling experience.
                {/* Check out for different places and experiences */}
              </p>
            </div>
            <div className="col-lg-12">
              <div
                className="search-form"
                data-aos="fade-up"
                sdata-aos-delay="300"
              >
                <input
                  type="search"
                  name="destination"
                  id=""
                  placeholder="Search by Country, State, City , Zipcode"
                  onChange={(e) => handlechange(e.target.value)}
                  className="form-control theme-control search-bar"
                />
                <button
                  type="submit"
                  className="border-0 search-button"
                  onClick={onSubmit}
                >
                  <img src={search_icon} className="m-auto " alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <TouristGuideList
        setPagecount={setPagecount}
        pageCount={pageCount}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setdefaultlist={setdefaultlist}
        defaultlist={defaultlist}
        show={show}
        isLoading={isLoading}
        setLoading={setLoading}
        squery={squery}
        setShow={() => setshow(false)}
      />
      {/* <SearchMapFilter/> */}
    </>
  );
};

export default Search;
