import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  guide_compose,
  guide_user_list,
  // guide_voice_Reply,
  traveler_compose,
  traveler_user_list,
  // traveler_voice_Reply,
} from "../../../../../../../config/endpoints";
import { getApi, postApi } from "../../../../../../../config/apiFunctions";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { IoMicCircleOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
// import { GrSend } from "react-icons/gr";

const Compose = (props) => {
  const recorderControls = useAudioRecorder();
  const location = useLocation();
  const { startRecording, isRecording } = recorderControls;

  const User = useSelector((state) => state.auth.SignupUser);
  const [list, setList] = useState(null);
  const [filteredItem, setFilteredItem] = useState(null);
  const [btnDisabled, setBtnDisable] = useState(false);
  const [preview, setPreview] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [getId, setGetId] = useState(location?.state?.id);
  const [isSubmit, setSubmit] = useState(false);
  const [isSubmitSuccessfull, setSubmitSuccessfull] = useState(false);
  const [selectedId, setslectedId] = useState(false);
  // const [threadlist, setThreadlist] = useState(null);
  const [blobObj, setBlobObj] = useState(null);
  // const [hasMic, setHasMic] = useState(false);
  // const [isStarted, setIsStarted] = useState(false);
  const toast = useToast();
  // const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    // trigger,
    // clearErrors,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  const getuserEmail = () => {
    let user;
    if (User.role_name === "GUIDE") {
      user = guide_user_list;
    } else {
      user = traveler_user_list;
    }
    getApi(user, {}, User.access_token).then((res) => {
      setList(res.data.data);
    });
  };
  useEffect(() => {
    reset();
    getuserEmail();
    setFilteredItem(null);
  }, [isSubmitSuccessfull]);

  // const handleChange = (e) => {
  //   setGetId(e);
  // };
  ///compose new message

  const addAudioElement = (blob) => {
    const myFile = new File([blob], `voice.mp3`, {
      type: blob.type.split(";")[0],
    });
    setBlobObj(myFile);
    setPreview(true);
  };

  const onSubmit = (e) => {
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_compose;
    } else {
      api = traveler_compose;
    }
    setSubmit(true);
    setBtnDisable(true);
    if (!e.message_text && !blobObj) {
      toast({
        title: "Voice message or text message is required",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
      setSubmit(false);
    } else {
      // const obj = {
      //   message_text: e.message_text,
      //   receiver_user_id: JSON.parse(e.receiver_user_id),
      //   subject: e.subject,
      // };
      const fomData = new FormData();
      fomData.append("message_text", e.message_text || "");
      // fomData.append("receiver_user_id", JSON.parse(e.receiver_user_id));
      fomData.append("receiver_user_id", e.receiver_user_id);
      fomData.append("subject", e.subject);
      fomData.append("voice_record", blobObj || "");
      postApi(api, fomData, User.access_token)
        .then((res) => {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          props.onHide();
          setSubmitSuccessfull(true);
          reset();
          setSubmit(false);
          setFilteredItem(null);
          sessionStorage.removeItem("message_id");
        })
        .catch((err) => {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          setSubmit(false);
        });
      setSubmit(false);
      reset();
    }
  };

  useEffect(() => {
    if (list?.length) {
      setslectedId(true);
    }
  }, [list]);

  useEffect(() => {
    // setslectedId(sessionStorage.getItem("message_id"));

    const findItem = list;
    if (findItem && getId) {
      props.isOpenById();
      const filterArray = findItem.filter(
        (item) => item.participant_id === parseFloat(getId)
      );
      // console.log(filterArray, "filterArrray");
      setValue("receiver_user_id", getId);
      setFilteredItem(filterArray);
      // const allItems = [...filterArray, ...findItem];
      setList(filterArray);
      // console.log(allItems);
    }
  }, [selectedId, getId]);

  const micError = () => {
    try {
      recorderControls();
    } catch (error) {
      alert("Requested device not found", error);
    }
  };
  const micFinded = () => {
    startRecording();
  };

  const handleVoiceRecording = () => {
    navigator?.getUserMedia({ audio: true }, micFinded, micError);
  };

  const handleDiscard = () => {
    setBlobObj(null);
    setPreview(false);
  };
  // console.log(list);

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content p-30">
        <div className="modal_header position-relative">
          <h3 className="text-secondary f-700 mb-2">Write message</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              props.onHide();
              setPreview(false);
              setBlobObj(null);
              reset();
            }}
          ></button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body p-0 mt-3">
            <div className="form w-100">
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-2">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="mail_to"
                >
                  To
                </label>
                <div className="position-relative w-100">
                  {/* <input className="form-control" type="email" name="mail_to" placeholder="" />
                   */}
                  {(filteredItem?.length || !filteredItem?.length) && (
                    <select
                      {...register("receiver_user_id")}
                      name="receiver_user_id"
                      id="receiver_user_id"
                      // onChange={(e) => {
                      //   // handleChange(e.target.value);
                      //   setValue("receiver_user_id", e.target.value);
                      //   setGetId(e.target.value);
                      // }}
                      className={`form-control ${
                        errors.receiver_user_id ? "is-invalid" : ""
                      }`}
                    >
                      {filteredItem?.length ? (
                        <>
                          {filteredItem?.map((item, i) => (
                            <option
                              key={i}
                              value={item?.participant_id}
                              selected
                              disabled
                            >
                              {item?.name}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="" selected>
                          --Select--
                        </option>
                      )}

                      {!filteredItem?.length && (
                        <>
                          {list?.map((item, i) => {
                            return (
                              <>
                                <option key={i} value={item?.participant_id}>
                                  {item?.name}
                                </option>
                              </>
                            );
                          })}
                        </>
                      )}
                    </select>
                  )}

                  <div className="invalid-feedback">
                    {errors.receiver_user_id?.message}
                  </div>
                </div>
              </div>
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-2">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="mail_subject"
                >
                  Subject
                </label>
                <div className="position-relative w-100">
                  <input
                    type="text"
                    name="mail_subject"
                    placeholder=""
                    {...register("subject")}
                    className={`form-control ${
                      errors.subject ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.subject?.message}
                  </div>
                </div>
              </div>
              <div className="input-box d-flex align-items-start justify-content-start flex-column mb-2">
                <label
                  className="small-text text-secondary mb-1 f-600"
                  htmlFor="mail_subject"
                >
                  Message
                </label>
                {props.voiceMsgs ? (
                  <>
                    <span
                      className={`${
                        preview ? "d-flex" : "d-none"
                      } justify-space-between align-items-center gap-2`}
                    >
                      <FaRegTrashAlt
                        size={20}
                        onClick={handleDiscard}
                        style={{ cursor: "pointer" }}
                      />
                      <audio
                        controls
                        className="audio-wrap"
                        controlsList="nodownload"
                        src={blobObj && URL.createObjectURL(blobObj)}
                      ></audio>
                    </span>
                    <span className={isRecording ? "" : "d-none"}>
                      <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        recorderControls={recorderControls}
                        audioTrackConstraints={{
                          noiseSuppression: true,
                          echoCancellation: true,
                        }}
                      />
                    </span>
                    <button
                      disabled={isLoading}
                      type="button"
                      // {...bind()}
                      onClick={() => {
                        handleVoiceRecording();
                      }}
                      style={{ cursor: "pointer" }}
                      className={isRecording || preview ? "d-none" : ""}
                    >
                      <IoMicCircleOutline size={30} />
                    </button>
                  </>
                ) : (
                  <>
                    <div className="position-relative w-100">
                      <textarea
                        name="compose_mail"
                        id=""
                        cols="30"
                        rows="3"
                        {...register("message_text")}
                        className={`form-control ${
                          errors.message_text ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.message_text?.message}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center justify-content-end border-0 p-0 w-100">
            {btnDisabled === true ? (
              <button
                className="composebtn btn-main btn-primary text-white w-100 mw-50"
                type="submit"
                disabled={btnDisabled}
              >
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </button>
            ) : (
              <button
                className="composebtn btn-main btn-primary text-white w-100 mw-50"
                type="submit"
                disabled={btnDisabled}
              >
                Send
              </button>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Compose;
const Schema = Yup.object().shape({
  receiver_user_id: Yup.string().required("This field is required"),
  subject: Yup.string()
    .trim(" ")
    .required("This field is required")
    .max(50, "Max limit is 50 characters only")
    .matches(/^[0-9a-zA-Z,."?'-\s]+$/, "Special characters are not allowed")
    .test(
      "no-email-phone",
      "Email addresses and phone numbers are not allowed.",
      (value) => {
        const emailPattern = /\S+@\S+\.\S+/;
        const phonePattern = /\d{6,}/;
        return !(emailPattern.test(value) || phonePattern.test(value));
      }
    ),
  // message_text: Yup.string()
  //   .required("This field is required")
  //   .min(2, "Too Short")
  //   .trim(" ")
  //   .max(600, "Maximum character length is 600")
  //   .matches(/^[0-9a-zA-Z,."?'-\s]+$/, "Special characters are not allowed")
  //   // .matches(/\S+@\S+\.\S+/, "Email is not allowed")
  //   .test(
  //     "no-email-phone",
  //     "Email addresses and phone numbers are not allowed.",
  //     (value) => {
  //       const emailPattern = /\S+@\S+\.\S+/;
  //       const phonePattern = /\d{6,}/;
  //       const phonePatternNew =
  //         /\d{10}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}.\d{3}.\d{4}/;
  //       return !(
  //         emailPattern.test(value) ||
  //         phonePattern.test(value) ||
  //         phonePatternNew.test(value)
  //       );
  //     }
  //   ),
  message_text: Yup.string(),
  voice_record: Yup.string(),
});
