import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  delete_guide_account,
  delete_traveller_account,
} from "../../../../../../../../config/endpoints";
import { postApi } from "../../../../../../../../config/apiFunctions";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SIGNUP_USER_DETAILS } from "../../../../../../../../redux/slices/auth/LoginSlice";
import { persistor } from "../../../../../../../../redux/store/store";

const DeleteAccountModal = (props) => {
  const [btndisabled, setBtnDisable] = useState(false);
  const Userdata = useSelector((state) => state.auth.SignupUser);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });
  const onSubmit = (e) => {
    setBtnDisable(true);
    const obj = {
      reason: e.description,
    };
    const access_token = Userdata.access_token;
    let api;
    if (Userdata.role_name === "GUIDE") {
      api = delete_guide_account;
    } else if (Userdata.role_name === "TRAVELLER") {
      api = delete_traveller_account;
    }
    postApi(api, obj, access_token)
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          await persistor.purge();
          dispatch(SIGNUP_USER_DETAILS(res.data.data));
          setTimeout(() => {
            document.body.classList.remove("dash");
            document.body.classList.remove("dashboard-module");
            document.body.classList.add("website");
            navigate("/login");
          }, 50);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          props.onHide(false);
          navigate("/");
        }
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
    reset();
  };
  return (
    // <!-- Modal Window -->
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={props.show === true ? "" : "modal fade"}
        id="cancel_trip"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className=" ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content p-20">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Delete Account</h3>
                <p className="text-grey mb-2">
                  Are you sure you want to delete your account?
                  <br></br>Please let us know why.
                </p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="modal-body p-0">
                <div>
                  <div className="form" action="">
                    <div className="my-3">
                      <div className="input-box mt-3">
                        <textarea
                          name="description"
                          id=""
                          cols="30"
                          rows="4"
                          placeholder="Please write your reason here"
                          {...register("description")}
                          className={` form-control ${
                            errors.description ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.description?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 p-0">
                <a className="btn-main text-red" onClick={props.onHide}>
                  Not Now
                </a>
                <button
                  disabled={btndisabled}
                  className="btn-main btn-red"
                  type="submit"
                >
                  {btndisabled ? (
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default DeleteAccountModal;

const Schema = Yup.object().shape({
  description: Yup.string()
    .required("This field is required*")
    .max(200, "Please enter 200 characters only")
    .min(5, "Too Short!"),
  //   .matches(/^(?=.*[a-zA-Z])(?!.*[^ a-zA-Z]).*$/, "Please enter valid characters"),
});
