import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postApi } from "../../../config/apiFunctions";
import { useToast } from "@chakra-ui/react";
import { emailRegExp } from "../../../config/common/common";
import facebook from "../../../assets/images/facebook.svg";
import instagram from "../../../assets/images/instagram.svg";
import EmailVerificationPopup from "../emailverify/EmailVerificationPopup";

function TravellerWaitingListForm() {
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();
  const [message, setMessage] = useState(`
    <div>Welcome to <b>Siesta Travel!</b></div> 
    </br> 
    <div>You've taken the first step towards unforgettable travel experiences. As a valued member of our advisory panel, we’re actively redefining what travel can be. We’ll be reaching out to you soon to gather your input and help us shape the future of travel. </div>
    </br> 
    <div>Thank you for your patience and excitement!</div>
    <div class="dialog_footer"> Follow us on <a href='https://www.facebook.com/siestaglobal'> <img src=${facebook} alt="social" /></a> <a href='https://www.instagram.com/siestaglobal/'> <img src=${instagram} alt="social" /></a>
    </div>
    `);
  const emailVerificationPopupMsg = `
    <div>Welcome to <b>Siesta Travel!</b></div> 
    </br> 
    <div>You've taken the first step towards unforgettable travel experiences. As a valued member of our advisory panel, we’re actively redefining what travel can be. We’ll be reaching out to you soon to gather your input and help us shape the future of travel. </div>
    </br> 
    <div>Thank you for your patience and excitement!</div>
    <div class="dialog_footer"> Follow us on <a href='https://www.facebook.com/siestaglobal'> <img src=${facebook} alt="social" /></a> <a href='https://www.instagram.com/siestaglobal/'> <img src=${instagram} alt="social" /></a>
    </div>
    `;

  const [show, setShow] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const onSubmit = (e) => {
    setMessage("");
    setSubmit(true);
    const obj = {
      email: e.email,
    };

    postApi("/user/pre-signup", obj).then(
      (res) => {
        if (res?.data?.success) {
          if (res.data.data.alreadyAdded === true) {
            setMessage(`
    <div>As a valued member of our advisory panel, you're already playing a key role in redefining the future of travel. We’re excited to continue working with you and will be reaching out soon to gather your valuable input as we shape unforgettable travel experiences together. </div>
    </br> 
    <div>Thank you for your patience and excitement!</div>
    <div class="dialog_footer"> Follow us on <a href='https://www.facebook.com/siestaglobal'> <img src=${facebook} alt="social" /></a> <a href='https://www.instagram.com/siestaglobal/'> <img src=${instagram} alt="social" /></a>
    </div>
    `);
          } else {
            setMessage(`
    <div>Welcome to <b>Siesta Travel!</b></div> 
    </br> 
    <div>You've taken the first step towards unforgettable travel experiences. As a valued member of our advisory panel, we’re actively redefining what travel can be. We’ll be reaching out to you soon to gather your input and help us shape the future of travel. </div>
    </br> 
    <div>Thank you for your patience and excitement!</div>
    <div class="dialog_footer"> Follow us on <a href='https://www.facebook.com/siestaglobal'> <img src=${facebook} alt="social" /></a> <a href='https://www.instagram.com/siestaglobal/'> <img src=${instagram} alt="social" /></a>
    </div>
    `);
          }
          reset();
          setShow(true);
        } else {
          toast({
            title: res.data.message,
            status: "warning",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
      (err) => {
        console.log(
          "Something went wrong while letting user Join Our Panel",
          err
        );
      }
    );
  };
  useEffect(() => {
    if (!show) {
      setMessage("");
    }
  }, [show]);

  return (
    <>
      <section className="login-signup-wrapper signup-page">
        <div className="container-fluid px-0 spacer-y">
          <div className="row gy-4 gy-lg-0 gx-0 align-items-center zindex-up">
            <div className="col-lg-6 pe-lg-5 order-2 order-lg-1 spacing-left">
              <h2 className="pb-0 mb-3">Join Our Panel Today!</h2>
              <p>Unlock Your Next Adventure.</p>
              <div className="tab-teaser">
                <div className="tab-main-box">
                  <div
                    className="tab-box"
                    id="tab-1"
                    style={{ display: "block" }}
                  >
                    <form
                      className="traveler-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row gy-3 gy-lg-4">
                        <div className="col-lg-12">
                          <label
                            htmlFor=""
                            className="form-label theme-label color__paragraph"
                          >
                            Email
                          </label>
                          <input
                            {...register("email")}
                            className={`form-control theme-controls ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            placeholder="you@company.com"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <input
                            type="submit"
                            value="Join Our Panel"
                            disabled={errors.email?.message}
                            className="button-primary w-100 next-step create-profile-btn"
                          />

                          <EmailVerificationPopup
                            show={show}
                            msgType={"html"}
                            message={message}
                            onHide={() => setShow(false)}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="aside-image"></div>
            </div>
          </div>
        </div>
        <div className="gradient-overlay hero"></div>
      </section>
    </>
  );
}

export default TravellerWaitingListForm;
const Schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Please enter valid email address"),
});
