import { combineReducers } from "@reduxjs/toolkit";
import LoginSlice from "../../slices/auth/LoginSlice";
import ProfilePictureSlice from "../../slices/profilePicture/ProfilePictureSlice";
import guideSlice from "../../slices/TouristGuideReducer/guideSlice";
import travellerSlice from "../../slices/TravellerReducer/travellerSlice";
import ContentSlice from "../../slices/content/ContentSlice";

const rootReducer = combineReducers({
  auth: LoginSlice,
  profilePic: ProfilePictureSlice,
  touristGuide: guideSlice,
  travellerSlice: travellerSlice,
  content: ContentSlice,
  //  traveler: TravelerSlice,
});
export default rootReducer;
