import React from "react";
import "./PlanYourTrip.css";
// import popular_img1 from "../../../../../assets/images/popular_img1.png";
// import popular_img2 from "../../../../../assets/images/popular_img2.png";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const PlanYourTrip = ({ dataList }) => {
  const User = useSelector((state) => state.auth.SignupUser);
  const waiting_status = useSelector((state) => state.auth.waiting_status);
  const token = User?.access_token;
  return (
    <section className="travel-guide">
      <div className="container spacer-y">
        <div className="row gx-lg-5 gy-3 gy-md-4 align-items-center">
          <div className="col-lg-6 col-md-8 mx-auto d-none d-lg-block">
            <div className="image-up-down">
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image1.upload_image_url
                }
                alt=""
                data-aos="fade-up"
              />
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image2.upload_image_url
                }
                alt=""
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <h2 className="primary__color" data-aos="fade-up">
              {dataList && dataList[0]?.value?.title}
            </h2>
            <div data-aos="fade-up" data-aos-delay="200">
              {parse(`${dataList && dataList[0]?.value?.description}`)}
              {/* {waiting_status === false && token && (
                <Link to="/find-tourist-guide" className="button-primary">
                  Find Guide
                </Link>
              )}
              {!token && (
                <Link to="/login" className="button-primary">
                  Find Guide
                </Link>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanYourTrip;
