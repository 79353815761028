import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import imgnull from "../../../assets/images/profile-placeholder1.jpg";
import { persistor } from "../../../redux/store/store";
import {
  CHAT_NOTIFICATION,
  SIGNUP_USER_DETAILS,
  TOGGLE_NOTIFICATION,
} from "../../../redux/slices/auth/LoginSlice";
import Changepassword from "./modals/Changepassword";
import {
  guide_logout,
  guide_notification_toggle,
  guide_notifications,
  traveler_logout,
  traveler_notification_toggle,
  traveler_notifications,
  traveller,
  guide,
  guide_availability,
} from "../../../config/endpoints";
import { getApi, postApi, putApi } from "../../../config/apiFunctions";
import { useToast } from "@chakra-ui/react";
import {
  TRAVELER_NOTIFICATION,
  UNREAD_NOTIFICATIONS_GUIDE,
} from "../../../redux/slices/TouristGuideReducer/guideSlice";
import LogOutModal from "../../LogOutModal";
import {
  ALLNOTIFICATIONS,
  UNREAD_NOTIFICATIONS_TRAVELLER,
} from "../../../redux/slices/TravellerReducer/travellerSlice";

const Navbar = (props) => {
  const location = useLocation();
  const User = useSelector((state) => state.auth.SignupUser);
  const Users = useSelector((state) => state.auth.toggle_notification);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [userAvailability, setuserAvailability] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const [activeMenu, setActiveMenu] = useState("allNotifications");
  const [isMarkedNotification, setIsMarkedNotification] = useState(false);
  const [showChangePass, setshowChangePass] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [tab, setTab] = useState("0");
  const [bookingnotifications, setBookingnotifications] = useState("yes");
  const [paymentnotifications, setPaymentnotifications] = useState("yes");
  const [isSubmit, setisSubmit] = useState(false);

  const NotificationsCountTraveller = useSelector(
    (state) => state.travellerSlice?.unreadNotificationTravller
  );
  const NotificationsCountGuide = useSelector(
    (state) => state.touristGuide?.unreadNotificationGuide
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [allNotifications, setAllNotifications] = useState(0);
  let all_notifications =
    useSelector(
      (state) => state?.travellerSlice.allNotifications?.Notifications
    ) || 0;

  const handleChangePass = () => {
    setshowChangePass(true);
  };

  useEffect(() => {
    if (location.pathname === "/dashboard/messages") {
      dispatch(CHAT_NOTIFICATION([0]));
    } else if (
      location.pathname === "/dashboard/booking" ||
      location.pathname === "/dashboard/booking-history" ||
      location.pathname === "/dashboard/transactions"
    ) {
      setAllNotifications(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      all_notifications?.data?.typeMode === "BOOKING" ||
      all_notifications?.data?.typeMode === "BOOKING_HISTORY" ||
      all_notifications?.data?.typeMode === "RECIEVED_BOOKING" ||
      all_notifications?.data?.typeMode === "PAYMENT"
    ) {
      setAllNotifications((prev) => prev + 1);
    } else {
      setAllNotifications(0);
    }
  }, [all_notifications]);

  const handleChange = (count) => {
    let tab = count;
    if (tab === "0") {
      setTab("0");
      setBookingnotifications("yes");
      setPaymentnotifications("yes");
    } else if (tab === "1") {
      setTab("1");
      setBookingnotifications("yes");
      setPaymentnotifications("no");
    } else if (tab === "2") {
      setTab("2");
      setBookingnotifications("no");
      setPaymentnotifications("yes");
    }
  };

  const notificationHandlechange = async (e) => {
    dispatch(TOGGLE_NOTIFICATION(e.target.checked));
    setBtnDisable(true);
    let type = e.target.checked;
    // return false;
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_notification_toggle;
    } else {
      api = traveler_notification_toggle;
    }
    let notification;
    if (type === true) {
      notification = 1;
    } else if (type === false) {
      notification = 0;
    }
    await putApi(api, { notification: notification }, User.access_token)
      .then((res) => {
        const user = { ...User };
        const newobj = (user.notification_status = notification);
        setValue(notification);
        dispatch(SIGNUP_USER_DETAILS(user));
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const handleMarkAllNotifications = (notificationId) => {
    let notificationArray = [];
    if (!notificationId) {
      notificationArray = notifications.rows.map(
        (_notification) => _notification.id
      );
    } else if (notificationId) {
      notificationArray.push(notificationId.id);
    }
    putApi(
      `${
        User.role_name !== "GUIDE" ? traveller : guide
      }/marked-read-notification`,
      { notification_ids: notificationArray },
      User.access_token
    ).then((res) => {
      setIsMarkedNotification(!isMarkedNotification);
      setLoading(false);
    });
  };

  useEffect(() => {
    setValue(User.notification_status);
    setuserAvailability(User.availability);
  }, [value, User, userAvailability]);

  const getNotification = () => {
    getApi(
      `${
        User.role_name !== "GUIDE"
          ? traveler_notifications
          : guide_notifications
      }?page_no=${currentPage}&number_of_rows=&booking_notifications=${bookingnotifications}&payment_notifications=${paymentnotifications}`,
      {},
      User.access_token
    ).then((res) => {
      setNotifications(res.data.data);
      dispatch(
        UNREAD_NOTIFICATIONS_TRAVELLER(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
      dispatch(
        UNREAD_NOTIFICATIONS_GUIDE(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
    });
  };

  useEffect(() => {
    getNotification();
  }, [tab, isMarkedNotification, all_notifications]);

  const logout = () => {
    setIsModalOpen(true);
  };

  const handleLogout = () => {
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_logout;
    } else if (User.role_name === "TRAVELLER") {
      api = traveler_logout;
    }
    setisSubmit(true);
    postApi(api, {}, User.access_token)
      .then((res) => {
        setisSubmit(false);
        setIsModalOpen(false);
        persistor.purge();
        dispatch(SIGNUP_USER_DETAILS(res.data.data));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/login");
        }, 50);
      })
      .catch((error) => {
        setisSubmit(false);
        setIsModalOpen(false);
        persistor.purge();
        dispatch(SIGNUP_USER_DETAILS({}));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/");
        }, 50);
      });
  };

  ///scroll disabled when side nav open in small screen sizes
  useEffect(() => {
    // handleHover();
    hideIcon();
    if (props.toggle === true) {
      document.body.classList.toggle("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  });
  useEffect(() => {
    if (window.innerWidth < 1024) {
      classHiddenVisible("hidden", 0);
      hidenotificationclass("hidden", 0);
    }
  }, []);
  const classHiddenVisible = (val, opacity) => {
    const hover = document.querySelector(".notification_inner");
    hover.style.visibility = val;
    hover.style.opacity = opacity;
  };

  const handleHover = (data) => {
    if (data === "enter") {
      classHiddenVisible("visible", 1);
      setIsMarkedNotification(!isMarkedNotification);
      setAllNotifications(0);
    } else {
      classHiddenVisible("hidden", 0);
    }
  };
  ///hide notification tab when user click view all
  const hidenotificationclass = (val, opacity) => {
    const hover = document.querySelector(".profile_dropdown");
    hover.style.visibility = val;
    hover.style.opacity = opacity;
  };
  const hideIcon = (data) => {
    if (!showChangePass) {
      if (data === "enter") {
        hidenotificationclass("visible", 1);
      } else {
        hidenotificationclass("hidden", 0);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleNotificationNavigate = (list) => {
    handleHover("leave");
    handleMarkAllNotifications(list);
    if (
      list.description.includes("itinerary") ||
      list.description.includes("Itinerary")
    ) {
      if (User.role_name === "GUIDE") {
        navigate(
          `./dashboard/booking-history/booking-detail/${list.module_type_id}`
        );
      } else {
        navigate(`./dashboard/itinerary-details/${list.module_type_id}`);
      }
    } else if (
      list.description.includes("booking") ||
      list.description.includes("Booking") ||
      list.description.includes("cancelled")
    ) {
      if (User.role_name !== "GUIDE") {
        navigate(`./dashboard/my-bookings`);
      } else {
        if (list.description.includes("cancelled")) {
          navigate(`./dashboard/booking-history`);
          return;
        } else if (list.description.includes("payment")) {
          navigate(`./dashboard/transactions`);
          return;
        }
        navigate(`./dashboard/booking`);
      }
    } else if (
      list.description.includes("paid") ||
      list.description.includes("payment")
    ) {
      navigate(`./dashboard/transactions`);
    }
  };

  // useEffect(() => {
  //   setAllNotifications(0);
  // }, [
  //   location.pathname
  // ]);

  ///gyuideAvialability
  const availability_Halndler = (e) => {
    let availability;
    if (e === true) {
      setuserAvailability(1);
      availability = 1;
    } else {
      availability = 0;
      setuserAvailability(0);
    }
    putApi(
      guide_availability,
      { availability: availability },
      User.access_token
    )
      .then((res) => {
        const user = { ...User };
        const newobj = (user.availability = availability);
        dispatch(SIGNUP_USER_DETAILS(user));
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  return (
    <>
      <div className="dashboard_page_content">
        <div className="dashboard_header d-flex align-items-start justify-content-between">
          <div className="dashboard_title">
            <div
              className={
                props.togglesidenav
                  ? "toggle-menu toggle-active"
                  : "toggle-menu"
              }
            >
              <span onClick={props.show}></span>
            </div>
            {/* <Link to="/dashboard">
            <h4 className="text-black f-700 mb-0">Dashboard</h4>
          </Link> */}
          </div>
          <div className="dashboard_nav_content d-flex align-items-center justify-content-between">
            <div
              className="notification_box me-4 position-relative"
              onMouseEnter={() => handleHover("enter")}
              onMouseLeave={() => handleHover("leave")}
              onTouchStart={() => handleHover("enter")}
              // onTouchEnd={()=>handleHover("leave")}
            >
              <span className="notification_iconbox d-flex align-items-center justify-content-center bg-white position-relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 6.43994V9.76994"
                    stroke="#475467"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12.0199 2C8.3399 2 5.3599 4.98 5.3599 8.66V10.76C5.3599 11.44 5.0799 12.46 4.7299 13.04L3.4599 15.16C2.6799 16.47 3.2199 17.93 4.6599 18.41C9.4399 20 14.6099 20 19.3899 18.41C20.7399 17.96 21.3199 16.38 20.5899 15.16L19.3199 13.04C18.9699 12.46 18.6899 11.43 18.6899 10.76V8.66C18.6799 5 15.6799 2 12.0199 2Z"
                    stroke="#475467"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                  <path
                    d="M15.3299 18.8198C15.3299 20.6498 13.8299 22.1498 11.9999 22.1498C11.0899 22.1498 10.2499 21.7698 9.64992 21.1698C9.04992 20.5698 8.66992 19.7298 8.66992 18.8198"
                    stroke="#475467"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                  />
                </svg>
                {/* {allNotifications ? (
                  <span className="unread_count d-flex bg-primary text-white align-items-center justify-content-center">
                    {allNotifications}
                  </span>
                ) : null} */}
                {User.role_name === "TRAVELLER" &&
                NotificationsCountTraveller ? (
                  <span className="unread_count d-flex bg-primary text-white align-items-center justify-content-center">
                    {NotificationsCountTraveller}
                  </span>
                ) : null}
                {User.role_name === "GUIDE" && NotificationsCountGuide ? (
                  <span className="unread_count d-flex bg-primary text-white align-items-center justify-content-center">
                    {NotificationsCountGuide}
                  </span>
                ) : null}
              </span>
              <div className="mobile_notificaton show ">
                <div className="notification_inner ">
                  <div className="p-15">
                    <div className="row d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="text-secondary mb-0 f-700">
                          Notifications
                        </h5>
                        <Link
                          className="extra-small-text text-primary text-capitalize text-decoration-underline ms-auto f-600"
                          onClick={() => handleMarkAllNotifications()}
                        >
                          Mark all as read
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ul
                      className="notification_tab nav nav-pills p-15 py-0 border-bottom"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          value={"0"}
                          onClick={() => {
                            handleChange("0");
                            setActiveMenu("allNotifications");
                          }}
                          className={
                            activeMenu === "allNotifications"
                              ? "notify_tab f-500 active"
                              : "notify_tab f-500"
                          }
                          id="all_notify_tab"
                          data-bs-toggle="pill"
                          data-bs-target="#all_notifications"
                          type="button"
                          role="tab"
                          aria-controls="all_notifications"
                          aria-selected="true"
                        >
                          All{" "}
                          <span>
                            {notifications?.unreadBookingNotificationsCount +
                              notifications?.unreadPaymentNotificationsCount !==
                            0
                              ? `(${
                                  notifications?.unreadBookingNotificationsCount +
                                  notifications?.unreadPaymentNotificationsCount
                                })`
                              : ""}
                          </span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          value={"1"}
                          onClick={() => {
                            handleChange("1");
                            setActiveMenu("booking");
                          }}
                          className={
                            activeMenu === "booking"
                              ? "notify_tab f-500 active"
                              : "notify_tab f-500"
                          }
                          id="booking_tab"
                          data-bs-toggle="pill"
                          data-bs-target="#booking_notifications"
                          type="button"
                          role="tab"
                          aria-controls="booking_notifications"
                          aria-selected="false"
                        >
                          Bookings{" "}
                          <span>
                            {notifications?.unreadBookingNotificationsCount !==
                            0
                              ? `(${notifications?.unreadBookingNotificationsCount})`
                              : ""}
                          </span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          value={"2"}
                          onClick={() => {
                            handleChange("2");
                            setActiveMenu("payment");
                          }}
                          className={
                            activeMenu === "payment"
                              ? "notify_tab f-500 active"
                              : "notify_tab f-500"
                          }
                          id="payment_tab"
                          data-bs-toggle="pill"
                          data-bs-target="#payment_notifications"
                          type="button"
                          role="tab"
                          aria-controls="payment_notifications"
                          aria-selected="false"
                        >
                          Payments{" "}
                          <span>
                            {notifications?.unreadPaymentNotificationsCount !==
                            0
                              ? `(${notifications?.unreadPaymentNotificationsCount})`
                              : ""}
                          </span>
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className={
                          activeMenu === "allNotifications"
                            ? "tab-pane fade show active"
                            : "tab-pane fade show"
                        }
                        id="all_notifications"
                        role="tabpanel"
                        aria-labelledby="all_notify_tab"
                      >
                        <div className="notify_list">
                          {notifications?.rows?.length !== 0 ? (
                            <div>
                              {notifications?.rows?.map((list, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="notify_tag d-flex align-items-center justify-content-start flex-column p-15 border-bottom bg-pink-light"
                                    onClick={() =>
                                      handleNotificationNavigate(list)
                                    }
                                    style={{
                                      backgroundColor:
                                        list.status === 3 ? "" : "antiquewhite",
                                    }}
                                  >
                                    <h6 className="text-secondary f-600 mb-0 lh-38 w-100 d-flex align-items-center justify-content-between">
                                      <span className="small-text">
                                        {list.description}
                                      </span>
                                      <span className="extra-small-text text-grey ms-2">
                                        {list.timeAgo}
                                      </span>
                                    </h6>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="text-center mt-2 text-primary">
                              No data
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu === "booking"
                            ? "tab-pane fade show active"
                            : "tab-pane fade show"
                        }
                        id="booking_notifications"
                        role="tabpanel"
                        aria-labelledby="booking_tab"
                      >
                        <div className="notify_list">
                          {notifications?.rows?.length !== 0 ? (
                            <div>
                              {notifications?.rows?.map((list, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        list.status === 3 ? "" : "antiquewhite",
                                    }}
                                    className="notify_tag d-flex align-items-center justify-content-start flex-column p-15 border-bottom bg-pink-light"
                                    onClick={() =>
                                      handleNotificationNavigate(list)
                                    }
                                  >
                                    <h6 className="text-secondary f-600 mb-0 lh-38 w-100 d-flex align-items-center justify-content-between">
                                      <span className="small-text">
                                        {list.description}
                                      </span>
                                      <span className="extra-small-text text-grey ms-2">
                                        {list.timeAgo}
                                      </span>
                                    </h6>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="text-center mt-2 text-primary">
                              No data
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          activeMenu === "payment"
                            ? "tab-pane fade show active"
                            : "tab-pane fade show"
                        }
                        id="payment_notifications"
                        role="tabpanel"
                        aria-labelledby="payment_tab"
                      >
                        <div className="notify_list">
                          {notifications?.rows?.length !== 0 ? (
                            <div>
                              {notifications?.rows?.map((list, i) => {
                                return (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        list.status === 3 ? "" : "antiquewhite",
                                    }}
                                    className="notify_tag d-flex align-items-center justify-content-start flex-column p-15 border-bottom bg-pink-light"
                                    onClick={() =>
                                      handleNotificationNavigate(list)
                                    }
                                  >
                                    <h6 className="text-secondary f-600 mb-0 lh-38 w-100 d-flex align-items-center justify-content-between">
                                      <span className="small-text">
                                        {list.description}
                                      </span>
                                      <span className="extra-small-text text-grey ms-2">
                                        {list.timeAgo}
                                      </span>
                                    </h6>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p className="text-center mt-2 text-primary">
                              No data
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {notifications?.rows?.length !== 0 && (
                    <div
                      className="mobile_notificaton_footer p-15 text-center"
                      onClick={handleHover}
                    >
                      <Link
                        to="/dashboard/notification"
                        className="text-primary extra-small-text text-center"
                      >
                        View All
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="logged_profile d-flex align-items-center justify-content-between">
              <div className="logged_profile_img me-3">
                <img
                  className="img-fluid"
                  src={!User.profile_picture ? imgnull : User.profile_picture}
                  alt="logged_user"
                />
              </div>
              <div className="looged_user_details me-4">
                <h6 className="small-text text-black-light f-700 mb-1 lh-1">
                  {" "}
                  {!User.last_name
                    ? ` ${User.name} `
                    : ` ${User.name} ${User.last_name}`}
                </h6>
                <p className="extra-small-text text-grey-light mb-0">
                  {User.role_name === "TRAVELLER" ? "Traveler" : "Localite"}
                </p>
              </div>
            </div>
            <div
              className="ms-2 logged_userbox position-relative"
              onMouseEnter={() => hideIcon("enter")}
              onMouseLeave={() => hideIcon("leave")}
            >
              <span className="dropdown_icon d-flex align-items-center justify-content-end">
                <svg
                  width="6"
                  height="26"
                  viewBox="0 0 6 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.63508 5.2C1.17957 5.2 -0.000355721 4.03594 -0.000355721 2.6C-0.000355721 1.16406 1.17957 0 2.63508 0C4.09058 0 5.27051 1.16406 5.27051 2.6C5.27051 4.03594 4.09058 5.2 2.63508 5.2Z"
                    fill="#1D2939"
                  />
                  <path
                    d="M2.63508 15.5999C1.17957 15.5999 -0.000355721 14.4358 -0.000355721 12.9999C-0.000355721 11.564 1.17957 10.3999 2.63508 10.3999C4.09058 10.3999 5.27051 11.564 5.27051 12.9999C5.27051 14.4358 4.09058 15.5999 2.63508 15.5999Z"
                    fill="#1D2939"
                  />
                  <path
                    d="M2.63508 25.9998C1.17957 25.9998 -0.000355721 24.8357 -0.000355721 23.3998C-0.000355721 21.9639 1.17957 20.7998 2.63508 20.7998C4.09058 20.7998 5.27051 21.9639 5.27051 23.3998C5.27051 24.8357 4.09058 25.9998 2.63508 25.9998Z"
                    fill="#1D2939"
                  />
                </svg>
              </span>
              <div className=" profile_dropdown">
                <span className="arrow"></span>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="icon_box d-flex align-items-center justify-content-center me-2">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 5.26022C5.795 5.26022 5.625 5.09022 5.625 4.88522V3.22021C5.625 3.01521 5.795 2.84521 6 2.84521C6.205 2.84521 6.375 3.01521 6.375 3.22021V4.88522C6.375 5.09522 6.205 5.26022 6 5.26022Z"
                            fill="#98A2B3"
                          />
                          <path
                            d="M6.01042 10.1751C4.72042 10.1751 3.43542 9.97012 2.21042 9.56012C1.75542 9.41012 1.41042 9.08512 1.26042 8.67512C1.11042 8.26512 1.16042 7.79512 1.40542 7.38512L2.04042 6.32512C2.18042 6.09012 2.30542 5.65012 2.30542 5.37512V4.32512C2.30542 2.28012 3.96542 0.620117 6.01042 0.620117C8.05542 0.620117 9.71542 2.28012 9.71542 4.32512V5.37512C9.71542 5.64512 9.84042 6.09012 9.98042 6.32512L10.6154 7.38512C10.8504 7.77512 10.8904 8.24012 10.7354 8.66512C10.5804 9.09012 10.2404 9.41512 9.81042 9.56012C8.58542 9.97512 7.30042 10.1751 6.01042 10.1751ZM6.01042 1.37512C4.38042 1.37512 3.05542 2.70012 3.05542 4.33012V5.38012C3.05542 5.78512 2.89542 6.37012 2.68542 6.71512L2.05042 7.78012C1.92042 7.99512 1.89042 8.22512 1.96542 8.42512C2.04042 8.62512 2.21042 8.77512 2.45042 8.85512C4.75042 9.62012 7.28042 9.62012 9.58042 8.85512C9.79542 8.78512 9.96042 8.62512 10.0354 8.41512C10.1154 8.20512 10.0904 7.97512 9.97542 7.78012L9.34042 6.72012C9.13042 6.37512 8.97042 5.79012 8.97042 5.38512V4.33512C8.96542 2.70012 7.64042 1.37512 6.01042 1.37512Z"
                            fill="#98A2B3"
                          />
                          <path
                            d="M5.99996 11.4502C5.46496 11.4502 4.93996 11.2302 4.55996 10.8502C4.17996 10.4702 3.95996 9.94516 3.95996 9.41016H4.70996C4.70996 9.75016 4.84996 10.0802 5.08996 10.3202C5.32996 10.5602 5.65996 10.7002 5.99996 10.7002C6.70996 10.7002 7.28996 10.1202 7.28996 9.41016H8.03996C8.03996 10.5352 7.12496 11.4502 5.99996 11.4502Z"
                            fill="#98A2B3"
                          />
                        </svg>
                      </span>
                      <h6 className="small-text text-black mb-0 f-600">
                        Notification
                      </h6>
                    </div>
                    <div id="notificationToggle">
                      <div className="toggle">
                        {value === 1 ? (
                          <>
                            <input
                              disabled={btndisable}
                              className="toglenotification"
                              // value={value}
                              type="checkbox"
                              id="notification-switch"
                              onChange={notificationHandlechange}
                              checked
                            />
                            <label htmlFor="notification-switch"></label>
                          </>
                        ) : (
                          <>
                            <input
                              disabled={btndisable}
                              className="toglenotification"
                              // value={value}
                              type="checkbox"
                              id="notification-switch"
                              onChange={notificationHandlechange}
                              // checked
                            />
                            <label htmlFor="notification-switch"></label>
                          </>
                        )}
                      </div>
                    </div>
                  </li>
                  {User.role_name === "TRAVELLER" ? (
                    <></>
                  ) : (
                    <li className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span className="icon_box d-flex align-items-center justify-content-center me-2">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6 0C9.31144 0 12 2.68856 12 6C12 9.31144 9.31144 12 6 12C2.68856 12 0 9.31144 0 6C0 2.68856 2.68856 0 6 0ZM6 0.837209C3.1507 0.837209 0.837209 3.1507 0.837209 6C0.837209 8.8493 3.1507 11.1628 6 11.1628C8.8493 11.1628 11.1628 8.8493 11.1628 6C11.1628 3.1507 8.8493 0.837209 6 0.837209Z"
                              fill="#98A2B3"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.31894 6.15646C3.15541 5.99348 3.15541 5.72781 3.31894 5.56483C3.48248 5.4013 3.74759 5.4013 3.91113 5.56483L5.37736 7.03106L8.07541 4.0813C8.23168 3.91106 8.4968 3.89878 8.66703 4.05506C8.83727 4.21078 8.84954 4.4759 8.69327 4.64613L5.69941 7.91906C5.62238 8.00334 5.5141 8.05246 5.40024 8.05525C5.28582 8.05748 5.17587 8.01339 5.09494 7.93246L3.31894 6.15646Z"
                              fill="#98A2B3"
                            />
                          </svg>
                        </span>
                        <h6 className="small-text text-black mb-0 f-600">
                          Availability
                        </h6>
                      </div>
                      <div id="availabilityToggle">
                        <div className="toggle">
                          {userAvailability === 1 ? (
                            <input
                              type="checkbox"
                              id="notification-switch"
                              onChange={(e) =>
                                availability_Halndler(e.target.checked)
                              }
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              id="notification-switch"
                              onChange={(e) =>
                                availability_Halndler(e.target.checked)
                              }
                            />
                          )}
                          <label htmlFor="notification-switch"></label>
                        </div>
                      </div>
                    </li>
                  )}
                  <li className=" d-flex align-items-center justify-content-between">
                    <Link className="w-100" to="/dashboard/settings">
                      <div
                        className="d-flex align-items-center"
                        onClick={hideIcon}
                      >
                        <span className="icon_box d-flex align-items-center justify-content-center me-2">
                          {/* <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.08027 5.81C6.06527 5.81 6.05527 5.81 6.04027 5.81C6.01527 5.805 5.98027 5.805 5.95027 5.81C4.50027 5.765 3.40527 4.625 3.40527 3.22C3.40527 1.79 4.57027 0.625 6.00027 0.625C7.43027 0.625 8.59527 1.79 8.59527 3.22C8.59027 4.625 7.49027 5.765 6.09527 5.81C6.09027 5.81 6.08527 5.81 6.08027 5.81ZM6.00027 1.375C4.98527 1.375 4.15527 2.205 4.15527 3.22C4.15527 4.22 4.93527 5.025 5.93027 5.06C5.95527 5.055 6.02527 5.055 6.09027 5.06C7.07027 5.015 7.84027 4.21 7.84527 3.22C7.84527 2.205 7.01527 1.375 6.00027 1.375Z"
                              fill="#98A2B3"
                            />
                            <path
                              d="M6.0848 11.275C5.1048 11.275 4.1198 11.025 3.3748 10.525C2.6798 10.065 2.2998 9.435 2.2998 8.75C2.2998 8.065 2.6798 7.43 3.3748 6.965C4.8748 5.97 7.3048 5.97 8.7948 6.965C9.4848 7.425 9.8698 8.055 9.8698 8.74C9.8698 9.425 9.4898 10.06 8.7948 10.525C8.0448 11.025 7.06481 11.275 6.0848 11.275ZM3.7898 7.595C3.3098 7.915 3.0498 8.325 3.0498 8.755C3.0498 9.18 3.3148 9.59 3.7898 9.905C5.0348 10.74 7.1348 10.74 8.3798 9.905C8.8598 9.585 9.1198 9.175 9.1198 8.745C9.1198 8.32 8.8548 7.91 8.3798 7.595C7.1348 6.765 5.0348 6.765 3.7898 7.595Z"
                              fill="#98A2B3"
                            />
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="16"
                            height="16"
                            fill="#a5aebd"
                          >
                            <path d="M12 14V16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM14.5946 18.8115C14.5327 18.5511 14.5 18.2794 14.5 18C14.5 17.7207 14.5327 17.449 14.5945 17.1886L13.6029 16.6161L14.6029 14.884L15.5952 15.4569C15.9883 15.0851 16.4676 14.8034 17 14.6449V13.5H19V14.6449C19.5324 14.8034 20.0116 15.0851 20.4047 15.4569L21.3971 14.8839L22.3972 16.616L21.4055 17.1885C21.4673 17.449 21.5 17.7207 21.5 18C21.5 18.2793 21.4673 18.551 21.4055 18.8114L22.3972 19.3839L21.3972 21.116L20.4048 20.543C20.0117 20.9149 19.5325 21.1966 19.0001 21.355V22.5H17.0001V21.3551C16.4677 21.1967 15.9884 20.915 15.5953 20.5431L14.603 21.1161L13.6029 19.384L14.5946 18.8115ZM18 19.5C18.8284 19.5 19.5 18.8284 19.5 18C19.5 17.1716 18.8284 16.5 18 16.5C17.1716 16.5 16.5 17.1716 16.5 18C16.5 18.8284 17.1716 19.5 18 19.5Z"></path>
                          </svg>
                        </span>
                        <h6 className="small-text text-black mb-0 f-600">
                          Settings
                        </h6>
                      </div>
                    </Link>
                  </li>
                  {User?.role_name === "GUIDE" && (
                    <li className=" d-flex align-items-center justify-content-between">
                      <Link
                        className="w-100"
                        to={`/guide_detailed_page/${User.id}`}
                      >
                        <div
                          className="d-flex align-items-center"
                          onClick={hideIcon}
                        >
                          <span className="icon_box d-flex align-items-center justify-content-center me-2">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.08027 5.81C6.06527 5.81 6.05527 5.81 6.04027 5.81C6.01527 5.805 5.98027 5.805 5.95027 5.81C4.50027 5.765 3.40527 4.625 3.40527 3.22C3.40527 1.79 4.57027 0.625 6.00027 0.625C7.43027 0.625 8.59527 1.79 8.59527 3.22C8.59027 4.625 7.49027 5.765 6.09527 5.81C6.09027 5.81 6.08527 5.81 6.08027 5.81ZM6.00027 1.375C4.98527 1.375 4.15527 2.205 4.15527 3.22C4.15527 4.22 4.93527 5.025 5.93027 5.06C5.95527 5.055 6.02527 5.055 6.09027 5.06C7.07027 5.015 7.84027 4.21 7.84527 3.22C7.84527 2.205 7.01527 1.375 6.00027 1.375Z"
                                fill="#98A2B3"
                              />
                              <path
                                d="M6.0848 11.275C5.1048 11.275 4.1198 11.025 3.3748 10.525C2.6798 10.065 2.2998 9.435 2.2998 8.75C2.2998 8.065 2.6798 7.43 3.3748 6.965C4.8748 5.97 7.3048 5.97 8.7948 6.965C9.4848 7.425 9.8698 8.055 9.8698 8.74C9.8698 9.425 9.4898 10.06 8.7948 10.525C8.0448 11.025 7.06481 11.275 6.0848 11.275ZM3.7898 7.595C3.3098 7.915 3.0498 8.325 3.0498 8.755C3.0498 9.18 3.3148 9.59 3.7898 9.905C5.0348 10.74 7.1348 10.74 8.3798 9.905C8.8598 9.585 9.1198 9.175 9.1198 8.745C9.1198 8.32 8.8548 7.91 8.3798 7.595C7.1348 6.765 5.0348 6.765 3.7898 7.595Z"
                                fill="#98A2B3"
                              />
                            </svg>
                          </span>
                          <h6 className="small-text text-black mb-0 f-600">
                            Profile
                          </h6>
                        </div>
                      </Link>
                    </li>
                  )}
                  <li
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => {
                      handleChangePass();
                      hideIcon();
                    }}
                  >
                    <a className="w-100">
                      <div className="d-flex align-items-center">
                        <span className="icon_box d-flex align-items-center justify-content-center me-2">
                          <svg
                            fill="#98A2B3"
                            width="12"
                            height="12"
                            viewBox="0 0 48 48"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="invisible_box" data-name="invisible box">
                                <rect width="48" height="48" fill="none" />
                              </g>
                              <g id="Layer_7" data-name="Layer 7">
                                <g>
                                  <path d="M39,18H35V13A11,11,0,0,0,24,2H22A11,11,0,0,0,11,13v5H7a2,2,0,0,0-2,2V44a2,2,0,0,0,2,2H39a2,2,0,0,0,2-2V20A2,2,0,0,0,39,18ZM15,13a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v5H15ZM37,42H9V22H37Z" />
                                  <circle cx="15" cy="32" r="3" />
                                  <circle cx="23" cy="32" r="3" />
                                  <circle cx="31" cy="32" r="3" />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                        <h6 className="small-text text-black mb-0 f-600">
                          Change Password
                        </h6>
                      </div>
                    </a>
                  </li>
                  <Changepassword
                    show={showChangePass}
                    onHide={() => setshowChangePass(false)}
                  />

                  <li className="d-flex align-items-center justify-content-between">
                    <a className="w-100" href="/">
                      <div className="d-flex align-items-center">
                        <span className="icon_box d-flex align-items-center justify-content-center me-2">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z"
                              stroke="#98A2B3"
                              strokeWidth="0.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M5.33372 2H6.00039C4.70039 5.89333 4.70039 10.1067 6.00039 14H5.33372"
                              stroke="#98A2B3"
                              strokeWidth="0.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 2C11.3 5.89333 11.3 10.1067 10 14"
                              stroke="#98A2B3"
                              strokeWidth="0.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2 10.6667V10C5.89333 11.3 10.1067 11.3 14 10V10.6667"
                              stroke="#98A2B3"
                              strokeWidth="0.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2 5.9999C5.89333 4.6999 10.1067 4.6999 14 5.9999"
                              stroke="#98A2B3"
                              strokeWidth="0.7"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <h6 className="small-text text-black mb-0 f-600">
                          Back to website
                        </h6>
                      </div>
                    </a>
                  </li>
                  <li className="d-flex align-items-center justify-content-between">
                    <Link className="w-100" onClick={logout}>
                      <div className="d-flex align-items-center">
                        <span className="icon_box d-flex align-items-center justify-content-center me-2">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_6331_53439"
                              style={{ maskType: "luminance" }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="12"
                              height="12"
                            >
                              <path
                                d="M0 9.53674e-07H12V12H0V9.53674e-07Z"
                                fill="white"
                              />
                            </mask>
                            <g mask="url(#mask0_6331_53439)">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 10.125C0 11.1605 0.839468 12 1.875 12H6.58594C7.62147 12 8.46094 11.1605 8.46094 10.125V9.1875C8.46094 8.92862 8.25107 8.71875 7.99219 8.71875C7.7333 8.71875 7.52344 8.92862 7.52344 9.1875V10.125C7.52344 10.6428 7.1037 11.0625 6.58594 11.0625H1.875C1.35723 11.0625 0.9375 10.6428 0.9375 10.125V1.875C0.9375 1.35723 1.35723 0.9375 1.875 0.9375H6.58594C7.1037 0.9375 7.52344 1.35723 7.52344 1.875V2.8125C7.52344 3.07138 7.7333 3.28125 7.99219 3.28125C8.25107 3.28125 8.46094 3.07138 8.46094 2.8125V1.875C8.46094 0.839469 7.62147 9.53674e-07 6.58594 9.53674e-07H1.875C0.839468 9.53674e-07 0 0.839469 0 1.875V10.125Z"
                                fill="#F04438"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.59375 6.02344C4.59375 6.28232 4.80362 6.49219 5.0625 6.49219H11.4141C11.6729 6.49219 11.8828 6.28232 11.8828 6.02344C11.8828 5.76455 11.6729 5.55469 11.4141 5.55469H5.0625C4.80362 5.55469 4.59375 5.76455 4.59375 6.02344Z"
                                fill="#F04438"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.94394 7.90177C10.127 8.08483 10.4238 8.08483 10.6069 7.90177L11.6565 6.85207C11.6565 6.85207 11.6565 6.85207 11.6565 6.85207C12.1142 6.39442 12.1142 5.65246 11.6565 5.19481C11.6565 5.1948 11.6565 5.19481 11.6565 5.19481L10.6069 4.14511C10.4238 3.96205 10.127 3.96205 9.94394 4.1451C9.76088 4.32816 9.76087 4.62495 9.94393 4.80801L10.9936 5.85771C11.0851 5.94925 11.0851 6.09763 10.9936 6.18916L9.94393 7.23886C9.76087 7.42192 9.76088 7.71872 9.94394 7.90177Z"
                                fill="#F04438"
                              />
                            </g>
                          </svg>
                        </span>
                        <h6 className="small-text text-black mb-0 f-600">
                          Log out
                        </h6>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!isModalOpen && (
        <LogOutModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleModalClose={handleModalClose}
          handleLogout={handleLogout}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};

export default Navbar;
