import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getApi, postApi } from "../../../config/apiFunctions";
import { create_post, get_activities } from "../../../config/endpoints";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import GeneralForm from "../createExperience/generalForm/GeneralForm";

export default function CreateGeneral() {
  const formOptions = {
    resolver: yupResolver(Schema),
  };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const User = useSelector((state) => state.auth.SignupUser);
  const toast = useToast();
  const navigate = useNavigate();

  const [post, setPost] = useState("GENERAL");
  const [acitvityList, setActivityList] = useState();
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedActivityID, setSelectedActivityID] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [address, setAddress] = useState();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [toggleAccessibility, settoggleAccessibility] = useState("No");
  const [files, setFiles] = useState([]);
  const [multipleImages, setMultipleImages] = useState([]);
  const [mainImg, setMainImg] = useState();
  const [mainImgBinary, setMainImgBinary] = useState();
  const [mainImgType, setMainImgType] = useState(null);
  const [btndisable, setBtnDisable] = useState(false);
  const [addImages, setAddImages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [activityError, setActivityError] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [getAddressData, setGetAddressData] = useState({
    lat: "",
    long: "",
    country: "",
    state: "",
    city: "",
  });

  let country = null;
  let state = null;
  let city = null;
  let lat;
  let long;

  const startDtaehandler = (date) => {
    setStartDate(date);
  };

  const handleChangePlace = (e) => {
    setInputValue(e);
    setAddress();
    setShowSuggestions(true);
    setInputError(false);
  };

  const handleSelectPlace = (address) => {
    setShowSuggestions(false);
    setAddressError(false);
    setAddress(address);
    setInputValue(address);
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]);

        const addressComponents = results[0].address_components;

        addressComponents.forEach((component) => {
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("administrative_area_level_3") ||
            component.types.includes("postal_town")
          ) {
            city = component.long_name;
          }
        });
        lat = results[0].geometry.location.lat();
        long = results[0].geometry.location.lng();

        setGetAddressData({
          ...getAddressData,
          lat: lat,
          long: long,
          country: country,
          state: state,
          city: city || "",
        });

        // setGetAddressData({
        //   ...getAddressData,
        //   lat: lat,
        //   long: long,
        // });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleGeneralForm = () => {
    setPost("GENERAL");
    setMultipleImages([]);
    setMainImgBinary([]);
    setFiles([]);
    setMainImg();
    setAddress();
    reset();
  };

  const handleExperienceForm = () => {
    setPost("EXPERIENCE");
    setMultipleImages([]);
    setMainImgBinary([]);
    setFiles([]);
    setMainImg();
    setAddress();
    reset();
  };

  const handleToggleChange = (event) => {
    settoggleAccessibility(event.target.checked ? "Yes" : "No");
  };

  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const newFiles = filesArray.map((file) => ({
        url: URL.createObjectURL(file),
        type: file.type,
      }));
      setFiles((prev) => [...prev, ...filesArray]);
      setAddImages((prev) => [...prev, ...filesArray]);
      setMultipleImages((prevImages) => prevImages.concat(newFiles));
    }
  };

  const handleRemove = (i, isNewIndexvalue = -1) => {
    if (isNewIndexvalue) {
      const _UPLOAD_IMG_INDEX = i - (multipleImages.length - addImages.length);
      setAddImages(
        addImages.filter((item, _index) => _index !== _UPLOAD_IMG_INDEX)
      );
    }
    setMultipleImages((current) => {
      return current.filter((item, index) => index !== i);
    });
    setFiles((current) => {
      return current.filter((item, index) => index !== i);
    });
  };

  const selectMainImg = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const file = files[0];
      const fileType = file.type;
      if (fileType.startsWith("image/")) {
        setMainImgType("image");
      } else if (fileType.startsWith("video/")) {
        setMainImgType("video");
      }
      const fileUrl = URL.createObjectURL(file);
      setMainImg(fileUrl);
    }

    setMainImgBinary(e.target.files[0]);
  };

  const getActivitiesList = () => {
    getApi(get_activities).then((res) => {
      if (res.status === 200) {
        setActivityList(res?.data?.data);
      }
    });
  };

  const onSubmit = (e) => {
    setBtnDisable(true);
    if (selectedActivityID?.length === 0) {
      setBtnDisable(false);
      setActivityError(true);
      setInputError(true);
    } else if (!inputValue) {
      setBtnDisable(false);
      setInputError(true);
    } else if (!address) {
      setBtnDisable(false);
      setAddressError(true);
    } else if (!mainImg) {
      toast({
        title: "Primary media is required",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (files.length > 4) {
      toast({
        title: "Secondary media length not be more than 4",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else {
      const formdata = new FormData();
      if (post === "GENERAL") {
        for (let i = 0; i < files.length; i++) {
          formdata.append("media", files[i]);
        }
        formdata.append("post_type", post);
        formdata.append("title", e.title);
        // formdata.append("activities", e.activity);
        // formdata.append("activities", selectedActivity);
        for (let i = 0; i < selectedActivityID.length; i++) {
          formdata.append("activities", selectedActivityID[i]);
        }
        formdata.append("description", e.description);
        formdata.append("hero_image", mainImgBinary);
        formdata.append("location", address);
        formdata.append("price", e.price);
        formdata.append("country", getAddressData.country || "USA");
        formdata.append("state", getAddressData.state || "Arizona");
        if (getAddressData.city) {
          formdata.append("city", getAddressData.city || "Arizona");
        }
        formdata.append("longitude", getAddressData.long || 111);
        formdata.append("latitude", getAddressData.lat || 34);
      } else {
        for (let i = 0; i < files.length; i++) {
          formdata.append("media", files[i]);
        }
        formdata.append("post_type", post);
        formdata.append("title", e.title);
        // formdata.append("activities", selectedActivity);
        for (let i = 0; i < selectedActivityID.length; i++) {
          formdata.append("activities", selectedActivityID[i]);
        }
        formdata.append("schedule", e.detailed_schedule);
        formdata.append("transport_type", e.transport_type);
        formdata.append(
          "accessibility",
          toggleAccessibility === "Yes" ? true : false
        );
        formdata.append("max_people", e.max_people);
        formdata.append("min_people", e.min_people);
        formdata.append("starting_time", startDate);
        formdata.append("duration", e.duration);
        formdata.append("meeting_point", e.meeting_point);
        formdata.append("drop_off_point", e.drop_point);
        formdata.append("price", e.price);
        formdata.append("description", e.description);
        formdata.append("hero_image", mainImgBinary);
        formdata.append("location", address);
        formdata.append("country", getAddressData.country || "USA");
        formdata.append("state", getAddressData.state || "Arizona");
        if (getAddressData.city) {
          formdata.append("city", getAddressData.city || "Arizona");
        }
        formdata.append("longitude", getAddressData.long || 111);
        formdata.append("latitude", getAddressData.lat || 34);
      }
      postApi(create_post, formdata, User?.access_token)
        .then((res) => {
          if (res?.status === 200) {
            setBtnDisable(false);
            toast({
              title: res?.data?.message,
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
            navigate(`/guide_detailed_page/${User.id}`);
            reset();
            setFiles([]);
            setMultipleImages([]);
            setMainImg();
            setAddress();
          }
        })
        .catch((err) => {
          setBtnDisable(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    getActivitiesList();
  }, []);

  useEffect(() => {
    if (selectedActivityID?.length > 0) {
      setActivityError(false);
    }
  }, [selectedActivityID]);

  return (
    <section className="page-header-gradient">
      <div className="container spacer-y contact-wrapper test">
        <div className="d-flex align-items-center justify-content-sm-between flex-wrap gap-2 justify-content-center">
          <h2 className="primary__color p-0 m-0">General</h2>
        </div>

        <GeneralForm
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          register={register}
          setValue={setValue}
          trigger={trigger}
          errors={errors}
          address={address}
          scriptLoaded={scriptLoaded}
          handleChangePlace={handleChangePlace}
          handleSelectPlace={handleSelectPlace}
          multipleImages={multipleImages}
          mainImg={mainImg}
          setMainImg={setMainImg}
          selectMainImg={selectMainImg}
          handleChangeRemove={handleRemove}
          changeMultipleFiles={changeMultipleFiles}
          btndisable={btndisable}
          mainImgType={mainImgType}
          acitvityList={acitvityList}
          setSelectedActivity={setSelectedActivity}
          setSelectedActivityID={setSelectedActivityID}
          inputValue={inputValue}
          showSuggestions={showSuggestions}
          addressError={addressError}
          inputError={inputError}
          activityError={activityError}
        />
      </div>
    </section>
  );
}

const Schema = Yup.object().shape({
  // hero_img: Yup.string().required("Media is required"),
  title: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .trim(" ")
    .max(50, "Please enter 50 characters only"),
  // activity: Yup.string()
  //   .required("Activity is required")
  //   .min(2, "Too Short!")
  //   .trim(" ")
  //   .max(50, "Please enter 50 characters only"),
  location: Yup.string(),
  detailed_schedule: Yup.string(),
  transport_type: Yup.string(),
  accessibility: Yup.string(),
  max_people: Yup.string(),
  min_people: Yup.string(),
  starting_time: Yup.string(),
  duration: Yup.string(),
  meeting_point: Yup.string(),
  drop_point: Yup.string(),
  price: Yup.string().required("Price is required"),
  description: Yup.string().required("Description is required"),
});
