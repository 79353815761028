import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import blog_image from "../../../../../assets/images/blog-image.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { emailRegExp } from "../../../../../config/common/common";
import { parsePhoneNumber } from "react-phone-number-input";
import { useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import moment from "moment";
import { postApi } from "../../../../../config/apiFunctions";
import { sendEnquiry } from "../../../../../config/endpoints";
import "../../../../../dashboard/dashboard.css";
import PhoneInput from "react-phone-input-2";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

const SendEnquiriesModal = (props) => {
  const [value, setvalue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const toast = useToast();
  const User = useSelector((state) => state.auth.SignupUser);
  const [btndisable, setBtnDisable] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [state, setStateSelect] = useState([]);
  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });
  const [city, setCity] = useState([]);
  const [addressState, setAddressState] = useState({
    country: User.country || "United States",
    state: User.state,
    city: User.city,
  });

  const formOptions = {
    resolver: yupResolver(Schema),
    defaultValues: {
      country: User.country || "United States",
      state: User.state,
      city: User.city,
    },
  };

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    trigger,
    setValue,
  } = useForm(formOptions);

  const { errors } = formState;
  const startDtaehandler = (date) => {
    setStartDate(date);
    setEndDate(null);
  };
  const [contactDetails, setContatDetails] = useState({
    phone: User.phone,
    isoCode: User.country_code_iso,
    dialCode: User.country_code,
  });

  const onContactNoChange = (value, country, e, formattedValue) => {
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: `+${country_code}`,
    });
    setValue("contact_no", _phone);
    trigger("contact_no");
  };

  const [ShowWithdraw, setShowWithdraw] = useState(false);
  const onSubmit = (e) => {
    const phoneNumber = parsePhoneNumber(value.toString());

    const obj = {
      ...e,
      booking_start: moment(startDate).format("YYYY-MM-DD"),
      booking_end: moment(endDate).format("YYYY-MM-DD"),
      country_code: contactDetails.dialCode,
      country_code_iso: contactDetails.isoCode,
    };
    const access_token = User.access_token;
    postApi(sendEnquiry, obj, access_token)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        }
        reset();
        props.onHide();
        setBtnDisable(false);
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
    // setSlottimeErr(false);
  };

  const setCityList = async (event) => {
    const { value } = event.target;
    setAddressState({
      ...addressState,
      city: value,
    });
  };
  const getCityList = async (event) => {
    setGetLoader({ ...getCityLoader, ["city"]: true });
    const { value } = event.target;
    const selectedState = state.find((state) => state.name === value);
    await GetCity(countryId, selectedState.id).then((result) => {
      setCity(result);
      setAddressState({
        ...addressState,
        state: value,
        city: result[0]?.name,
      });
    });
    setGetLoader({ ...getCityLoader, ["city"]: false });
  };
  const getStateList = async (event) => {
    setGetLoader({ ...getCityLoader, ["state"]: true });
    const { value } = event.target;
    const country = countries.find((country) => country.name === value);
    setCountryId(country.id);
    await GetState(country.id).then(async (stateList) => {
      setStateSelect(stateList);
      setGetLoader({ ...getCityLoader, ["city"]: true });
      await GetCity(country.id, stateList[0]?.id).then((cityList) => {
        setCity(cityList);
        setAddressState({
          ...addressState,
          country: value,
          state: stateList[0]?.name,
          city: cityList[0]?.name,
        });
      });
      setGetLoader({ ...getCityLoader, ["city"]: false });
    });
    setGetLoader({ ...getCityLoader, ["state"]: false });
  };
  const getCountriesList = async () => {
    setGetLoader({ ...getCityLoader, ["countries"]: true });
    await GetCountries().then((result) => {
      setCountries(result);
    });
    setGetLoader({ ...getCityLoader, ["countries"]: false });
  };

  const getDefaultAddress = async () => {
    await GetCountries().then(async (countries) => {
      setCountries(countries);
      let countryQuery = User.country ? User.country : "United States";
      const country = countries.find(
        (country) => country.name === countryQuery
      );
      setCountryId(country.id);
      await GetState(country.id).then(async (states) => {
        setStateSelect(states);
        let stateQuery = User.state ? User.state : states[0].name;
        const state = states.find((state) => state.name === stateQuery);
        await GetCity(country?.id, state?.id).then((result) => {
          setCity(result);
        });
      });
    });
  };
  useEffect(() => {
    // getCountriesList();
    getDefaultAddress();
  }, []);

  return (
    <Modal
      {...props}
      className="modal_webview"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="withdraw_modal_web">
        <div className="modal_header position-relative">
          <h3>Send Your Enquiry</h3>
          <p> We're here to serve, with answers that you deserve!</p>
          <button
            type="button"
            className="btn-close"
            onClick={props.onHide}
          ></button>
        </div>
        <div className="modal-body p-0 mt-3">
          <div className="">
            <form className=" row gy-3 gx-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-lg-6">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="first_name"
                  defaultValue={User.name}
                  className={`form-control theme-controls ${
                    errors.first_name ? "is-invalid" : ""
                  }`}
                  placeholder="First Name"
                  {...register("first_name")}
                />
                <div className="invalid-feedback">
                  {errors.first_name?.message}
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="last_name"
                  defaultValue={User.last_name}
                  className={`form-control theme-controls ${
                    errors.last_name ? "is-invalid" : ""
                  }`}
                  placeholder="Last Name"
                  {...register("last_name")}
                />
                <div className="invalid-feedback">
                  {errors.last_name?.message}
                </div>
              </div>
              <div className="col-lg-12">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="user_email"
                  defaultValue={User.email}
                  className={`form-control theme-controls ${
                    errors.user_email ? "is-invalid" : ""
                  }`}
                  placeholder="your@company.com"
                  {...register("user_email")}
                />
                <div className="invalid-feedback">
                  {errors.user_email?.message}
                </div>
              </div>
              <div className="col-lg-12">
                <label
                  className="small-text text-grey-scale mb-1 f-600"
                  htmlFor="Phone number"
                >
                  Phone Number
                </label>
                <PhoneInput
                  // disableDropdown
                  // country="us"
                  value={`${contactDetails.dialCode}${contactDetails.phone}`}
                  placeholder="Enter contact number"
                  countryCodeEditable={false}
                  enableSearch={true}
                  onChange={(value, country, e, formattedValue) =>
                    onContactNoChange(value, country, e, formattedValue)
                  }
                  sx={{ width: "100%" }}
                  isValid={() => (!!errors.phone ? "" : true)}
                />
                <input
                  {...register("contact_no")}
                  type="hidden"
                  value={contactDetails.phone}
                />
                {errors.contact_no && (
                  <div
                    className="text-danger mt-1 fs-6"
                    sx={{ ml: "1px" }}
                    error={true}
                  >
                    <div className="phoneerr small">
                      Please enter valid number
                    </div>
                  </div>
                )}
              </div>
              <div className="date_fixed input-box date_selector">
                <div className="row">
                  <div className="col-md-6">
                    <div className="position-relative w-100">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="start_date"
                      >
                        Start Date
                      </label>
                      <div className="position-relative w-100">
                        <DatePicker
                          className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                          selected={startDate}
                          onChange={(date) => startDtaehandler(date)}
                          dateFormat="MM-dd-yyyy"
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          minDate={new Date()}
                          showDisabledMonthNavigation
                          required
                          onFocus={(e) => e.target.blur()}
                        />
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.33301 1.5835V3.9585"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.667 1.5835V3.9585"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M2.77051 7.19629H16.2288"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.4247 10.846H12.4318"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.4247 13.221H12.4318"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M9.49598 10.846H9.50309"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M9.49598 13.221H9.50309"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M6.56629 10.846H6.5734"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M6.56629 13.221H6.5734"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="position-relative w-100 mt-3 mt-md-0">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="start_date"
                      >
                        End Date
                      </label>
                      <div className="position-relative w-100">
                        <DatePicker
                          className="form-control dob-field small-text date-field mw-100 text-start ps-5"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          dateFormat="MM-dd-yyyy"
                          required
                          onFocus={(e) => e.target.blur()}
                        />
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.33301 1.5835V3.9585"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.667 1.5835V3.9585"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M2.77051 7.19629H16.2288"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M16.625 6.72933V13.4585C16.625 15.8335 15.4375 17.4168 12.6667 17.4168H6.33333C3.5625 17.4168 2.375 15.8335 2.375 13.4585V6.72933C2.375 4.35433 3.5625 2.771 6.33333 2.771H12.6667C15.4375 2.771 16.625 4.35433 16.625 6.72933Z"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.4247 10.846H12.4318"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M12.4247 13.221H12.4318"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M9.49598 10.846H9.50309"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M9.49598 13.221H9.50309"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M6.56629 10.846H6.5734"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M6.56629 13.221H6.5734"
                            stroke="#475467"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12">
                <label
                  className="small-text text-grey-scale mb-1 f-600"
                  htmlFor="Destination"
                >
                  Destination
                </label>
                <input
                  id="myInput"
                  name="destination"
                  placeholder="Enter location where you want to go"
                  {...register("destination")}
                  className={`form-control ${
                    errors.destination ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.destination?.message}
                </div>
              </div> */}
              <div className="col-lg-12">
                <label
                  className="small-text text-grey-scale mb-1 f-600"
                  htmlFor="country"
                >
                  Country
                </label>
                <select
                  {...register("country")}
                  name="country"
                  // defaultValue={User?.country}
                  value={addressState.country}
                  onChange={(e) => getStateList(e)}
                  className={`form-control ${
                    errors.country ? "is-invalid" : ""
                  }`}
                >
                  {/* {getCityLoader.country && (
                    <option value={""}>Loading...</option>
                  )} */}

                  {countries.length > 0 &&
                    countries.map((country, index) => {
                      return (
                        <option value={country.name} key={index}>
                          {country.name}
                        </option>
                      );
                    })}
                </select>
                <div className="invalid-feedback">
                  {errors.destination?.message}
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="State"
                  className="small-text text-grey-scale mb-1 f-600"
                >
                  State
                </label>
                <select
                  {...register("state")}
                  type="text"
                  defaultValue={User?.state}
                  value={addressState?.state}
                  onChange={(e) => getCityList(e)}
                  className={`form-control ${errors.state ? "is-invalid" : ""}`}
                >
                  {/* {getCityLoader.state && (
                    <option value={""}>Loading...</option>
                  )} */}
                  {state.length > 0 &&
                    state.map((state, index) => {
                      return (
                        <option value={state.name} key={index}>
                          {state.name}
                        </option>
                      );
                    })}
                </select>
                <div className="invalid-feedback">
                  {errors.first_name?.message}
                </div>
              </div>
              <div className="col-lg-6">
                <label
                  htmlFor="City"
                  className="small-text text-grey-scale mb-1 f-600"
                >
                  City
                </label>
                <select
                  {...register("city")}
                  onChange={setCityList}
                  value={addressState.city}
                  name="city"
                  defaultValue={User?.city}
                  type="text"
                  className={`form-control ${errors.city ? "is-invalid" : ""}`}
                >
                  {getCityLoader.city && <option value={""}>Loading...</option>}
                  {city.length > 0 &&
                    city.map((city, index) => {
                      return (
                        <option value={city.name} key={index}>
                          {city.name}
                        </option>
                      );
                    })}
                </select>
                <div className="invalid-feedback">
                  {errors.first_name?.message}
                </div>
              </div>
              <div className="col-lg-12">
                <label
                  htmlFor="Message"
                  className="small-text text-grey-scale mb-1 f-600"
                >
                  Enquiry
                </label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="3"
                  {...register("message_text")}
                  className={`form-control ${
                    errors.message_text ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.message_text?.message}
                </div>
              </div>
              <div className="col-lg-12">
                <input
                  type="submit"
                  value="Send"
                  className="btn btn-primary w-100 mt-md-4"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendEnquiriesModal;
const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .max(20, "Maximum character length is 20")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Too Short!")
    .max(20, "Maximum character length is 20")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  user_email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Please enter valid email address"),
  contact_no: Yup.string()
    .required("Phone number is required.")
    .min(10, "Enter valid phone number")
    .max(15, "Enter valid phone number"),

  // destination: Yup.string()
  //   .required("Destination is required")
  //   .min(2, "Invalid Destination")
  //   .trim(" ")
  //   .max(30, "Maximum character length is 30")
  //   .matches(/^[0-9a-zA-Z\s]+$/, "Invalid Destination"),
  country: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "Country",
    // })
  ),
  state: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "State",
    // })
  ),
  city: Yup.string().max(
    30
    // allReplace(VALIDATION_MESSAGE.NAME.MAX_LENGTH, {
    //   "{LENGTH}": "30",
    //   "{NAME}": "City",
    // })
  ),
  message_text: Yup.string()
    // .required("This field is required")
    // .min(2, "Too Short")
    .trim(" ")
    .max(600, "Maximum character length is 600")
    .matches(/^[0-9a-zA-Z,."?'-\s]+$/, "Special characters are not allowed")
    // .matches(/\S+@\S+\.\S+/, "Email is not allowed")
    .test(
      "no-email-phone",
      "Email addresses and phone numbers are not allowed.",
      (value) => {
        const emailPattern = /\S+@\S+\.\S+/;
        const phonePattern = /\d{6,}/;
        const phonePatternNew =
          /\d{10}|\(\d{3}\)\s*\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}.\d{3}.\d{4}/;
        return !(
          emailPattern.test(value) ||
          phonePattern.test(value) ||
          phonePatternNew.test(value)
        );
      }
    ),
});
