import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { putApi } from "../../../../config/apiFunctions";
import {
  traveler_updatePassword,
  guide_updatePassword,
} from "../../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Changepassword = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [currentPassToggle, setcurrentPassToggle] = useState(false);
  const [BtnDisable, setBtnDisable] = useState(false);
  const [newPassToggle, setnewPassToggle] = useState(false);
  const [cnewPassToggle, setCnewPassToggle] = useState(false);
  const user = useSelector((state) => state.auth.SignupUser);
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const handleEyeOpen = (id) => {
    if (id === 1) {
      setcurrentPassToggle(!currentPassToggle);
      setnewPassToggle(false);
      setCnewPassToggle(false);
    } else if (id === 2) {
      setcurrentPassToggle(false);
      setnewPassToggle(!newPassToggle);
    } else if (id === 3) {
      setCnewPassToggle(!cnewPassToggle);
      setcurrentPassToggle(false);
    }
  };
  useEffect(() => {
    reset();
  }, [props]);

  const onFormSubmit = (e) => {
    const obj = {
      old_password: e.oldpassword,
      new_password: e.password,
    };
    setBtnDisable(true);
    let api = "";
    if (user.role_name === "GUIDE") {
      api = guide_updatePassword;
    } else if (user.role_name === "TRAVELLER") {
      api = traveler_updatePassword;
    }
    putApi(api, obj, user.access_token)
      .then((res) => {
        if (res.status === 200) {
          setBtnDisable(false);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          navigate("/dashboard");
          reset();
          props.onHide();
        }
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <div>
          <div className="modal-content p-30">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Change Password</h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div>
                  <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 position-relative">
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="password"
                    >
                      Current Password
                    </label>
                    <input
                      id="password-field"
                      type={currentPassToggle === true ? "text" : "password"}
                      name="oldpassword"
                      {...register("oldpassword")}
                      className={`form-control ${
                        errors.oldpassword ? "is-invalid" : ""
                      }`}
                    />
                    <span
                      toggle="#password-field"
                      className={
                        currentPassToggle === true
                          ? "text-grey-light small-text fa-regular field-icon toggle-password fa-eye"
                          : "text-grey-light small-text fa-regular field-icon toggle-password fa-eye-slash"
                      }
                      onClick={() => handleEyeOpen(1)}
                    ></span>
                    <div className="invalid-feedback">
                      {errors.oldpassword?.message}
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 position-relative">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="password"
                      >
                        New Password
                      </label>
                      <input
                        id="password-field"
                        type={newPassToggle === true ? "text" : "password"}
                        name="password"
                        {...register("password")}
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                      />
                      <span
                        toggle="#password-field"
                        className={
                          newPassToggle === true
                            ? "text-grey-light small-text fa-regular fa-eye field-icon toggle-password"
                            : "text-grey-light small-text fa-regular fa-eye-slash field-icon toggle-password"
                        }
                        onClick={() => handleEyeOpen(2)}
                      ></span>
                      <div className="invalid-feedback">
                        {errors.password?.message}
                      </div>
                    </div>
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 position-relative">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="password"
                      >
                        Confirm New Password
                      </label>
                      <input
                        id="password-field"
                        type={cnewPassToggle === true ? "text" : "password"}
                        // className="form-control"
                        name="cpassword"
                        {...register("cpassword")}
                        className={`form-control ${
                          errors.cpassword ? "is-invalid" : ""
                        }`}
                      />
                      <span
                        toggle="#password-field"
                        className={
                          cnewPassToggle === true
                            ? "text-grey-light small-text fa-regular fa-eye field-icon toggle-password"
                            : "text-grey-light small-text fa-regular fa-eye-slash field-icon toggle-password"
                        }
                        onClick={() => handleEyeOpen(3)}
                      ></span>
                      <div className="invalid-feedback">
                        {errors.cpassword?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 p-0">
                <button
                  disabled={BtnDisable}
                  className="btn-main btn-primary w-100 mw-100"
                  type="submit"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Changepassword;

const Schema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("This field is required")
    .min(8, "Password must be at 8 char long")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Password must be at 8 char long")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  cpassword: Yup.string()
    .required("This field is required")
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});
