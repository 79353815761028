import React from "react";
import any_question_img from "../../../../../assets/images/any_question_img.jpg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

const GoExplore = ({ dataList }) => {
  const User = useSelector((state) => state.auth.SignupUser);
  const waiting_status = useSelector((state) => state.auth.waiting_status);
  const token = User?.access_token;
  return (
    <section className="hero">
      <div className="container spacer-y">
        <div className="row gy-4 gy-md-0 align-items-center">
          <div className="col-lg-6 col-md-12 order-2 order-md-1">
            <div className="hero_content">
              <h1 className="m-0" data-aos="fade-up">
                {dataList && dataList[0]?.value?.title}
              </h1>
              <div data-aos="fade-up" data-aos-delay="200">
                {parse(`${dataList && dataList[0]?.value?.description}`)}
                {/* <div className="banner_btn">
                  {waiting_status === false && token && (
                    <Link to="/find-tourist-guide" className="button-primary">
                      Find Guide
                    </Link>
                  )}
                  {!token && (
                    <Link to="/login" className="button-primary">
                      Find Guide
                    </Link>
                  )}
                </div> */}
                <div className="header_btn pt-2">
                  {/* <Link to="/login" className="login color__paragraph button">
                    Log In
                  </Link> */}
                  <Link to="/traveller-waiting-list" className="button-primary">
                    Join Our Panel
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-8 mx-auto order-1 order-md-2 mt-5 mt-lg-0">
            <svg
              viewBox="0 0 591 500"
              className="hero-image-masking"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <defs>
                <pattern id="img" x="0" y="0" width="1" height="1">
                  <image
                    className="h-100"
                    href={
                      dataList &&
                      dataList[0]?.value?.banner_image.upload_image_url
                    }
                  />
                </pattern>
              </defs>
              <mask id="path-1-inside-1_6754_58036" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M167.587 0C75.0315 0 0 75.0315 0 167.587V332.413C0 424.969 75.0315 500 167.587 500C218.819 500 264.682 477.011 295.422 440.787C326.163 477.011 372.026 500 423.257 500C515.813 500 590.845 424.969 590.845 332.413V167.587C590.845 75.0315 515.813 0 423.257 0C372.026 0 326.163 22.9887 295.422 59.2134C264.682 22.9887 218.819 0 167.587 0Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M167.587 0C75.0315 0 0 75.0315 0 167.587V332.413C0 424.969 75.0315 500 167.587 500C218.819 500 264.682 477.011 295.422 440.787C326.163 477.011 372.026 500 423.257 500C515.813 500 590.845 424.969 590.845 332.413V167.587C590.845 75.0315 515.813 0 423.257 0C372.026 0 326.163 22.9887 295.422 59.2134C264.682 22.9887 218.819 0 167.587 0Z"
                fill="url(#img)"
              />
              <path
                d="M295.422 440.787L303.047 434.316L295.422 425.331L287.798 434.316L295.422 440.787ZM295.422 59.2134L287.798 65.6837L295.422 74.6686L303.047 65.6837L295.422 59.2134ZM10 167.587C10 80.5543 80.5543 10 167.587 10V-10C69.5087 -10 -10 69.5086 -10 167.587H10ZM10 332.413V167.587H-10V332.413H10ZM167.587 490C80.5543 490 10 419.446 10 332.413H-10C-10 430.491 69.5087 510 167.587 510V490ZM287.798 434.316C258.876 468.398 215.76 490 167.587 490V510C221.879 510 270.488 485.625 303.047 447.257L287.798 434.316ZM423.257 490C375.085 490 331.969 468.398 303.047 434.316L287.798 447.257C320.357 485.625 368.966 510 423.257 510V490ZM580.845 332.413C580.845 419.446 510.291 490 423.257 490V510C521.336 510 600.845 430.491 600.845 332.413H580.845ZM580.845 167.587V332.413H600.845V167.587H580.845ZM423.257 10C510.291 10 580.845 80.5543 580.845 167.587H600.845C600.845 69.5086 521.336 -10 423.257 -10V10ZM303.047 65.6837C331.969 31.6023 375.085 10 423.257 10V-10C368.966 -10 320.357 14.3751 287.798 52.7431L303.047 65.6837ZM167.587 10C215.76 10 258.876 31.6023 287.798 65.6837L303.047 52.7431C270.488 14.3751 221.879 -10 167.587 -10V10Z"
                fill="white"
                mask="url(#path-1-inside-1_6754_58036)"
              />
            </svg>
            {/* <!-- <img src="images/travaler_banner_img.png" className="w-100" alt="banner"> -->
                <!-- <div className="mask-image traveler-hero-image">
                </div> --> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoExplore;
