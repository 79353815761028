import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Error from "../../../../../../Errors/SearchError/Error";
import Pagination from "../../../../../../../config/pagination/Pagination";
import moment from "moment";
const ItineraryTable = (props) => {
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const nPages = props.myBookingList?.count
    ? Math.ceil(props.myBookingList?.count / 10)
    : [];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className={
              props.isLoading === true
                ? "overflow-table loader"
                : "overflow-table"
            }
            style={
              props.myBookingList?.count > 10
                ? { height: "calc(100vh - 278px)" }
                : { height: "calc(100vh - 225px)" }
            }
          >
            {props.isLoading === true && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Oval
                  height={80}
                  width={80}
                  color="#1f74ca"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#1f74ca8f"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
            {props.isLoading === false && (
              <>
                {props.myBookingList?.rows ? (
                  <table className="table custom-design" data-aos="fade-up">
                    <thead>
                      <tr className="border-bottom w-100">
                        <th className="text-secondary f-700">Guide</th>
                        {/* <th className="text-secondary f-700">Destination</th> */}
                        <th className="text-secondary f-700">Country</th>
                        <th className="text-secondary f-700">State</th>
                        <th className="text-secondary f-700">City</th>
                        {/* <th className="text-secondary f-700">Time</th> */}
                        <th className="text-secondary f-700">Start Date</th>
                        <th className="text-secondary f-700">End Date</th>
                        <th className="text-secondary f-700">No. of Days</th>
                        <th className="text-secondary f-700">Status</th>
                        <th className="text-secondary f-700">Action</th>
                      </tr>
                    </thead>
                    <tbody id="my-scrollbar-2">
                      {props.myBookingList?.rows?.map((data, i) => (
                        <tr key={i} className="border-bottom w-100">
                          <td>
                            <h6 className="text-secondary mb-1 f-600">
                              {data.user.last_name
                                ? `${data.user.name} ${data.user.last_name}`
                                : data.user.name}
                            </h6>
                            <p className="text-grey mb-0 f-500">
                              {/* {data.user.email} */}
                            </p>
                          </td>
                          {/* <td>
                            <div className="d-flex align-items-center">
                              <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.0004 11.1917C11.4363 11.1917 12.6004 10.0276 12.6004 8.5917C12.6004 7.15576 11.4363 5.9917 10.0004 5.9917C8.56445 5.9917 7.40039 7.15576 7.40039 8.5917C7.40039 10.0276 8.56445 11.1917 10.0004 11.1917Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    d="M3.01675 7.07484C4.65842 -0.141827 15.3501 -0.133494 16.9834 7.08317C17.9417 11.3165 15.3084 14.8998 13.0001 17.1165C11.3251 18.7332 8.67508 18.7332 6.99175 17.1165C4.69175 14.8998 2.05842 11.3082 3.01675 7.07484Z"
                                    stroke="#1F74CA"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </span>
                              <p className="text-secondary mb-0 f-600">
                                {data.destination}
                              </p>
                            </div>
                          </td> */}
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {data.country || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {data.state || "---"}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {data.city || "---"}
                            </p>
                          </td>
                          {/* <td>
                        <p className="text-secondary mb-0 f-600">
                          {new Date(data.createdAt).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </p>
                      </td> */}
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {/* {data.booking_start} */}
                              {moment(data.booking_start, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {/* {data.booking_end} */}
                              {moment(data.booking_end, "MM-DD-YYYY").format(
                                "MM-DD-YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <p className="text-secondary mb-0 f-600">
                              {data.NoOfDays}
                            </p>
                          </td>
                          <td>
                            {/* {data?.is_cancelled === 1 && "Cancelled"}
                              {data?.is_completed === 1 && "Completed"} */}
                            {data?.is_cancelled === 1 ? (
                              <p className=" text-danger mb-0 f-600">
                                Cancelled
                              </p>
                            ) : data?.is_completed === 1 ? (
                              <p className="text-success mb-0 f-600">
                                Completed
                              </p>
                            ) : (
                              <p className="text-success mb-0 f-600">Ongoing</p>
                            )}
                          </td>
                          <td className="d-flex align-items-center justify-content-between">
                            <Link
                              to={`/dashboard/itinerary-details/${data.id}`}
                              className="btn-main btn-primary w-100 mw-100"
                            >
                              View Detail
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Error errmsg={props.errorRes} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {props.isLoading === false && (
        <>
          {props.myBookingList?.count > 10 && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">
                        {10 * props.currentPage - 9}
                      </span>
                      to
                      <span className="mx-1 f-500">
                        {10 * props.currentPage -
                          (10 - props.myBookingList?.rows?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">
                        {props.myBookingList?.count}
                      </span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={props.currentPage}
                    setCurrentPage={props.setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ItineraryTable;
