import React, { useEffect, useState } from "react";
import EmailVerificationPopup from "../../emailverify/EmailVerificationPopup";
import { Link, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import { phone } from "phone";
import ReCAPTCHA from "react-google-recaptcha";

const GuideForm = (props) => {
  const { state } = useLocation();
  const [inputValue, setInputValue] = useState("");
  const [inputClick, setInputClick] = useState(false);

  // const [getId, setGetId] = useState(state);

  const handleInputChange = (event) => {
    const newValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setInputValue(newValue);
  };

  const hanldeChangepass = (e) => {
    props.setPassword(e.target.value);
    setpasswordError(e.target.value);
  };
  const [showPopup, setShowPopup] = useState(false);
  const [passwordError, setpasswordError] = useState();

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const hasNumber = /\d/.test(props.password);
  const hasMinimumLength = props.password?.length >= 8;
  const hasUppercase = /[A-Z]/.test(props.password);
  const hasLowercase = /[a-z]/.test(props.password);
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
    props.password
  );

  return (
    <div className="tab-box" id="tab-2" style={{ display: "block" }}>
      <form
        className="tourist-form"
        onSubmit={props.handleSubmit(props.onSubmit)}
      >
        <div className="row gy-3 gy-lg-4">
          <div className="col-lg-6">
            <label htmlFor="" className=" form-label theme-label">
              First Name
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter name"
              value={inputValue}
              // {...props.register("name")}
              {...props.register("name", {
                onChange: handleInputChange,
              })}
              className={`form-control theme-controls ${
                props.errors.name ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">{props.errors.name?.message}</div>
          </div>
          <div className="col-lg-6">
            <label htmlFor="" className="form-label theme-label">
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              placeholder="Enter last name"
              {...props.register("last_name")}
              className={`form-control theme-controls ${
                props.errors.last_name ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {props.errors.last_name?.message}
            </div>
          </div>
          <div className="col-lg-12">
            <label htmlFor="" className="form-label theme-label">
              Email
            </label>
            <input
              {...props.register("email")}
              className={`form-control theme-controls ${
                props.errors.email ? "is-invalid" : ""
              }`}
              type="email"
              name="email"
              placeholder="you@company.com"
            />
            <div className="invalid-feedback">
              {props.errors.email?.message}
            </div>
          </div>
          <div className="col-lg-12">
            <label
              htmlFor=""
              className="form-label theme-label color__paragraph"
            >
              Contact Number
            </label>
            <PhoneInput
              country="us"
              value={`${props.contactDetails.dialCode}${props.contactDetails.phone}`}
              placeholder="Enter contact number"
              countryCodeEditable={false}
              enableSearch={true}
              onChange={(value, country, e, formattedValue) => {
                props.onContactNoChange(value, country, e, formattedValue);
                setInputClick(true);
              }}
              sx={{ width: "100%" }}
              // isValid={() => (!!props.errors.phone ? "" : true)}
              isValid={(value) => {
                if (inputClick) {
                  return phone(`+${value}`).isValid ? true : false;
                } else {
                  return true;
                }
              }}
            />

            <input
              type="hidden"
              name="phone"
              minLength="10"
              maxLength="15"
              placeholder="Enter your contact number"
              {...props.register("phone")}
            />
            {/* <div className="invalid-feedback">
              {props.errors.phone?.message}
            </div> */}
            {inputClick && !props.phoneError && (
              <div
                className="text-danger mt-1"
                sx={{ ml: "1px" }}
                error={true}
                style={{ fontSize: ".875em" }}
              >
                Enter a valid Phone Number
              </div>
            )}
          </div>
          <div className="col-lg-12">
            <div className="checkbox-wrap">
              <label className="checkbox-container">
                <input
                  name="phone_condition"
                  type="checkbox"
                  {...props.register("phone_condition", {
                    onChange: props.phoneCheckHandle,
                  })}
                  checked={props.phoneCheckbox}
                />
                <div className="checkmark"></div>
              </label>
              <p className="mb-0">
                I consent to receiving SMS text messages from Siesta.
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <label htmlFor="" className="form-label theme-label">
              Password
            </label>
            <div className="input-icon-grp">
              <input
                type={props.toggle === true ? "password" : "text"}
                name="password"
                defaultValue={props.password}
                //  className="form-control theme-controls"
                placeholder="8 character or more"
                // {...props.register("password")}
                {...props.register("password", {
                  onChange: hanldeChangepass,
                })}
                className={`form-control theme-controls ${
                  props.errors.password ? "is-invalid" : ""
                }`}
                onFocus={togglePopup}
                onBlur={togglePopup}
              />
              <div className="invalid-feedback">
                {props.errors.password?.message}
              </div>
              <i
                className={
                  props.toggle === true
                    ? "fa-light  fa-eye-slash show-pass"
                    : "fa-light  fa-eye show-pass"
                }
                onClick={props.handlepass}
              ></i>
            </div>
            {showPopup && (
              <div className="popup mt-2">
                <h6>Password Requirements:</h6>
                <ul>
                  <li
                    className={
                      hasMinimumLength ? "text-success" : "text-danger"
                    }
                  >
                    At least 8 characters
                  </li>
                  <li className={hasUppercase ? "text-success" : "text-danger"}>
                    At least one uppercase letter
                  </li>
                  <li className={hasLowercase ? "text-success" : "text-danger"}>
                    At least one lowercase letter
                  </li>
                  <li className={hasNumber ? "text-success" : "text-danger"}>
                    At least one number
                  </li>
                  <li
                    className={
                      hasSpecialCharacter ? "text-success" : "text-danger"
                    }
                  >
                    At least one special character
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="col-lg-12">
            <label htmlFor="" className="form-label theme-label">
              Confirm Password
            </label>
            <div className="input-icon-grp">
              <input
                type={props.toggle1 === true ? "password" : "text"}
                name="cpassword"
                placeholder="8 character or more"
                {...props.register("cpassword")}
                className={`form-control theme-controls ${
                  props.errors.cpassword ? "is-invalid" : ""
                }`}
              />
              <i
                className={
                  props.toggle1 === true
                    ? "fa-light  fa-eye-slash show-pass"
                    : "fa-light  fa-eye show-pass"
                }
                onClick={props.handlepass1}
              ></i>
              <div className="invalid-feedback">
                {props.errors.cpassword?.message}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="checkbox-wrap">
              <label className="checkbox-container">
                <input
                  name="terms_condition"
                  type="checkbox"
                  checked={props.checkbox}
                  {...props.register("terms_condition", {
                    onChange: props.checkhandle,
                  })}
                />
                <div className="checkmark"></div>
              </label>
              <p className="mb-0">
                By signing up, you agree to our{" "}
                <Link to="/terms" className="primary__color">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy" className="primary__color">
                  Privacy Notice
                </Link>
              </p>
            </div>
          </div>
          <div className="col-lg-12">
            <ReCAPTCHA
              ref={props.recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={props.onChangeCaptcha}
            />
          </div>
          <div className="col-lg-12">
            {props.btndisable === true && props.isSubmit === true ? (
              <button
                type="submit"
                disabled={props.btndisable}
                className="button-primary w-100 next-step create-profile-btn"
              >
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </button>
            ) : (
              <input
                type="submit"
                disabled={props.btndisable}
                value="Next Step"
                className="button-primary w-100 next-step create-profile-btn"
              />
            )}
            <EmailVerificationPopup
              show={props.show}
              onHide={() => props.setShow(false)}
            />
          </div>
          <div className="col-lg-12">
            <p className="text-sm mb-0 text-center">
              Already have an account.{" "}
              <Link to="/login" className="primary__color fw-bold">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GuideForm;
