import React, { useEffect, useState } from "react";
import PaymentDetails from "./modal/PaymentDetails";
import { getApi } from "../../../../../../config/apiFunctions";
import {
  traveler_transaction_list,
  traveler_transaction_view,
} from "../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import Pagination from "../../../../../../config/pagination/Pagination";

const TravelerTransaction = ({ onLoadComponent, setOnLoadComponent }) => {
  const User = useSelector((state) => state.auth.SignupUser);
  const [showModal, setModalShow] = useState(false);
  const [data, setData] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [viewData, setViewdata] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingmodal, setLoadingmodal] = useState(false);

  //pagination...........
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  // const [countPage, setCountPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const nPages = data?.count ? Math.ceil(data?.count / 10) : [];
  const getTransactions = () => {
    setLoading(true);
    getApi(
      `${traveler_transaction_list}?page_no=${currentPage}`,
      {},
      User.access_token
    )
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  useEffect(() => {
    getTransactions();
  }, [currentPage]);

  const handleView = (booking_id) => {
    setLoadingmodal(true);
    setModalShow(true);
    getApi(
      `${traveler_transaction_view}?booking_id=${booking_id}`,
      {},
      User.access_token
    ).then((res) => {
      setViewdata(res.data.data);
      setLoadingmodal(false);
    });
  };
  return (
    <>
      <div className="table_fixed booking_innerbox">
        {isLoading === true && (
          <div
            className="loader"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {isLoading === false && (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="booking_header p-20 border-bottom">
                  <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-12 col-12">
                      <h3 className="text-secondary mb-0 f-700">
                        Transactions
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* <div className="overflow-table" data-aos="fade-up" style={{height: "calc(100vh - 280px)"}}> */}
                <div
                  className="overflow-table"
                  data-aos="fade-up"
                  style={
                    data?.count > 10
                      ? { height: "calc(100vh - 270px)" }
                      : { height: "calc(100vh - 220px)" }
                  }
                >
                  <table className="table custom-design">
                    <thead>
                      <tr className="border-bottom w-100">
                        <th className="text-secondary f-700">Tourist Guides</th>
                        <th className="text-secondary f-700">Transaction Id</th>
                        <th className="text-secondary f-700">Amount</th>
                        <th className="text-secondary f-700">Booking Id</th>
                        <th className="text-secondary f-700">
                          Transaction Date
                        </th>
                        <th className="text-secondary f-700">Status</th>
                      </tr>
                    </thead>
                    {data?.rows?.map((list, i) => {
                      return (
                        <tbody key={i}>
                          <tr className="border-bottom w-100">
                            <td>
                              <h6 className="text-secondary mb-0 lh-24 f-600">
                                {list.bookingDetails.user.last_name
                                  ? `${list.bookingDetails.user.name} ${list.bookingDetails.user.last_name}`
                                  : list.bookingDetails.user.name}
                              </h6>
                            </td>
                            <td>
                              <div>
                                <p className="text-secondary mb-0 f-600">
                                  {list.transaction_id}
                                </p>
                              </div>
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                ${list.amount}{" "}
                              </p>
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                {list.booking_id}
                              </p>
                            </td>
                            <td>
                              <p className="text-secondary mb-0 f-600">
                                {/* {new Date(list.createdAt).toLocaleDateString().replaceAll("/","-")} */}
                                {moment(list.createdAt).format("MM-DD-YYYY")}
                              </p>
                            </td>
                            <td className="">
                              <button
                                className="btn-main btn-primary f-500 w-100 mw-100"
                                onClick={() => {
                                  setTransactionStatus(list.payment_type);
                                  handleView(list.booking_id);
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
        {isLoading === false && (
          <>
            {data?.count > 10 && (
              <div className="row">
                <div className="col-md-12">
                  <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                    <div className="data_count p-20 text-grey small-text f-400">
                      <span>
                        Showing
                        <span className="mx-1 f-500">
                          {10 * currentPage - 9}
                        </span>
                        to
                        <span className="mx-1 f-500">
                          {10 * currentPage - (10 - data?.rows?.length)}
                        </span>
                        of
                        <span className="mx-1 f-500">{data?.count}</span>
                        entries
                      </span>
                    </div>
                    <Pagination
                      nPages={nPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageNumberLimit={pageNumberLimit}
                      maxPageLimit={maxPageLimit}
                      minPageLimit={minPageLimit}
                      setMinPageLimit={setMinPageLimit}
                      setMaxPageLimit={setMaxPageLimit}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <PaymentDetails
        show={showModal}
        onHide={() => setModalShow(false)}
        viewData={viewData}
        isLoading={isLoadingmodal}
        transactionData={transactionStatus}
      />
    </>
  );
};

export default TravelerTransaction;
