import React, {forwardRef, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ReactDatePicker from "react-datepicker";
import { getApi } from "../../../../../../../config/apiFunctions";
import { guide_chart } from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import moment from "moment";

const Bar = () => {
  const [income, setIncome] = useState(null);
  const [profileVisit, setProfileVisit] = useState(null);
  const [xaxis, setXaxis] = useState(null);
  const User = useSelector((state) => state.auth.SignupUser);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));
  const handleDate1 = (date) => {
    setStartDate(
      new Date(
        moment(date, "ddd MMM DD YYYY HH:mm:ss GMTZ (z)")
          .startOf("month")
          .format("YYYY-MM-DD")
      )
    );
    setEndDate(
      new Date(
        moment(date, "ddd MMM DD YYYY HH:mm:ss GMTZ (z)")
          .endOf("month")
          .format("YYYY-MM-DD")
      )
    );
  };
  const handleDate2 = (date) => {
    setEndDate(
      new Date(
        moment(date, "ddd MMM DD YYYY HH:mm:ss GMTZ (z)")
          .endOf("month")
          .format("YYYY-MM-DD")
      )
    );
  };
  const myChart = {
    options: {
      dataLabels: {
        enabled: false,
      },
      chart: {
        width: "100%",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      colors: ["#FF4560", "#62E22D"],
      stroke: {
        curve: "smooth",
        width: 2,
      },
      // title: {
      //   text: 'Activities',
      //   align: 'left',
      //   fontSize:'30px'
      // },
      // subtitle: {
      //   text: 'May- June 2023',
      //   align: 'left'
      // },
      tooltip: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            plotOptions: {
              line: {
                horizontal: true,
              },
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
            },
          },
        },
      ],
      toolbar: {
        show: false,
      },
      grid: {
        padding: {
          left: 10, // or whatever value that works
          right: 10, // or whatever value that works
        },
      },
      xaxis: {
        // categories: ['', 'Week 1', 'Week 2', 'Week 3', 'Week 4', ''],
        categories: xaxis,
      },
    },

    series: [
      {
        name: "Income",
        data: income,
      },
      {
        name: "Profile Visitors",
        data: profileVisit,
      },
    ],
  };

  useEffect(() => {
    let start_dt = moment().startOf("month").format("YYYY-MM-DD");
    let end_dt = moment().endOf("month").format("YYYY-MM-DD");
    setStartDate(new Date(start_dt));
    setEndDate(new Date(end_dt));
  }, []);

  useEffect(() => {
    if (startDate !== null) {
      getApi(
        `${guide_chart}?start_time=${startDate?.toISOString()}&end_time=${endDate?.toISOString()}`,
        {},
        User.access_token
      ).then((res) => {
        setXaxis(res.data.data.timeOfGraph);
        setIncome(res.data.data.paymentArray);
        setProfileVisit(res.data.data.visitArray);
      });
    }
  }, [startDate, endDate]);

  return (
    <div className="bar">
      <h1 className="text-secondary mb-3 ms-3 f-700 h5">Activities</h1>
      <div className="col-6 ms-3 graph_chart_wrap">
        <div className="row">
          <div className="col-3">
            <ReactDatePicker
              selected={startDate}
              onChange={(date) =>
                handleDate1(date)
              }
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMM-yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              customInput={<ExampleCustomInput />}
            />
          </div>
          <div className="col-3">
            <ReactDatePicker
              selected={endDate}
              onChange={(date) =>
                handleDate2(date)
              }
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMM-yyy"
              showMonthYearPicker
              showFullMonthYearPicker
              showTwoColumnMonthYearPicker
              customInput={<ExampleCustomInput />}
            />
          </div>
        </div>
      </div>
      <Chart
        options={myChart.options}
        series={myChart.series}
        type="line"
        height="300"
        width="100%"
      />
    </div>
  );
};

export default Bar;
