import React, { useEffect, useState } from "react";
import RejectModal from "./modals/RejectModal";
import EditItinerary from "./modals/EditItinerary";
import PaymentModal from "./modals/PaymentModal";
import { getApi } from "../../../../../../../config/apiFunctions";
import { traveller_itinerary } from "../../../../../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import parse from "html-react-parser";
import { bookingHistoryKey } from "../../../../../../../constant/configuration";

const ItineraryDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [ids, setIds] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [showrejectModal, setrejectModal] = useState(false);
  const [showeditModal, seteditModal] = useState(false);
  const [showpaymentModal, setpaymentModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  const booking_history = useSelector(
    (state) => state.touristGuide.booking_history
  );

  useEffect(() => {
    itineraryDetails();
  }, [showeditModal, showpaymentModal, showrejectModal, booking_history]);

  const itineraryDetails = () => {
    setLoading(true);
    getApi(`${traveller_itinerary}?booking_id=${id}`, {}, access_token).then(
      (res) => {
        setLoading(false);
        setData(res.data.data);
      }
    );
  };

  return (
    <div className="table_fixed booking_innerbox">
      {isLoading === true && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
      {isLoading === false && (
        <div className="container_wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="booking_header p-20 border-bottom">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-md-4">
                    <h3 className="text-secondary mb-0 f-700">
                      Itinerary Detail
                    </h3>
                    {[2, 8, 6].includes(data?.status) && (
                      <p
                        style={{ fontSize: "14px" }}
                        className=" text-danger mb-0"
                      >
                        Note: In order to view itinerary, please pay advance
                        amount.
                      </p>
                    )}
                  </div>
                  <div className="col-md-8 mt-3 mt-md-0 pr-15">
                    <div className="m-flex-start d-flex align-items-center justify-content-end">
                      {[4].includes(data?.status) && (
                        <div>
                          <a
                            className="btn-main  btn-primary me-2"
                            data-bs-toggle="modal"
                            onClick={() => {
                              navigate("/dashboard/messages", {
                                replace: true,
                                state: {
                                  id: data?.tourist_guide_user_id,
                                  show: true,
                                },
                              });
                            }}
                          >
                            Chat With Guide
                          </a>
                        </div>
                      )}
                      {[4].includes(data?.status) &&
                        data.is_completed === 0 && (
                          <div className="m-flex-start d-flex align-items-center justify-content-end">
                            <a className="btn-main btn-secondary me-2">
                              Booking Confirmed
                            </a>
                          </div>
                        )}
                      {/* {data?.isEligibleToPay === false &&
                        data.is_completed === 0 && (
                          <div className="m-flex-start d-flex align-items-center justify-content-end">
                            <a className="btn-main btn-secondary me-2">
                              Booking Confirmed
                            </a>
                          </div>
                        )} */}
                      {[4].includes(data?.status) &&
                        data.is_completed === 1 && (
                          <div className="m-flex-start d-flex align-items-center justify-content-end">
                            <a className="btn-main btn-secondary me-2">
                              Booking Completed
                            </a>
                          </div>
                        )}

                      {[1, 3, 5, 7, 9].includes(data?.status) && (
                        <div className="m-flex-start d-flex align-items-center justify-content-end">
                          <a
                            className="btn-main btn-red"
                            data-bs-toggle="modal"
                          >
                            Rejected
                          </a>
                        </div>
                      )}
                      {[4].includes(data?.status) &&
                        data.is_completed === 0 && (
                          <div className="m-flex-start d-flex align-items-center justify-content-end">
                            <a
                              className="btn-main btn-secondary me-2"
                              data-bs-toggle="modal"
                            >
                              Trip Ongoing
                            </a>
                          </div>
                        )}
                    </div>

                    <div className="m-flex-start d-flex align-items-center justify-content-end">
                      {[2, 8, 6].includes(data?.status) && (
                        <div>
                          <a
                            className="btn-main  btn-primary me-2"
                            data-bs-toggle="modal"
                            onClick={() => {
                              navigate("/dashboard/messages", {
                                replace: true,
                                state: {
                                  id: data?.tourist_guide_user_id,
                                  show: true,
                                },
                              });
                            }}
                          >
                            Chat With Guide
                          </a>
                        </div>
                      )}

                      {/* {[2, 8, 6].includes(data?.status) && (
                        <div>
                          <a
                            className="btn-main btn-secondary me-2"
                            onClick={() => {
                              setpaymentModal(true);
                              setIds(data?.id);
                              setUserEmail(data?.user.email);
                            }}
                          >
                            Pay Advance
                          </a>
                        </div>
                      )} */}
                      {data?.isEligibleToPay && (
                        <div>
                          <a
                            className="btn-main btn-secondary me-2"
                            onClick={() => {
                              setpaymentModal(true);
                              setIds(data?.id);
                              setUserEmail(data?.user.email);
                            }}
                          >
                            Pay Advance
                          </a>
                        </div>
                      )}
                      {[2, 8, 6].includes(data?.status) && (
                        <div>
                          <a
                            className="btn-main btn-red"
                            data-bs-toggle="modal"
                            onClick={() => {
                              setrejectModal(true);
                              setIds(data.id);
                            }}
                          >
                            Reject
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-table full_height">
            <div className="row">
              <div className="col-md-12">
                <div className="booking_detail_active p-20" data-aos="fade-up">
                  <div className="row align-items-center border-bottom pb-4">
                    <div className="col-md-7">
                      <div>
                        <h4 className="text-secondary mb-3 f-600">
                          {data?.user.last_name
                            ? `${data?.user.name} ${data?.user.last_name}`
                            : data?.user.name}
                        </h4>
                        {/* <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center me-3">
                            <span className="location_iconbox me-2 bg-primary-fade d-flex align-items-center justify-content-center">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.167 17.0834H5.83366C3.33366 17.0834 1.66699 15.8334 1.66699 12.9167V7.08341C1.66699 4.16675 3.33366 2.91675 5.83366 2.91675H14.167C16.667 2.91675 18.3337 4.16675 18.3337 7.08341V12.9167C18.3337 15.8334 16.667 17.0834 14.167 17.0834Z"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                                <path
                                  d="M14.1663 7.5L11.558 9.58333C10.6997 10.2667 9.29134 10.2667 8.433 9.58333L5.83301 7.5"
                                  stroke="#1F74CA"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </span>
                            <p className="text-secondary mb-0 f-600">
                              {data?.user.email}
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-5 mt-md-0 mt-3">
                      <div className="m-flex-start d-flex align-items-center justify-content-end">
                        <span className="location_iconbox me-2 bg-primary d-flex align-items-center justify-content-center">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.66797 1.66626V4.16626"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M13.332 1.66626V4.16626"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M2.91797 7.57471H17.0846"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M17.5 7.08293V14.1663C17.5 16.6663 16.25 18.3329 13.3333 18.3329H6.66667C3.75 18.3329 2.5 16.6663 2.5 14.1663V7.08293C2.5 4.58293 3.75 2.91626 6.66667 2.91626H13.3333C16.25 2.91626 17.5 4.58293 17.5 7.08293Z"
                              stroke="white"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M13.0762 11.4169H13.0836"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M13.0762 13.9169H13.0836"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.99412 11.4169H10.0016"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M9.99412 13.9169H10.0016"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.90818 11.4169H6.91567"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                            <path
                              d="M6.90818 13.9169H6.91567"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </span>
                        <h6 className="text-secondary mb-0 f-600">
                          {data?.dateDetails}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="booking_inner_content py-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h3 className="f-600 mb-0 text-black-light me-2">
                        Itinerary
                      </h3>
                      {(data?.status === 2 || data?.status === 8) && (
                        <button
                          className={"btn-main btn-square btn-primary"}
                          data-bs-toggle="modal"
                          onClick={() => {
                            seteditModal(true);
                            setIds(data.id);
                          }}
                        >
                          <svg
                            className="me-2"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.2733 1.56143C11.6567 -0.0552402 10.0733 -0.0969068 8.41499 1.56143L7.40665 2.56976C7.32332 2.65309 7.28999 2.78643 7.32332 2.90309C7.95665 5.11143 9.72332 6.87809 11.9317 7.51143C11.965 7.51976 11.9983 7.52809 12.0317 7.52809C12.1233 7.52809 12.2067 7.49476 12.2733 7.42809L13.2733 6.41976C14.0983 5.60309 14.4983 4.81143 14.4983 4.01143C14.5067 3.18643 14.1067 2.38643 13.2733 1.56143Z"
                              fill="white"
                            ></path>
                            <path
                              d="M10.4308 8.26979C10.1891 8.15312 9.9558 8.03646 9.7308 7.90312C9.54747 7.79479 9.37247 7.67812 9.19747 7.55312C9.0558 7.46146 8.88913 7.32812 8.7308 7.19479C8.71413 7.18646 8.6558 7.13646 8.58913 7.06979C8.31413 6.83646 8.0058 6.53646 7.7308 6.20312C7.7058 6.18646 7.66413 6.12812 7.6058 6.05312C7.52247 5.95312 7.3808 5.78646 7.2558 5.59479C7.1558 5.46979 7.03913 5.28646 6.9308 5.10312C6.79747 4.87812 6.6808 4.65312 6.56413 4.41979C6.41117 4.09201 5.98096 3.99464 5.72518 4.25041L1.03913 8.93646C0.9308 9.04479 0.830799 9.25312 0.805799 9.39479L0.355799 12.5865C0.272466 13.1531 0.4308 13.6865 0.7808 14.0448C1.0808 14.3365 1.49747 14.4948 1.94747 14.4948C2.04747 14.4948 2.14747 14.4865 2.24747 14.4698L5.44747 14.0198C5.59747 13.9948 5.8058 13.8948 5.9058 13.7865L10.5996 9.09268C10.8502 8.84202 10.7561 8.41074 10.4308 8.26979Z"
                              fill="white"
                            ></path>
                          </svg>
                          <span>Request Changes</span>
                        </button>
                      )}
                    </div>
                    <div className="trip_content">
                      <div className="trip_pricebox d-flex align-items-center justify-content-between">
                        <div className="me-1 mb-2 mb-md-0">
                          {data?.itinerary?.price && (
                            <h5 className="text-secondary mb-1 f-700">
                              Advance Amount:{" "}
                              <span>
                                {data?.itinerary?.price
                                  ? `$${data?.itinerary?.price}`
                                  : " NIL"}
                              </span>
                            </h5>
                          )}
                          {/* <h5 className="text-secondary mb-1 f-700">
                            Advance Amount:{" "}
                            <span>
                              {data?.itinerary?.price
                                ? `$${data?.itinerary?.price}`
                                : " NIL"}
                            </span>
                          </h5> */}
                          {/* <p className="extra-small-text text-grey-light mb-0">
                            Note: Prepayment will be required
                          </p> */}
                        </div>
                        {/* <h5 className="text-secondary mb-1 f-700 mb-2 mb-md-0">
                          Price:{" "}
                          <span className="text-primary">
                            ${data?.price ? `${data?.price}/per hour` : "NIL"}
                          </span>
                        </h5> */}
                      </div>
                      <div className="trip_pricebox d-flex align-items-center justify-content-between">
                        {data?.itinerary?.final_price && (
                          <div className="me-1 mb-2 mb-md-0">
                            <h5 className="text-secondary mb-1 f-700">
                              Final Amount:{" "}
                              <span>
                                {data?.itinerary?.final_price
                                  ? `$${data?.itinerary?.final_price}`
                                  : " NIL"}
                              </span>
                            </h5>
                            <p className="extra-small-text text-grey-light mb-0">
                              Note: Prepayment will be required
                            </p>
                          </div>
                        )}
                        {/* <div className="me-1 mb-2 mb-md-0">
                          <h5 className="text-secondary mb-1 f-700">
                            Final Amount:{" "}
                            <span>
                              {data?.itinerary?.final_price
                                ? `$${data?.itinerary?.final_price}`
                                : " NIL"}
                            </span>
                          </h5>
                          <p className="extra-small-text text-grey-light mb-0">
                            Note: Prepayment will be required
                          </p>
                        </div> */}
                      </div>
                      {/* <h6 className="text-primary text-center mb-3 f-700 text-uppercase">{data?.itinerary.title}</h6> */}
                      {data?.itinerary?.descriptions && (
                        <div className="mt-5">
                          <p className="small-text text-secondary mb-2">
                            {parse(`${data?.itinerary?.descriptions}`)}
                          </p>
                        </div>
                      )}
                      {/* <hr></hr> */}
                      {data?.booking_track_histories?.map((list, i) => {
                        return (
                          <div key={i} className="mt-5">
                            <h6 className="text-primary text-center mb-3 f-700 text-uppercase">
                              {/* {list.key.replaceAll("_", " ")} */}
                              {bookingHistoryKey[list.key]}
                            </h6>
                            <p className="small-text text-secondary mb-2">
                              {list.value}
                            </p>
                            <hr></hr>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <RejectModal
        show={showrejectModal}
        onHide={() => setrejectModal(false)}
        ids={ids}
      />
      <EditItinerary
        show={showeditModal}
        onHide={() => seteditModal(false)}
        ids={ids}
      />
      <PaymentModal
        show={showpaymentModal}
        onHide={() => setpaymentModal(false)}
        ids={ids}
        userEmail={userEmail}
      />
    </div>
  );
};

export default ItineraryDetails;
