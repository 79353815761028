import React, { useEffect, useState } from "react";
import BookinghistoryTable from "./BookinghistoryTable";
import {
  guide_notifications,
  traveler_notifications,
} from "../../../../../../../config/endpoints";
import { TRAVELER_NOTIFICATION, UNREAD_NOTIFICATIONS_GUIDE } from "../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { getApi } from "../../../../../../../config/apiFunctions";
import { useDispatch, useSelector } from "react-redux";
import { UNREAD_NOTIFICATIONS_TRAVELLER } from "../../../../../../../redux/slices/TravellerReducer/travellerSlice";

const BookinghistoryHeader = () => {
  const [popup, setShowPopup] = useState(false);
  const [values, setValues] = useState("allBooking");
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.SignupUser);

  const getNotifications = () => {
    getApi(
      `${
        User.role_name !== "GUIDE"
          ? traveler_notifications
          : guide_notifications
      }?page_no=1&number_of_rows=&booking_notifications=yes&payment_notifications=yes`,
      {},
      User.access_token
    ).then((res) => {
      dispatch(TRAVELER_NOTIFICATION(res.data.data));
      dispatch(
        UNREAD_NOTIFICATIONS_TRAVELLER(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
      dispatch(
        UNREAD_NOTIFICATIONS_GUIDE(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
    });
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleChangePopup = (e) => {
    setShowPopup(!popup);
  };
  const handleChange = (e) => {
    setValues(e.target.value);
    setCurrentPage(1);
    setShowPopup(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="booking_header p-20 border-bottom">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-4 col-8">
                <h3 className="text-secondary mb-0 f-700">Bookings History</h3>
              </div>
              <div className="col-md-8 col-4">
                <div className="filter_inner d-flex align-items-center justify-content-end me-auto position-relative">
                  <div
                    className="filter_bookingbtn d-flex align-items-center justify-content-center"
                    onClick={handleChangePopup}
                  >
                    <svg
                      className="me-3"
                      width="20"
                      height="12"
                      viewBox="0 0 20 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="2" fill="black" />
                      <rect x="3" y="5" width="14" height="2" fill="black" />
                      <rect x="6" y="10" width="8" height="2" fill="black" />
                    </svg>
                    <span className="extra-small-text text-uppercase text-black-light f-600">
                      filter
                    </span>
                  </div>
                  <div
                    className={
                      popup === true
                        ? "filter_popup show_popup"
                        : "filter_popup"
                    }
                  >
                    <div className="htmlform">
                      <div className="d-flex align-items-center justify-content-start mb-3">
                        <input
                          className="me-2"
                          type="radio"
                          id="all-booking"
                          value="allBooking"
                          name="filter_option"
                          onChange={handleChange}
                        />
                        <label
                          className="small-text text-grey-scale f-600"
                          htmlFor="all-booking"
                        >
                          All
                        </label>
                      </div>
                      <div className="d-flex align-items-center justify-content-start mb-3">
                        <input
                          className="me-2"
                          type="radio"
                          id="ongoing-booking"
                          value="ongoingBooking"
                          name="filter_option"
                          onChange={handleChange}
                        />
                        <label
                          className="small-text text-grey-scale f-600"
                          htmlFor="ongoing-booking"
                        >
                          Ongoing
                        </label>
                      </div>
                      <div className="d-flex align-items-center justify-content-start mb-3">
                        <input
                          className="me-2"
                          type="radio"
                          id="completed-booking"
                          value="completedBooking"
                          name="filter_option"
                          onChange={handleChange}
                        />
                        <label
                          className="small-text text-grey-scale f-600"
                          htmlFor="completed-booking"
                        >
                          Completed
                        </label>
                      </div>
                      <div className="d-flex align-items-center justify-content-start">
                        <input
                          className="me-2"
                          type="radio"
                          id="cancelled-booking"
                          value="cancelledBooking"
                          name="filter_option"
                          onChange={handleChange}
                        />
                        <label
                          className="small-text text-grey-scale f-600"
                          htmlFor="cancelled-booking"
                        >
                          Cancelled
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BookinghistoryTable
        values={values}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default BookinghistoryHeader;
