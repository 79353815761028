import React from "react";
import "./ExploreWorld.css";
import explore_img from "../../../../../assets/images/explore_img.png";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const ExploreWorld = ({ dataList }) => {
  return (
    <section className="explor__section">
      <div className="container spacer-y">
        <div className="row gx-lg-4 align-items-center">
          <div className="col-md-6 mx-auto">
            <div className="explore_img" data-aos="fade-up">
              <img
                src={
                  dataList && dataList[0]?.value?.banner_image?.upload_image_url
                }
                className="w-100"
                alt="explore"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
            <div className="explore_content">
              {/* <span
                className="subtitle color__grey"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Take a Tour
              </span> */}
              <h2
                className="primary__color m-0 text-capitalize"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {dataList && dataList[0]?.value?.title}
              </h2>
              <div data-aos="fade-up" data-aos-delay="400">
                <div className="paragraph_content">
                  {parse(`${dataList && dataList[0]?.value?.description}`)}
                </div>
                <Link to="/about-us" className="button-primary">
                  See More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExploreWorld;
