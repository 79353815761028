import React, { useEffect, useState } from "react";
// import imguser1 from "../../../../../../../assets/images/message-user-1.png";
import { getApi, postApi } from "../../../../../../../config/apiFunctions";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import {
  guide_threads,
  guide_treadReply,
  guide_voice_Reply,
  traveler_threads,
  traveler_treadReply,
  traveler_voice_Reply,
} from "../../../../../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Oval, ThreeDots } from "react-loader-spinner";
// import { TabPane } from "react-bootstrap";
// import { set } from "react-hook-form";
import nullImg from "../../../../../../../assets/images/profile-placeholder1.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoMicCircleOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";
import { GrSend } from "react-icons/gr";

import * as Yup from "yup";
import { Link } from "react-router-dom";

const DetailedMsg = (props) => {
  const recorderControls = useAudioRecorder();
  const toast = useToast();
  const User = useSelector((state) => state.auth.SignupUser);
  const [btnDisabled, setBtnDisable] = useState(false);
  const [preview, setPreview] = useState(false);
  const [outPreview, setOutPreview] = useState(false);
  const [blobObj, setBlobObj] = useState(null);
  const [outBlobObj, setOutBlobObj] = useState(null);
  const [threadlist, setThreadlist] = useState(null);
  const [thread, setThread] = useState([]);
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isUsed, setUsed] = useState(false);
  const [currentPageLastIndex, setCurrentPageLastIndex] = useState(null);
  const [page, setPage] = useState(1);
  // const [hasMic, setHasMic] = useState(false);
  // const [isStarted, setIsStarted] = useState(false);
  const chat_notification = useSelector(
    (state) => state.auth.chat_notification
  );
  const { startRecording, isRecording } = recorderControls;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  const handleChange = (e) => {
    setData(e);
  };

  const addAudioElement = (blob) => {
    if (isUsed && blob) {
      setUsed(false);
      return;
    }
    const myFile = new File([blob], `voice_${threadlist?.id}.mp3`, {
      type: blob.type.split(";")[0],
    });
    setBlobObj(myFile);
    setPreview(true);
  };

  const addOutAudioElement = (outBlob) => {
    setUsed(true);
    const myOutFile = new File([outBlob], `voice_${threadlist?.id}.mp3`, {
      type: outBlob.type.split(";")[0],
    });
    setOutBlobObj(myOutFile);
    setOutPreview(true);
  };

  const sendOutVoiceMessage = () => {
    setOutPreview(false);
    setLoading(true);
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_voice_Reply;
    } else {
      api = traveler_voice_Reply;
    }
    const fomData = new FormData();
    fomData.append("thread_id", threadlist?.id);
    fomData.append("voice_record", outBlobObj);
    postApi(api, fomData, User.access_token)
      .then((res) => {
        setThread([]);
        setBlobObj(null);
        setPage(0);
        // threadList();
        setData("");
        reset();
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const sendVoiceMessage = () => {
    setPreview(false);
    setLoading(true);
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_voice_Reply;
    } else {
      api = traveler_voice_Reply;
    }
    const fomData = new FormData();
    fomData.append("thread_id", threadlist?.id);
    fomData.append("voice_record", blobObj);
    postApi(api, fomData, User.access_token)
      .then((res) => {
        setThread([]);
        setBlobObj(null);
        setPage(0);
        // threadList();
        setData("");
        reset();
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const handleOutDiscard = () => {
    setOutBlobObj(null);
    setBlobObj(null);
    setOutPreview(false);
  };

  const handleDiscard = () => {
    setOutBlobObj(null);
    setBlobObj(null);
    setPreview(false);
  };

  useEffect(() => {
    if (page === 0) {
      setPage(1);
    }
    threadList();
  }, [page]);

  useEffect(() => {
    setPage(1);
    setThread([]);
    threadList();
  }, [chat_notification]);

  const threadList = () => {
    if (page === 0) {
      setPage(1);
    } else {
      if (page === 1) {
        setThread([]);
      }
      setLoading(true);
      let api;
      if (User.role_name === "GUIDE") {
        api = guide_threads;
      } else {
        api = traveler_threads;
      }
      getApi(
        `${api}?thread_id=${props.thredId}&page_no=${page}`,
        {},
        User.access_token
      ).then((res) => {
        setLoading(false);
        setThreadlist(res.data.data);
        setCurrentPageLastIndex(threadlist?.messages.length - 1);
        chatContainerRef.current?.scrollIntoView(currentPageLastIndex);
        const newArray = res.data.data.messages;
        setThread([...newArray, ...thread]);
      });
    }
  };

  //scroll chat from new page add
  const chatContainerRef = thread?.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (thread.length !== 0) {
      // const keys = Object.keys(chatContainerRef);
      let list = threadlist?.messages;
      let lastElement = list?.slice(-1);
      let id = lastElement[0]?.id;
      chatContainerRef[id]?.current?.scrollIntoView();
    }
  }, [threadlist?.totalMessageCount, thread]);

  useEffect(() => {
    document.querySelector(".message_profile_wrapperbox").addEventListener(
      "scroll",
      function () {
        var scrollTop = document.querySelector(
          ".message_profile_wrapperbox"
        ).scrollTop;
        var scrollHeight = document.querySelector(
          ".message_profile_wrapperbox"
        ).scrollHeight; // added
        var offsetHeight = document.querySelector(
          ".message_profile_wrapperbox"
        ).offsetHeight;
        var contentHeight = scrollHeight - offsetHeight;
        if (scrollTop === 0 && contentHeight !== 0) {
          if (threadlist?.totalMessageCount > page * 10) {
            setPage(page + 1);
          } else {
            setPage(page);
          }
        } else if (contentHeight === scrollTop) {
          setPage(1);
        }
      },
      false
    );
  }, [thread]);

  ///send message.....................

  const sendThread = (e) => {
    // e.preventDefault();
    let api;
    if (User.role_name === "GUIDE") {
      api = guide_treadReply;
    } else {
      api = traveler_treadReply;
    }
    setBtnDisable(true);
    const obj = {
      thread_id: threadlist?.id,
      message_text: data,
    };
    postApi(api, obj, User.access_token)
      .then((res) => {
        setThread([]);
        setPage(0);
        // threadList();
        setData("");
        reset();
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  const micError = () => {
    try {
      recorderControls(); // Try to start recording
    } catch (error) {
      alert("Requested device not found", error);
      // Handle the error here
    }
  };
  const micFinded = () => {
    startRecording();
  };

  const handleVoiceRecording = () => {
    navigator?.getUserMedia({ audio: true }, micFinded, micError);
  };

  return (
    <>
      <div className="message_profile_wrapperbox">
        <div className="w-100 p-20 message_profile_wrapper">
          {isLoading === true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "auto",
                marginBottom: "auto",
                margin: "auto",
              }}
            >
              <Oval
                height={80}
                width={80}
                color="#1f74ca"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#1f74ca8f"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
          {isLoading === false && (
            <div className="message_profile_icon">
              {thread?.map((list, i) => {
                return (
                  <>
                    <div
                      key={i}
                      className="messages_wrapper"
                      ref={chatContainerRef[list.id]}
                    >
                      <div className="d-flex align-items-center">
                        {list.sender_user_id === User.id ? (
                          <>
                            <div className="me-3 message_profile d-flex align-items-center justify-content-between me-1">
                              <img
                                className="img-fluid"
                                src={
                                  User.profile_picture
                                    ? User.profile_picture
                                    : nullImg
                                }
                                alt="message-user"
                              />
                            </div>
                            <div>
                              <h6 className="d-flex align-items-center small-text text-secondary mb-0 f-700">
                                {User.last_name
                                  ? `${User.name} ${User.last_name}`
                                  : User?.name}
                                <span className="f-400 extra-small-text text-grey-light ms-2">
                                  {moment(new Date(list?.createdAt)).fromNow()}{" "}
                                </span>
                              </h6>
                              <span className="d-flex f-400 extra-small-text text-grey-light">
                                to{" "}
                                {threadlist.receiverMessageDetails?.last_name
                                  ? `${threadlist?.receiverMessageDetails?.name} ${threadlist.receiverMessageDetails?.last_name}`
                                  : threadlist?.receiverMessageDetails?.name}
                                <svg
                                  className="ms-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.6209 5.22083L7.81753 9.02416C7.36836 9.47333 6.63336 9.47333 6.18419 9.02416L2.38086 5.22083"
                                    stroke="#98A2B3"
                                    strokeWidth="1.1"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="me-3 message_profile d-flex align-items-center justify-content-between me-1">
                              <img
                                className="img-fluid"
                                src={
                                  threadlist?.receiverMessageDetails
                                    ?.user_detail?.profile_picture
                                    ? threadlist?.receiverMessageDetails
                                        ?.user_detail?.profile_picture
                                    : nullImg
                                }
                                alt="message-user"
                              />
                            </div>
                            <div>
                              <h6 className="d-flex align-items-center small-text text-secondary mb-0 f-700">
                                {threadlist.receiverMessageDetails?.last_name
                                  ? `${threadlist?.receiverMessageDetails?.name} ${threadlist.receiverMessageDetails?.last_name}`
                                  : threadlist?.receiverMessageDetails?.name}
                                <span className="f-400 extra-small-text text-grey-light ms-2">
                                  {moment(new Date(list?.createdAt)).fromNow()}
                                </span>
                              </h6>
                              <span className="d-flex f-400 extra-small-text text-grey-light">
                                to me
                                <svg
                                  className="ms-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.6209 5.22083L7.81753 9.02416C7.36836 9.47333 6.63336 9.47333 6.18419 9.02416L2.38086 5.22083"
                                    stroke="#98A2B3"
                                    strokeWidth="1.1"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </>
                        )}
                      </div>

                      <p className="text-grey small-text mt-3">
                        {/* {list.message_text} */}
                        {list?.message_type === 0 ? (
                          list?.message_text
                        ) : (
                          <>
                            <audio
                              className="audio-wrap"
                              controls
                              controlsList="nodownload"
                            >
                              <source
                                src={list?.message_text}
                                type="audio/ogg"
                              />
                              <source
                                src={list?.message_text}
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio tag.
                            </audio>
                          </>
                        )}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="message_wrapperbox">
        {props.showdreply === false && (
          <>
            <div className="d-flex justify-content-end">
              <div className=" me-2">
                <span
                  className={`${
                    outPreview ? "d-flex" : "d-none"
                  } justify-space-between align-items-center gap-2`}
                >
                  <FaRegTrashAlt
                    size={20}
                    onClick={handleOutDiscard}
                    style={{ cursor: "pointer" }}
                  />
                  <audio
                    controls
                    className="audio-wrap"
                    controlsList="nodownload"
                    src={outBlobObj && URL.createObjectURL(outBlobObj)}
                  ></audio>
                  <GrSend
                    size={20}
                    onClick={() => sendOutVoiceMessage()}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <span className={isRecording ? "" : "d-none"}>
                  <AudioRecorder
                    onRecordingComplete={addOutAudioElement}
                    recorderControls={recorderControls}
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                  />
                </span>
                <button
                  disabled={isLoading}
                  type="button"
                  onClick={() => {
                    handleVoiceRecording();
                  }}
                  style={{ cursor: "pointer" }}
                  className={isRecording || outPreview ? "d-none" : ""}
                >
                  <IoMicCircleOutline size={30} />
                </button>
              </div>
              <Link
                className="replyBtn btn-main "
                onClick={() => {
                  props.Reply();
                }}
              >
                <svg
                  className="me-2"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.70687 0.292793C6.89434 0.480326 6.99966 0.734639 6.99966 0.999802C6.99966 1.26496 6.89434 1.51928 6.70687 1.70681L3.41383 4.99985H8.99986C11.1217 4.99985 13.1565 5.84271 14.6568 7.34298C16.1571 8.84329 17 10.8782 17 12.9999V17C17 17.2652 16.8947 17.5196 16.7071 17.7071C16.5196 17.8946 16.2651 18 15.9999 18C15.7347 18 15.4804 17.8946 15.2928 17.7071C15.1053 17.5196 14.9999 17.2652 14.9999 17V12.9999C14.9999 11.4086 14.3678 9.88251 13.2426 8.75729C12.1174 7.63198 10.5912 6.99987 8.99986 6.99987H3.41383L6.70687 10.2929C6.88903 10.4815 6.98982 10.7341 6.98754 10.9963C6.98527 11.2585 6.8801 11.5093 6.69469 11.6947C6.50927 11.8801 6.25846 11.9853 5.99626 11.9876C5.73406 11.9899 5.48145 11.8891 5.29285 11.7069L0.292793 6.70687C0.105321 6.51934 0 6.26502 0 5.99986C0 5.7347 0.105321 5.48038 0.292793 5.29285L5.29285 0.292793C5.48038 0.105321 5.7347 0 5.99986 0C6.26502 0 6.51934 0.105321 6.70687 0.292793Z"
                    fill="#1F74CA"
                  />
                </svg>
                Reply
              </Link>
            </div>
          </>
        )}
        {props.showdreply === true && (
          <div className="message_input_wrapper">
            <form onSubmit={handleSubmit(sendThread)}>
              {/* <input
                rows="2"
                type="text"
                id="lname"
                name="message_text"
                value={data}
                placeholder="Type here........."
                onChange={(e) => handleChange(e.target.value)}
              /> */}
              <div className="reply-wrap d-flex direction-column gap-1 justify-space-between align-items-center">
                <span
                  className={`${
                    preview ? "d-flex" : "d-none"
                  } justify-space-between align-items-center gap-2`}
                >
                  <FaRegTrashAlt
                    size={20}
                    onClick={handleDiscard}
                    style={{ cursor: "pointer" }}
                  />
                  <audio
                    controls
                    className="audio-wrap"
                    controlsList="nodownload"
                    src={blobObj && URL.createObjectURL(blobObj)}
                  ></audio>
                  <GrSend
                    size={20}
                    onClick={() => sendVoiceMessage()}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                <span className={isRecording ? "" : "d-none"}>
                  <AudioRecorder
                    onRecordingComplete={addAudioElement}
                    recorderControls={recorderControls}
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                  />
                </span>
                <button
                  disabled={isLoading}
                  type="button"
                  // {...bind()}
                  onClick={() => {
                    handleVoiceRecording();
                  }}
                  style={{ cursor: "pointer" }}
                  className={isRecording || preview ? "d-none" : ""}
                >
                  <IoMicCircleOutline size={30} />
                </button>

                <textarea
                  rows="1"
                  type="text"
                  id="lname"
                  name="message_text"
                  // value={data}
                  placeholder="Type here........."
                  // onChange={(e) => handleChange(e.target.value)}
                  {...register(
                    "message_text",
                    (onchange = (e) => handleChange(e.target.value))
                  )}
                ></textarea>
              </div>
              <div className="invalid-feedback-error">
                {errors.message_text?.message}
              </div>

              <span className="send_message_btn">
                <button type="submit" disabled={btnDisabled}>
                  {btnDisabled ? (
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.16641 5.2668L13.2414 2.90846C16.4164 1.85013 18.1414 3.58346 17.0914 6.75846L14.7331 13.8335C13.1497 18.5918 10.5497 18.5918 8.96641 13.8335L8.26641 11.7335L6.16641 11.0335C1.40807 9.45013 1.40807 6.85846 6.16641 5.2668Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.42578 11.375L11.4091 8.3833"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </span>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default DetailedMsg;
const Schema = Yup.object().shape({
  message_text: Yup.string()
    // .required("This field is required")
    // .min(2, "Too Short")
    .trim(" ")
    .max(600, "Maximum character length is 600")
    .test(
      "no-email-phone",
      "Email addresses and phone numbers are not allowed.",
      (value) => {
        const emailPattern = /\S+@\S+\.\S+/;
        const phonePattern = /\d{6,}/;
        const specialRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/'"\-=|]+/;
        return !(
          emailPattern.test(value) ||
          phonePattern.test(value) ||
          specialRegex.test(value)
        );
      }
    ),
});
