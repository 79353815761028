import React from "react";
import { Link } from "react-router-dom";
import deviceImg from "../../../../../assets/images/device_screen.png";
import deviceHolder from "../../../../../assets/images/device_placeholder.png";

export default function LandingBooking({
  bookingTitleData,
  platformOneData,
  platformTwoData,
  platformThreeData,
}) {
  return (
    <section className="bookings">
      <div className="container spacer-y">
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="mb-50">
              {bookingTitleData?.title}
              <span className="primary__color">
                {" "}
                {bookingTitleData?.titleBlue}
              </span>
            </h2>
          </div>
        </div>
        <div className="row g-4 align-items-center mb-50">
          <div className="col-lg-7">
            <div className="device_wrap">
              <img
                src={
                  platformOneData
                    ? platformOneData?.banner_image?.upload_image_url
                    : deviceHolder
                }
                className="placeholder_img"
                alt=""
              />
              <img src={deviceImg} alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <h3>{platformOneData?.title}</h3>
            <Link
              to="/signup"
              state={{ fromLangingPage: true }}
              className="button-secondary button-blue"
            >
              Get Started
            </Link>
          </div>
        </div>
        <div className="row g-4 align-items-center mb-50">
          <div className="col-lg-5 order-2 order-lg-1">
            <h3>{platformTwoData?.title}</h3>
            <Link
              to="/signup"
              state={{ fromLangingPage: true }}
              className="button-secondary button-blue"
            >
              Get Started
            </Link>
          </div>
          <div className="col-lg-7 order-1 order-lg-2">
            <div className="device_wrap">
              <img
                src={
                  platformTwoData
                    ? platformTwoData?.banner_image?.upload_image_url
                    : deviceHolder
                }
                className="placeholder_img"
                alt=""
              />
              <img src={deviceImg} alt="" />
            </div>
          </div>
        </div>
        <div className="row g-4 align-items-center">
          <div className="col-lg-7">
            <div className="device_wrap">
              <img
                src={
                  platformThreeData
                    ? platformThreeData?.banner_image?.upload_image_url
                    : deviceHolder
                }
                className="placeholder_img"
                alt=""
              />
              <img src={deviceImg} alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <h3>{platformThreeData?.title}</h3>
            <Link
              to="/signup"
              state={{ fromLangingPage: true }}
              className="button-secondary button-blue"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
