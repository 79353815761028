import React from "react";
import mail from "../../../../../assets/images/mail.svg";
import parse from "html-react-parser";
// import map_pin from "../../../../../assets/images/map-pin.svg";
// import phone from "../../../../../assets/images//phone.svg";

const Details = ({ dataList }) => {
  return (
    <section className="contact-details primary__bg">
      <div className="container spacer-y">
        <div className="row gy-4 gy-md-0 justify-content-between">
          {/* <div className="col-lg-3 col-md-4"> */}
          <div className="col-lg-12 col-md-12">
            <div className="contact-wrap text-center">
              <div className="contact-icon">
                <img src={mail} alt="" />
              </div>
              <h5 className="mb-3 text-white">Email</h5>
              {/* <p className="text-white">Our friendly team is here to help.</p> */}
              <p className="text-white">
                {parse(`${dataList && dataList?.value?.subtitle}`)}
              </p>

              {/* <a className="text-white" href="mailto:hi@untitledui.com">
                contact@siestaglobal.com.{" "}
              </a> */}
              <a className="text-white" href="mailto:hi@untitledui.com">
                {parse(`${dataList && dataList?.value?.email}`)}
              </a>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4">
            <div className="contact-wrap text-center">
              <div className="contact-icon">
                <img src={map_pin} alt="" />
              </div>
              <h5 className="mb-3 text-white">Office</h5>
              <p className="text-white">Come say hello at our office HQ.</p>
              <p className="mb-0 text-white">
                100 Smith Street Collingwood VIC 3066 AU
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="contact-wrap text-center">
              <div className="contact-icon">
                <img src={phone} alt="" />
              </div>
              <h5 className="mb-3 text-white">Phone</h5>
              <p className="text-white">Mon-Fri from 8am to 5pm.</p>
              <a className="text-white" href="tel:+15550000000">
                +1 (555) 000-0000
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Details;
