import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-pikaday-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { emailRegExp } from "../../../../../../../../config/common/common";
import { postApi } from "../../../../../../../../config/apiFunctions";
import {
  create_Itinerary,
  create_Itinerary_by_ai,
} from "../../../../../../../../config/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { TRAVELER_NOTIFICATION } from "../../../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import moment from "moment";

const CreateItinerary = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isSubmit, setSubmit] = useState(false);
  const [isSubmitPrompt, setSubmitprompt] = useState(false);
  const [btndisabled, setBtnDisable] = useState(false);
  const [btndisabledPrompt, setBtnDisablePrompt] = useState(false);
  const [text, setText] = useState(null);
  const [showPrompt, setSHowPrompt] = useState(false);
  const [aiTextPrompt, setAitextprompt] = useState();
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    setValue("itinerary_text", text);
  }, [text]);

  useEffect(() => {
    reset();
  }, [props.show]);

  const onSubmit = (e) => {
    setSubmit(true);
    setBtnDisable(true);
    const obj = {
      booking_id: props.user.id,
      currency: "USD",
      itinerary_text: e.itinerary_text,
      advance_price: e.advance_price,
      final_price: e.final_price,
      title: "Lorium Ipsum",
    };
    postApi(create_Itinerary, obj, access_token)
      .then((res) => {
        dispatch(TRAVELER_NOTIFICATION(res.data.data));
        setSubmit(false);
        if (res.data.statusCode === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          navigate("/dashboard/booking-history");
          props.onHide(false);
        }
      })
      .catch((err) => {
        setSubmit(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
    // reset();
    setBtnDisable(false);
  };
  const handleChange = (e) => {
    if (e.target.value) {
      const numberInput = document.getElementById("numberInput");
      numberInput.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    }
  };

  // const getDataFromApi = async (e) => {
  //   e.preventDefault();
  //   setSubmitprompt(true);
  //   setBtnDisablePrompt(true);
  //   const obj = {
  //     givenText: aiTextPrompt,
  //   };
  //   await postApi(create_Itinerary_by_ai, obj, access_token)
  //     .then((res) => {
  //       setText(res.data.data.aiGeneratedText);
  //       setSubmitprompt(false);
  //       setSHowPrompt(false);
  //       setAitextprompt("");
  //       setBtnDisablePrompt(false);
  //     })
  //     .catch((err) => {
  //       setSubmitprompt(false);
  //       setBtnDisablePrompt(false);
  //       toast({
  //         title: err.response.data.message,
  //         status: "error",
  //         duration: 30000,
  //         isClosable: true,
  //         position: "top-right",
  //         onCloseComplete: () => setBtnDisable(false),
  //       });
  //     });
  // };

  const finalText = props?.user?.itinerary?.descriptions
    ?.replace(/\n\n/g, "<br/><br/>")
    .replace(/\n/g, "<br/>");
  // console.log(finalText, "finalTextfinalText");
  return (
    <Modal
      {...props}
      size="mw-600"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Review Itinerary</h3>
                <p className="text-grey mb-2">
                  Crafting Memories: Your Personalized Journey Starts Here
                </p>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="modal-body p-0 mt-3">
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    {...register("name")}
                    defaultValue={
                      props.user?.last_name
                        ? `${props.user?.first_name} ${props.user?.last_name}`
                        : props.user?.first_name
                    }
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                {/* <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="email"
                    name="email"
                    placeholder="Enter email id"
                    {...register("email")}
                    defaultValue={props.user?.email}
                  />
                  <div className="invalid-feedback">
                    {errors.email?.message}
                  </div>
                </div> */}
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="advance_price"
                  >
                    {/* Advance Amount */}
                    Advance Payment
                  </label>
                  <input
                    id="numberInput"
                    onwheel
                    type="number"
                    className={`form-control ${
                      errors.advance_price ? "is-invalid" : ""
                    }`}
                    name="advance_price"
                    placeholder="Enter your price"
                    {...register("advance_price", {
                      onChange: handleChange,
                    })}
                  />
                  <p className="extra-small-text text-grey-light mb-0">
                    Note: Prepayment will be required
                  </p>
                  <div className="invalid-feedback">
                    {errors.advance_price?.message}
                  </div>
                </div>
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="final_price"
                  >
                    Final Payment
                  </label>
                  <input
                    id="numberInput"
                    onwheel
                    type="number"
                    className={`form-control ${
                      errors.final_price ? "is-invalid" : ""
                    }`}
                    name="final_price"
                    placeholder="Enter your price"
                    {...register("final_price", {
                      onChange: handleChange,
                    })}
                  />
                  <p className="extra-small-text text-grey-light mb-0">
                    Note: Final amount will need to be processed by{" "}
                    {moment(props?.user?.booking_end, "MM-DD-YYYY").format(
                      "MM-DD-YYYY"
                    )}{" "}
                    or 30 days after advance payment(whichever comes first).
                  </p>
                  <div className="invalid-feedback">
                    {errors.final_price?.message}
                  </div>
                </div>
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <label
                      className="small-text text-grey-scale mb-1 f-600"
                      htmlFor="itinerary_text"
                    >
                      Review Itinerary
                    </label>
                    {/* <button
                      type="button"
                      className="btn-main btn-primary mb-2"
                      onClick={() => setSHowPrompt(true)}
                    >
                      Add a Prompt
                    </button> */}
                  </div>

                  <div className=" ck w-100 h-50">
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        placeholder: " Review Itinerary...",
                        height: 400,
                        // plugins: [ Paragraph, Bold, Italic, Essentials ],
                        toolbar: [
                          "font",
                          "heading",
                          "bold",
                          "italic",
                          "undo",
                          "redo",
                          "blockQuote",
                          "bulletedlist",
                          "numberedlist",
                          "indent",
                          "outdent",
                          "underline",
                        ],
                      }}
                      // data={text && text}
                      // data={
                      //   props?.user?.itinerary?.descriptions
                      //     ? props?.user?.itinerary?.descriptions
                      //     : text
                      // }

                      data={finalText || text}
                      onChange={(event, editor) => {
                        setText(editor.getData());
                        setValue("value", text);
                        // trigger("itinerary_text")
                        trigger("itinerary_text", "jbjhbjb");
                      }}
                    />
                    <input
                      name="itinerary_text"
                      type="hidden"
                      {...register("itinerary_text")}
                      value={finalText || text}
                      defaultValue={finalText || text}
                    />
                    <div className="text-danger" style={{ fontSize: ".875em" }}>
                      {errors.itinerary_text?.message}
                    </div>
                  </div>
                </div>
                <div className="date_fixed input-box date_selector mb-4"></div>
              </div>
              <div className="modal-footer border-0 p-0">
                <button
                  disabled={btndisabled}
                  className="btn-main btn-primary w-100 mw-100"
                  type="submit"
                >
                  {isSubmit ? (
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "  Send"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* 
      <Modal
        show={showPrompt}
        size="mw-600"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div>
          <form onSubmit={getDataFromApi}>
            <div className=" p-30">
              <div className="position-relative">
                <h3 className="text-grey-500 f-700 mb-2">
                  Enter text to generate Itinerary
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSHowPrompt(false)}
                ></button>
              </div>
              <div className=" p-0 mt-3">
                <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                  <label
                    className="small-text text-grey-scale mb-1 f-600"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className={`form-control`}
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    onChange={(e) => setAitextprompt(e.target.value)}
                  />
                </div>

                <div className="date_fixed input-box date_selector mb-4"></div>
              </div>
              <div className="modal-footer border-0 p-0">
                <button
                  disabled={btndisabled}
                  className="btn-main btn-primary mw-100"
                  type="submit"
                >
                  {isSubmitPrompt ? (
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    "  Send"
                  )}
                </button>
               
              </div>
            </div>
          </form>
        </div>
      </Modal> */}
    </Modal>
  );
};

export default CreateItinerary;
const Schema = Yup.object().shape({
  itinerary_text: Yup.string().required("This field is required"),
  advance_price: Yup.string()
    .required("This field is required")
    .max(5, "Please enter valid amount")
    .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  final_price: Yup.string()
    .required("This field is required")
    .max(5, "Please enter valid amount")
    .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  // email: Yup.string()
  //   .required("This field is required")
  //   .matches(emailRegExp, "Please enter valid email address"),
  name: Yup.string().required("This field is required"),
});
