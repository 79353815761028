import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { PRIVACY_PAGE } from "../../../redux/slices/content/ContentSlice";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

const Privacy = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.privacyPage);
  const [loading, setLoading] = useState(false);

  const privacyPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=PRIVACY_POLICY&section=POLICY`)
      .then((res) => {
        dispatch(PRIVACY_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    privacyPageData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="container spacer-y px-2 px-md-0">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="toc-blk bg-white">
                <h1 className="mb-3 d-block">{data && data?.value?.title}</h1>
                {parse(`${data && data?.value?.description}`)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Privacy;
