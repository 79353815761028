import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi, putApi } from "../../../../../../config/apiFunctions";
import {
  traveler_notifications,
  guide_notifications,
  traveller,
  guide,
} from "../../../../../../config/endpoints";
import {
  TRAVELER_NOTIFICATION,
  UNREAD_NOTIFICATIONS_GUIDE,
} from "../../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { Oval } from "react-loader-spinner";
import Pagination from "../../../../../../config/pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import {
  ALLNOTIFICATIONS,
  BOOKING,
  UNREAD_NOTIFICATIONS_TRAVELLER,
} from "../../../../../../redux/slices/TravellerReducer/travellerSlice";
import nodata from "../../../../../../assets/images/no-result.png";

const Notification = () => {
  const [tab, setTab] = useState("0");
  const [bookingnotifications, setBookingnotifications] = useState("yes");
  const [paymentnotifications, setPaymentnotifications] = useState("yes");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isMarkedNotification, setIsMarkedNotification] = useState(false);
  const [notifications, setNotifications] = useState(null);

  // const toast = useToast();

  const handleChange = (count) => {
    setCurrentPage(1);
    setMaxPageLimit(4);
    setMinPageLimit(0);
    let tab = count;
    if (tab === "0") {
      setTab("0");
      setBookingnotifications("yes");
      setPaymentnotifications("yes");
    } else if (tab === "1") {
      setTab("1");
      setBookingnotifications("yes");
      setPaymentnotifications("no");
    } else if (tab === "2") {
      setTab("2");
      setBookingnotifications("no");
      setPaymentnotifications("yes");
    }
  };

  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.SignupUser);

  const notification = useSelector(
    (state) => state.touristGuide.traveler_notification
  );

  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const nPages = notification?.count ? Math.ceil(notification?.count / 10) : [];

  let recieved_booking =
    useSelector((state) => state.touristGuide.recieved_bookings) || 0;
  let booking_history =
    useSelector((state) => state.touristGuide.booking_history) || 0;
  let traveler_booking_notification =
    useSelector((state) => state?.travellerSlice.bookings.booking) || 0;

  const getNotifications = () => {
    setLoading(true);
    getApi(
      `${
        User.role_name !== "GUIDE"
          ? traveler_notifications
          : guide_notifications
      }?page_no=${currentPage}&number_of_rows=&booking_notifications=${bookingnotifications}&payment_notifications=${paymentnotifications}`,
      {},
      User.access_token
    ).then((res) => {
      setNotifications(res.data.data);
      dispatch(TRAVELER_NOTIFICATION(res.data.data));
      dispatch(
        UNREAD_NOTIFICATIONS_TRAVELLER(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
      dispatch(
        UNREAD_NOTIFICATIONS_GUIDE(
          res.data.data.unreadPaymentNotificationsCount +
            res.data.data.unreadBookingNotificationsCount
        )
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    getNotifications();
  }, [
    tab,
    recieved_booking,
    booking_history,
    traveler_booking_notification,
    currentPage,
    isMarkedNotification,
  ]);

  useEffect(() => {
    dispatch(ALLNOTIFICATIONS(0));
  }, []);

  const navigate = useNavigate();

  const handleMarkAllNotifications = (notificationId) => {
    let notificationArray = [];
    if (!notificationId) {
      notificationArray = notification.rows.map(
        (_notification) => _notification.id
      );
    } else if (notificationId) {
      notificationArray.push(notificationId.id);
    }
    putApi(
      `${
        User.role_name !== "GUIDE" ? traveller : guide
      }/marked-read-notification`,
      { notification_ids: notificationArray },
      User.access_token
    ).then((res) => {
      setIsMarkedNotification(!isMarkedNotification);
      setLoading(false);
    });
  };

  const handleNotificationNavigate = (list) => {
    handleMarkAllNotifications(list);
    if (
      list.description.includes("itinerary") ||
      list.description.includes("Itinerary")
    ) {
      if (User.role_name == "GUIDE") {
        navigate(`../booking-history/booking-detail/${list.module_type_id}`);
      } else {
        navigate(`../itinerary-details/${list.module_type_id}`);
      }
    } else if (
      list.description.includes("booking") ||
      list.description.includes("Booking")
    ) {
      if (list.module_type == 2) {
        navigate("../transactions");
      } else if (User.role_name !== "GUIDE") {
        navigate(`../my-bookings`);
      } else {
        if (list.description.includes("cancelled")) {
          navigate(`../booking-history`);
        } else {
          navigate(`../booking`);
        }
      }
    } else if (
      list.description.includes("paid") ||
      list.description.includes("payment")
    ) {
      navigate(`../transactions`);
    }
  };

  return (
    <div className="table_fixed booking_innerbox">
      <div className="container_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="booking_header p-20 border-bottom">
              <div className="row d-flex align-items-center justify-content-between">
                <div className="col-md-4 col-6">
                  <h3 className="text-secondary mb-0 f-700">Notifications</h3>
                </div>
                <div className="col-md-8 col-6 text-end">
                  <Link
                    className="text-primary text-capitalize text-decoration-underline ms-auto f-600 pe-4 mark_readtag"
                    onClick={() => handleMarkAllNotifications()}
                  >
                    Mark all as read
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className={
                isLoading === true ? "overflow-table loader" : "overflow-table"
              }
              style={
                notification?.count > 10
                  ? { height: "calc(100vh - 270px)" }
                  : { height: "calc(100vh - 220px)" }
              }
            >
              {isLoading === true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <Oval
                    height={80}
                    width={80}
                    color="#1f74ca"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#1f74ca8f"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}
              {isLoading === false && (
                <div className="notification_inner" data-aos="fade-up">
                  <ul
                    className="notification_tab p-sticky nav nav-pills p-20 py-0 border-bottom"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        value={"0"}
                        onClick={() => {
                          handleChange("0");
                        }}
                        className={
                          tab === "0"
                            ? "active notify_tab f-500"
                            : "notify_tab f-500"
                        }
                        id="all_notify_tab"
                        data-bs-toggle="pill"
                        data-bs-target="#all_notifications"
                        type="button"
                        role="tab"
                        aria-controls="all_notifications"
                      >
                        All{" "}
                        <span>
                          {notification?.unreadBookingNotificationsCount +
                            notification?.unreadPaymentNotificationsCount !==
                          0
                            ? `(${
                                notification?.unreadBookingNotificationsCount +
                                notification?.unreadPaymentNotificationsCount
                              })`
                            : ""}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        value={"1"}
                        onClick={() => {
                          handleChange("1");
                        }}
                        className={
                          tab === "1"
                            ? "active notify_tab f-500"
                            : "notify_tab f-500"
                        }
                        id="booking_tab"
                        data-bs-toggle="pill"
                        data-bs-target="#booking_notifications"
                        type="button"
                        role="tab"
                        aria-controls="booking_notifications"
                      >
                        Bookings{" "}
                        <span>
                          {notification?.unreadBookingNotificationsCount !== 0
                            ? `(${notification?.unreadBookingNotificationsCount})`
                            : ""}
                        </span>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        value={"2"}
                        onClick={() => {
                          handleChange("2");
                        }}
                        className={
                          tab === "2"
                            ? "active notify_tab f-500"
                            : "notify_tab f-500"
                        }
                        id="payment_tab"
                        data-bs-toggle="pill"
                        data-bs-target="#payment_notifications"
                        type="button"
                        role="tab"
                        aria-controls="payment_notifications"
                        aria-selected="false"
                      >
                        Payments{" "}
                        <span>
                          {notification?.unreadPaymentNotificationsCount !== 0
                            ? `(${notification?.unreadPaymentNotificationsCount})`
                            : ""}
                        </span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all_notifications"
                      role="tabpanel"
                      aria-labelledby="all_notify_tab"
                    >
                      {/* {isLoading === false && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <Oval
                          height={80}
                          width={80}
                          color="#1f74ca"
                          wrapperStyle={{}}
                          wrapperclassName=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#1f74ca8f"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      </div>
                    )} */}
                      {/* {isLoading === false && ( */}
                      {notification?.rows?.length !== 0 ? (
                        <div className="notify_list">
                          <div>
                            {notification?.rows?.map((list, i) => {
                              return (
                                <div
                                  key={i}
                                  className={
                                    list.status === 1
                                      ? "notify_tag m-view-notification d-flex align-items-center justify-content-between p-20 border-bottom bg-pink-light"
                                      : "notify_tag m-view-notification d-flex align-items-center justify-content-between p-20 border-bottom"
                                  }
                                  style={{
                                    backgroundColor:
                                      list.status === 3 ? "" : "antiquewhite",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-between w-100 me-2"
                                    onClick={() =>
                                      handleNotificationNavigate(list)
                                    }
                                  >
                                    <h6 className="text-secondary f-600 mb-0 lh-38">
                                      {list.description}
                                    </h6>
                                    <span className="small-text text-grey ms-2">
                                      {list.timeAgo}
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <h1 className="text-primary text-center">
                          {/* Something went wrong!! */}
                          <img src={nodata} className="m-auto" />
                          No Results Found
                        </h1>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading === false && (
        <>
          {notification?.count > 10 && (
            <div className="row">
              <div className="col-md-12">
                <div className="pagination-wrapperbox d-flex align-items-center justify-content-between">
                  <div className="data_count p-20 text-grey small-text f-400">
                    <span>
                      Showing
                      <span className="mx-1 f-500">{10 * currentPage - 9}</span>
                      to
                      <span className="mx-1 f-500">
                        {10 * currentPage - (10 - notification?.rows?.length)}
                      </span>
                      of
                      <span className="mx-1 f-500">{notification?.count}</span>
                      entries
                    </span>
                  </div>
                  <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageNumberLimit={pageNumberLimit}
                    maxPageLimit={maxPageLimit}
                    minPageLimit={minPageLimit}
                    setMinPageLimit={setMinPageLimit}
                    setMaxPageLimit={setMaxPageLimit}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Notification;
