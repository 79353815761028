import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DetailedMsg from "./DetailedMsg";
import { Oval } from "react-loader-spinner";
import Pagination from "../../../../../../../config/pagination/Pagination";
import moment from "moment";
import { deleteApi, putApi } from "../../../../../../../config/apiFunctions";
import {
  guide_deleteMSG,
  traveler_deleteMSG,
} from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import nodata from "../../../../../../../assets/images/no-result.png";
import Delete from "./Delete";
import nullImg from "../../../../../../../assets/images/profile-placeholder1.jpg";

const TableContent = (props) => {
  const toast = useToast();
  const pageNumberLimit = 4;
  const [showDelete, setDelete] = useState(false);
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [thredId, setTreadId] = useState(null);
  const [checked, setChecked] = useState([]);
  const [recordsPerPage] = useState(10);
  const nPages = props.list?.counts
    ? Math.ceil(props.list?.counts / recordsPerPage)
    : [];

  const navigate = useNavigate();

  const handleSelectAll = (e) => {
    const input = document.querySelectorAll("#myCheck");
    if (e.target.checked) {
      for (var i = 0; i < input.length; i++) {
        input[i].setAttribute("checked", "");
      }
      const filter = props.list?.rows.map((lists) => lists.id);
      setChecked(filter);
    } else {
      for (var i = 0; i < input.length; i++) {
        input[i].removeAttribute("checked", "");
      }
      setChecked([]);
    }
  };

  const handlChange = (e, id) => {
    if (id) {
      const input = document.querySelectorAll("#myCheck");
      for (var i = 0; i < input.length; i++) {
        input[i].removeAttribute("checked", "");
      }
    }
    var updatedList = [...checked];
    if (e.target.checked) {
      updatedList = [...checked, id];
    } else {
      updatedList.splice(checked.indexOf(id), 1);
    }
    setChecked(updatedList);
  };

  useEffect(() => {
    setChecked([]);
  }, [props.currentPage, props.active]);

  const RemoveList = () => {
    setDelete(true);
  };

  return (
    <div
      className={
        props.showdetail === true
          ? "tab-content w-100 height_fix"
          : "tab-content w-100"
      }
      id="v-pills-tabContent"
      // data-aos="fade-up"
    >
      <div
        className={
          props.active === "2" || props.active === "0"
            ? "tab-pane fade msg-tab-pane show active"
            : "tab-pane msg-tab-pane fade show"
        }
        id={props.isLoading === true ? "loader" : "v-pills-messages"}
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
      >
        {props.isLoading === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {props.showdetail === false && (
          <>
            {props.isLoading === false && (
              <div className="messages_container">
                {props?.list?.rows.length !== 0 ? (
                  <>
                    <div className="messages_option_inner p-20 p-sticky border-bottom d-flex align-items-center justify-content-between">
                      <div className="message_optionbox d-flex align-items-center justify-content-center me-3 position-relative">
                        <div className="message_checkbox d-flex align-items-center">
                          <label
                            className="d-flex align-items-center"
                            htmlFor="delete_check_2"
                          >
                            <input
                              id="delete_check_2"
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={
                                checked.length === props.list?.rows?.length
                              }
                            />
                          </label>
                          <span className="ms-2 small-text text-secondary f-600">
                            Select All
                          </span>
                        </div>
                      </div>
                      <div className="delete_box_inner">
                        <button
                          className={
                            checked?.length !== 0
                              ? "delete_message d-flex align-items-center justify-content-center"
                              : "delete_message d-flex align-items-center justify-content-center disabled"
                          }
                          // href=""
                          onClick={RemoveList}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.8032 3.9225C14.5957 3.8025 13.3882 3.7125 12.1732 3.645V3.6375L12.0082 2.6625C11.8957 1.9725 11.7307 0.9375 9.97573 0.9375H8.01073C6.26323 0.9375 6.09823 1.9275 5.97823 2.655L5.82073 3.615C5.12323 3.66 4.42573 3.705 3.72823 3.7725L2.19823 3.9225C1.88323 3.9525 1.65823 4.23 1.68823 4.5375C1.71823 4.845 1.98823 5.07 2.30323 5.04L3.83323 4.89C7.76323 4.5 11.7232 4.65 15.6982 5.0475C15.7207 5.0475 15.7357 5.0475 15.7582 5.0475C16.0432 5.0475 16.2907 4.83 16.3207 4.5375C16.3432 4.23 16.1182 3.9525 15.8032 3.9225Z"
                              fill="#F04438"
                            ></path>
                            <path
                              d="M14.4218 6.105C14.2418 5.9175 13.9943 5.8125 13.7393 5.8125H4.25932C4.00432 5.8125 3.74932 5.9175 3.57682 6.105C3.40432 6.2925 3.30682 6.5475 3.32182 6.81L3.78682 14.505C3.86932 15.645 3.97432 17.07 6.59182 17.07H11.4068C14.0243 17.07 14.1293 15.6525 14.2118 14.505L14.6768 6.8175C14.6918 6.5475 14.5943 6.2925 14.4218 6.105ZM10.2443 13.3125H7.74682C7.43932 13.3125 7.18432 13.0575 7.18432 12.75C7.18432 12.4425 7.43932 12.1875 7.74682 12.1875H10.2443C10.5518 12.1875 10.8068 12.4425 10.8068 12.75C10.8068 13.0575 10.5518 13.3125 10.2443 13.3125ZM10.8743 10.3125H7.12432C6.81682 10.3125 6.56182 10.0575 6.56182 9.75C6.56182 9.4425 6.81682 9.1875 7.12432 9.1875H10.8743C11.1818 9.1875 11.4368 9.4425 11.4368 9.75C11.4368 10.0575 11.1818 10.3125 10.8743 10.3125Z"
                              fill="#F04438"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="messages_list">
                      <>
                        {props.list?.rows?.map((lists, i) => {
                          return (
                            <ul key={i} className="list-unstyled">
                              <li className="list_checked  d-flex align-items-center py-2 p-20">
                                <div className="d-flex align-items-center me-5">
                                  <div className="message_checkbox d-flex align-items-center me-3">
                                    <label
                                      className="d-flex align-items-center"
                                      htmlFor="myCheck"
                                    >
                                      <input
                                        className="message_checked"
                                        // id="delete_check"
                                        id="myCheck"
                                        type="checkbox"
                                        onChange={(e) =>
                                          handlChange(e, lists.id)
                                        }
                                        checked={checked.includes(lists.id)}
                                        // defaultChecked={false}
                                        // checked
                                      />
                                    </label>
                                  </div>
                                  <Link
                                    to=""
                                    onClick={() => {
                                      setTreadId(lists.id);
                                      props.onShow();
                                    }}
                                  >
                                    <div className="d-flex align-items-center message_profileBox">
                                      <div className="message_profile d-flex align-items-center justify-content-between me-1">
                                        <img
                                          className="img-fluid"
                                          src={
                                            lists?.lastMessageUserDetails
                                              ?.user_detail?.profile_picture
                                              ? lists?.lastMessageUserDetails
                                                  ?.user_detail?.profile_picture
                                              : nullImg
                                          }
                                          alt="message-user"
                                        />
                                      </div>
                                      <h6 className="small-text text-secondary mb-0 f-700">
                                        {lists.lastMessageUserDetails?.last_name
                                          ? `${lists.lastMessageUserDetails?.name} ${lists.lastMessageUserDetails?.last_name}`
                                          : lists.lastMessageUserDetails?.name}
                                      </h6>
                                    </div>
                                  </Link>
                                </div>
                                <Link
                                  className="w-100"
                                  to=""
                                  onClick={() => {
                                    setTreadId(lists.id);
                                    props.onShow();
                                  }}
                                >
                                  <div className="w-100">
                                    <p className="extra-small-text text-grey mb-0">
                                      {lists.subject_text}
                                    </p>
                                  </div>
                                </Link>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="delete_box_inner d-none">
                                    <a
                                      className="delete_message d-flex align-items-center justify-content-center me-3"
                                      href=""
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.8032 3.9225C14.5957 3.8025 13.3882 3.7125 12.1732 3.645V3.6375L12.0082 2.6625C11.8957 1.9725 11.7307 0.9375 9.97573 0.9375H8.01073C6.26323 0.9375 6.09823 1.9275 5.97823 2.655L5.82073 3.615C5.12323 3.66 4.42573 3.705 3.72823 3.7725L2.19823 3.9225C1.88323 3.9525 1.65823 4.23 1.68823 4.5375C1.71823 4.845 1.98823 5.07 2.30323 5.04L3.83323 4.89C7.76323 4.5 11.7232 4.65 15.6982 5.0475C15.7207 5.0475 15.7357 5.0475 15.7582 5.0475C16.0432 5.0475 16.2907 4.83 16.3207 4.5375C16.3432 4.23 16.1182 3.9525 15.8032 3.9225Z"
                                          fill="#F04438"
                                        />
                                        <path
                                          d="M14.4218 6.105C14.2418 5.9175 13.9943 5.8125 13.7393 5.8125H4.25932C4.00432 5.8125 3.74932 5.9175 3.57682 6.105C3.40432 6.2925 3.30682 6.5475 3.32182 6.81L3.78682 14.505C3.86932 15.645 3.97432 17.07 6.59182 17.07H11.4068C14.0243 17.07 14.1293 15.6525 14.2118 14.505L14.6768 6.8175C14.6918 6.5475 14.5943 6.2925 14.4218 6.105ZM10.2443 13.3125H7.74682C7.43932 13.3125 7.18432 13.0575 7.18432 12.75C7.18432 12.4425 7.43932 12.1875 7.74682 12.1875H10.2443C10.5518 12.1875 10.8068 12.4425 10.8068 12.75C10.8068 13.0575 10.5518 13.3125 10.2443 13.3125ZM10.8743 10.3125H7.12432C6.81682 10.3125 6.56182 10.0575 6.56182 9.75C6.56182 9.4425 6.81682 9.1875 7.12432 9.1875H10.8743C11.1818 9.1875 11.4368 9.4425 11.4368 9.75C11.4368 10.0575 11.1818 10.3125 10.8743 10.3125Z"
                                          fill="#F04438"
                                        />
                                      </svg>
                                    </a>
                                  </div>
                                  <span className="message_timing f-700">
                                    {moment(
                                      new Date(lists?.updatedAt)
                                    ).fromNow()}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                      </>
                    </div>
                  </>
                ) : (
                  <h1 className="text-primary text-center mt-5">
                    {/* Something went wrong!! */}
                    <img src={nodata} className="m-auto" />
                    No Results Found
                  </h1>
                )}
              </div>
            )}
          </>
        )}
        {props.showdetail === true && (
          <DetailedMsg
            showdreply={props.showdreply}
            Reply={props.Reply}
            thredId={thredId}
            setActive={props.setActive}
          />
        )}
        <Delete
          show={showDelete}
          checked={checked}
          onHide={() => setDelete(false)}
          setDefList={props.setDefList}
        />
      </div>

      {props.isLoading === false && (
        <>
          {props.list?.counts > 10 && (
            <div
              className={
                props.showdetail === true
                  ? "pagination-wrapper visibility_hidden"
                  : "pagination-wrapper "
              }
            >
              <div className="data_count p-20 text-grey small-text f-400">
                <span>
                  Showing
                  <span className="mx-1 f-500">
                    {10 * props?.currentPage - 9}
                  </span>
                  to
                  <span className="mx-1 f-500">
                    {10 * props.currentPage - (10 - props.list?.rows?.length)}
                  </span>
                  of
                  <span className="mx-1 f-500">{props.list?.counts}</span>
                  entries
                </span>
              </div>
              <Pagination
                nPages={nPages}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
                pageNumberLimit={pageNumberLimit}
                maxPageLimit={maxPageLimit}
                minPageLimit={minPageLimit}
                setMinPageLimit={setMinPageLimit}
                setMaxPageLimit={setMaxPageLimit}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TableContent;
