import React from "react";
// import tools_1 from "../../../../../assets/images/tools-1.png";
// import tools_2 from "../../../../../assets/images/tools-2.png";
// import tools_3 from "../../../../../assets/images/tools-3.png";
// import tools_4 from "../../../../../assets/images/tools-4.png";
import parse from "html-react-parser";

const Experience = ({ dataList, stepOne, stepTwo, stepThree, stepFour }) => {
  return (
    <section className="tools">
      <div className="container spacer-y pt-0">
        <div className="row justify-content-center text-center">
          <div className="col-lg-8 mb-40">
            <h2 className="primary__color mb-3 p-0" data-aos="fade-up">
              {dataList && dataList[0]?.value?.title}
            </h2>
            <div className="m-0" data-aos="fade-up" data-aos-delay="200">
              {parse(`${dataList && dataList[0]?.value?.description}`)}
            </div>
          </div>
          <div className="col-lg-10">
            <div className="tools-wrapper">
              <div className="tools-blk text-center " data-aos="fade-up">
                <img
                  src={
                    stepOne && stepOne[0]?.value?.banner_image?.upload_image_url
                  }
                  alt=""
                />
                <h5>{stepOne && stepOne[0]?.value?.title}</h5>
                {parse(`${stepOne && stepOne[0]?.value?.description}`)}
              </div>
              <div className="tools-blk text-center" data-aos="fade-up">
                <img
                  src={
                    stepTwo && stepTwo[0]?.value?.banner_image?.upload_image_url
                  }
                  alt=""
                />
                <h5>{stepTwo && stepTwo[0]?.value?.title}</h5>

                {parse(`${stepTwo && stepTwo[0]?.value?.description}`)}
              </div>
              <div className="tools-blk text-center" data-aos="fade-up">
                <img
                  src={
                    stepThree &&
                    stepThree[0]?.value?.banner_image?.upload_image_url
                  }
                  alt=""
                />
                <h5>{stepThree && stepThree[0]?.value?.title}</h5>
                {parse(`${stepThree && stepThree[0]?.value?.description}`)}
              </div>
              <div className="tools-blk text-center" data-aos="fade-up">
                <img
                  src={
                    stepFour &&
                    stepFour[0]?.value?.banner_image?.upload_image_url
                  }
                  alt=""
                />
                <h5>{stepFour && stepFour[0]?.value?.title}</h5>
                {parse(`${stepFour && stepFour[0]?.value?.description}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
