import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import PaymentModal from "../../itinerary/itineraryDetails/modals/PaymentModal";
import defaultPicture from "../../../../../../../assets/images/profile-placeholder1.jpg";
import { Oval } from "react-loader-spinner";
import { postApi } from "../../../../../../../config/apiFunctions";
import {
  // traveller_Advance_Payment,
  traveller_final_payment,
} from "../../../../../../../config/endpoints";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

const PaymentDetails = (props) => {
  const [btndisabled, setBtnDisable] = useState(false);
  const [ShowWithdraw, setShowWithdraw] = useState(false);
  const toast = useToast();

  const Advance_payment = props?.viewData?.payments.find(
    (obj) => obj.payment_type === "INITIAL" && obj.payment_status === 1
  );
  const Remaining_payment = props?.viewData?.payments.find(
    (obj) => obj.payment_type === "FINAL" && obj.payment_status === 1
  );
  const FailedAdvance_payment = props?.viewData?.payments.find(
    (obj) => obj.payment_type === "INITIAL" && obj.payment_status === 0
  );

  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const completeBooking = () => {
    const api = traveller_final_payment;
    const obj = {
      booking_id: props.viewData.id,
    };
    postApi(api, obj, access_token)
      .then((res) => {
        toast({
          title: "Booking has been completed",
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.onHide();
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.onHide();
      });
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="withdraw_modal modal-content">
        {props.isLoading === true && (
          <div
            className="loader"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {props.isLoading === false && (
          <>
            <div className="modal_header position-relative">
              <div className="d-flex align-items-center">
                <div className="image_wrapper me-3">
                  <img
                    alt="Guide"
                    src={
                      props.viewData?.user.user_detail?.profile_picture
                        ? props.viewData?.user.user_detail?.profile_picture
                        : defaultPicture
                    }
                  />
                </div>
                <div>
                  <h4 className="text-black-light mb-2 lh-24 f-700">
                    {props.viewData?.user.last_name
                      ? `${props.viewData?.user.name} ${props.viewData?.user.last_name}`
                      : props.viewData?.user.name}
                  </h4>
                  <div className="d-flex align-items-center">
                    <p className="text-grey mb-0 f-500">
                      {/* {props.viewData?.user.email} */}
                    </p>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={props.onHide}
              ></button>
            </div>
            <div className="modal-body p-0 mt-3">
              <div>
                {/* <div className="py-2 px-2 border-bottom">
              <p className="text-secondary mb-0 f-500">
                <span className="text-black-light f-700 me-1">Location:</span>
                <span>{props.viewData?.destination}</span>
              </p>
            </div> */}
                <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                  {/* <div className="py-3 px-2">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Price:
                      </span>
                      <span>
                        ${props.viewData?.user.user_detail.price} per hour
                      </span>
                    </p>
                  </div>
                  <div className="py-2 px-2 border-bottom text-capitalize">
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">
                        Location:
                      </span>
                      <span>{props.viewData?.destination}</span>
                    </p>
                  </div> */}
                  {/* <div className="py-3 px-2">
                <p className="text-secondary mb-0 f-500">
                  <span className="text-black-light f-700 me-1">
                    Total Counted Hours:
                  </span>
                  <span>{props.viewData?.TotalCountedHours}</span>
                </p>
              </div> */}
                </div>
                {/* {props.viewData?.payments.map((list, i)=>{
             return(  */}
                {props?.transactionData === "INITIAL" && (
                  <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                    <div className="py-3 px-2">
                      {
                        <p className="text-secondary mb-0 f-500">
                          <span className="text-black-light f-700 me-1">
                            Advance Payment:
                          </span>
                          <span>
                            $
                            {Advance_payment?.amount
                              ? Advance_payment?.amount
                              : FailedAdvance_payment?.amount}
                          </span>
                        </p>
                      }
                    </div>
                    {Advance_payment?.length !== 0 && (
                      <div className="py-3 px-2">
                        {Advance_payment?.payment_status === 1 &&
                          props?.transactionData === "INITIAL" && (
                            <>
                              <span className="payment_status completed_badge d-flex align-items-center justify-content-end f-600 text-success">
                                <span className="btn-secondary-dark"></span>
                                Completed
                              </span>
                            </>
                          )}

                        {Advance_payment === undefined && (
                          <>
                            <span className="payment_status cancelled_badge d-flex align-items-center justify-content-end f-600 text-danger">
                              <span className="bg-danger"></span>
                              Failed
                            </span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {/* )
            })} */}
                {props?.transactionData === "FINAL" && (
                  // props.viewData?.is_completed === 1 &&
                  <div className="payment_row d-flex align-items-center justify-content-between border-bottom">
                    <div className="py-3 px-2">
                      {
                        <p className="text-secondary mb-0 f-500">
                          <span className="text-black-light f-700 me-1">
                            Final Pay:
                          </span>
                          <span>
                            {props.viewData?.final_paid === 0
                              ? ""
                              : `$${props.viewData?.final_paid}`}
                          </span>
                        </p>
                      }
                    </div>
                    <div className="py-3 px-2">
                      {[1, 3, 5, 7, 9].includes(props.viewData?.status) ? (
                        <button
                          disabled
                          className="dis small-text btn-main btn-primary f-400 px-4 py-1"
                          onClick={() => setShowWithdraw(true)}
                        >
                          Make Payment
                        </button>
                      ) : (
                        <>
                          {!Advance_payment ? (
                            <button
                              disabled
                              className="dis small-text btn-main btn-primary f-400 px-4 py-1"
                              onClick={() => setShowWithdraw(true)}
                            >
                              Make Payment
                            </button>
                          ) : (
                            <>
                              {Remaining_payment?.payment_status === 1 &&
                                props?.transactionData === "FINAL" && (
                                  <span className="payment_status completed_badge d-flex align-items-center justify-content-end f-600 text-success">
                                    <span className="btn-secondary-dark"></span>
                                    Completed
                                  </span>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div className="pt-2 px-2 pb-0">
                  {[1, 3, 5, 7, 9].includes(props.viewData?.status) && (
                    <p className="text-secondary mb-0 f-500">
                      <span className="text-black-light f-700 me-1">Note:</span>
                      <span className="text-danger fs-6">
                        This booking has been cancelled!
                      </span>
                    </p>
                  )}
                  {/* <p className="text-secondary mb-0 f-500">
                    <span className="text-black-light f-700 me-1">Note:</span>
                    {[1, 3, 5, 7, 9].includes(props.viewData?.status) ? (
                      <span className="text-danger fs-6">
                        This booking has been cancelled!
                      </span>
                    ) : (
                      <span>
                        Total payment will reflect after completion of the tour{" "}
                      </span>
                    )}
                  </p> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <PaymentModal
        show={ShowWithdraw}
        onHide={() => setShowWithdraw(false)}
        ids={props.viewData?.id}
        finalPayment={"yes"}
        onHide2={props.onHide}
      />
    </Modal>
  );
};

export default PaymentDetails;
