import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import cardImg from "../../../../../assets/images/explore-world-card-img.png";
import parse from "html-react-parser";

export default function JoinSiesta({
  joinData,
  exploreOneData,
  exploreTwoData,
  exploreThreeData,
  exploreFourData,
}) {
  return (
    <>
      <section className="join_siesta_travel_section new__bg">
        <div className="container spacer-y">
          <div className="row mb-50">
            <div className="col-md-7">
              <h2 className="m-0 text-capitalize ">
                {joinData?.title}{" "}
                <span className="primary__color">{joinData?.titleBlue}</span>
              </h2>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-md-5">
              <div className="explore_world_card">
                <div className="explore_content">
                  <h5 className="text-white">{joinData?.subtitle}</h5>
                  <Link to="/signup" state={{ fromLangingPage: true }} className="button-secondary">
                    Get Started
                  </Link>
                </div>
                <img
                  src={
                    joinData
                      ? joinData?.banner_image?.upload_image_url
                      : cardImg
                  }
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="explore_card_wrap">
                    <img
                      src={exploreOneData?.banner_image?.upload_image_url}
                      alt="img"
                    />
                    <h5>{exploreOneData?.title}</h5>
                    <div>{parse(`${exploreOneData?.description}`)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="explore_card_wrap">
                    <img
                      src={exploreTwoData?.banner_image?.upload_image_url}
                      alt="img"
                    />
                    <h5>{exploreTwoData?.title}</h5>
                    <div>{parse(`${exploreTwoData?.description}`)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="explore_card_wrap">
                    <img
                      src={exploreThreeData?.banner_image?.upload_image_url}
                      alt="img"
                    />
                    <h5>{exploreThreeData?.title}</h5>
                    <div>{parse(`${exploreThreeData?.description}`)}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="explore_card_wrap">
                    <img
                      src={exploreFourData?.banner_image?.upload_image_url}
                      alt="img"
                    />
                    <h5>{exploreFourData?.title}</h5>
                    <div>{parse(`${exploreFourData?.description}`)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="exp_platform">
        <div className="container spacer-y">
          <h2 className="mb-50">Explore <span className="primary__color">Our Platform</span></h2>
          <div className="row">
            <div className="col-lg-6">
              <img src={platform1} alt="" className="rounded-3" />
            </div>
            <div className="col-lg-6">
              <div className="exp_tabs">
                <h4>Profile Page</h4>
                <p className="mb-0">Showcase your expertise, available tours, and customer reviews</p>
              </div>
              <div className="exp_tabs">
                <h4>Profile Page</h4>
                <p className="mb-0">Showcase your expertise, available tours, and customer reviews</p>
              </div>
              <div className="exp_tabs">
                <h4>Profile Page</h4>
                <p className="mb-0">Showcase your expertise, available tours, and customer reviews</p>
              </div>
              <div className="exp_tabs">
                <h4>Profile Page</h4>
                <p className="mb-0">Showcase your expertise, available tours, and customer reviews</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="questions">
        <div className="container spacer-y">
          <h2 className="text-center">Frequently Asked Questions</h2>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <Accordion className="faq-accordion" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="mb-0 pb-0">The standard Lorem Ipsum passage, used since the 1500s</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="mb-0 pb-0">The standard Lorem Ipsum passage, used since the 1500s</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="mb-0 pb-0">The standard Lorem Ipsum passage, used since the 1500s</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
