import React, { useState } from "react";
import UploadMedia from "../uploadMedia/UploadMedia";
import PlacesAutocomplete from "react-places-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Commision from "../../../../dashboard/sections/home/components/pages/settingPage/Commision";

export default function ExperienceForm(props) {
  const navigate = useNavigate();
  const options = props?.acitvityList?.rows;

  const [selectedValue, setSelectedValue] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
    props?.setSelectedActivity(selectedList?.map((item) => item.name));
    props?.setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
    props?.setSelectedActivity(selectedList?.map((item) => item.name));
    props?.setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  return (
    <div className="spacer-y">
      <form className="" onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="row g-4">
          <div className="col-md-8 ">
            <div className="row">
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="Give your post a title..."
                  {...props.register("title")}
                  // {...register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls ${
                    props.errors.title ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {props?.errors?.title?.message}
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Activities
                </label>

                <Multiselect
                  options={options}
                  selectedValues={selectedValue}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  showCheckbox={true}
                />
                {props?.activityError && (
                  <div className="location_error">Activity is required</div>
                )}
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Location
                </label>

                {props.scriptLoaded && (
                  <PlacesAutocomplete
                    value={props.inputValue}
                    onChange={props.handleChangePlace}
                    onSelect={props.handleSelectPlace}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <div className="input-icon-grp">
                          <input
                            {...getInputProps({
                              placeholder: "Enter your location",
                              className:
                                "location-search-input form-control theme-controls",
                              type: "text",
                            })}
                          />
                          <i className="fa-regular fa-location-dot"></i>
                        </div>
                        {props?.showSuggestions && suggestions?.length > 0 && (
                          <div className="autocomplete-dropdown-container">
                            {/* {loading && <p>Loading...</p>} */}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                  onClick={() => {
                                    props.handleSelectPlace(
                                      suggestion.description
                                    );
                                  }}
                                >
                                  <p>{suggestion.description}</p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
                {props?.inputError && (
                  <div className="location_error">Location is required</div>
                )}
                {props?.addressError && (
                  <div className="location_error">
                    Please select location from dropdown
                  </div>
                )}
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Price{" "}
                  <span style={{ fontSize: "12px" }} className="m-0">
                    (Per Person)
                  </span>
                </label>
                <p style={{ fontSize: "12px" }} className="m-0">
                  (Siesta will deduct <Commision /> commission)
                </p>

                <div className="input-icon-grp">
                  <input
                    type="number"
                    name="price"
                    placeholder="Enter price"
                    {...props.register("price")}
                    // {...props.register("name", {
                    //   onChange: handleInputChange,
                    // })}
                    className={`form-control theme-controls   ${
                      props.errors.price ? "is-invalid" : ""
                    } `}
                  />
                  <i className="fa-regular fa-dollar-sign"></i>
                </div>
                <div className="location_error">
                  {props?.errors?.price?.message}
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Detailed Schedule
                </label>
                <input
                  type="text"
                  name="detailed_schedule"
                  placeholder="Enter schedule of the trip"
                  {...props.register("detailed_schedule")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.detailed_schedule ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.detailed_schedule?.message}
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Transport Type
                </label>

                <div className="input-icon-grp">
                  <input
                    type="text"
                    name="transport_type"
                    placeholder="Enter transport type"
                    {...props.register("transport_type")}
                    // {...props.register("name", {
                    //   onChange: handleInputChange,
                    // })}
                    className={`form-control theme-controls   ${
                      props.errors.transport_type ? "is-invalid" : ""
                    } `}
                  />
                  <i className="fa-light fa-car"></i>
                </div>
                <div className="location_error">
                  {props.errors.transport_type?.message}
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Accessibility
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    name="accessibility"
                    value={props.toggleAccessibility}
                    {...props.register("accessibility")}
                    className={`form-control theme-controls ${
                      props.errors.accessibility ? "is-invalid" : ""
                    }`}
                    readOnly // Make the input read-only if it's just for display
                  />
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={props.toggleAccessibility === "Yes"}
                      onChange={props.handleToggleChange}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                <div className="invalid-feedback">
                  {props.errors.accessibility?.message}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Maximum People
                </label>
                <input
                  type="number"
                  name="max_people"
                  placeholder="Enter max no of people"
                  {...props.register("max_people")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.max_people ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.max_people?.message}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Minimum People
                </label>
                <input
                  type="number"
                  name="min_people"
                  placeholder="Enter min no of people"
                  {...props.register("min_people")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.min_people ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.min_people?.message}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Starting Time
                </label>
                {/* <DatePicker
                  className="form-control theme-controls mw-100"
                  selected={props.startDate}
                  onChange={(date) => props.startDtaehandler(date)}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  selectsStart
                  startDate={props.startDate}
                  minDate={new Date()}
                  showDisabledMonthNavigation
                  required
                  onFocus={(e) => e.target.blur()}
                /> */}
                <DatePicker
                  className="form-control theme-controls mw-100"
                  selected={props.startDate}
                  onChange={(date) => props.startDtaehandler(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Duration
                </label>
                <input
                  type="text"
                  name="duration"
                  placeholder=""
                  {...props.register("duration")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.duration ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.duration?.message}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Meeting Point
                </label>
                <input
                  type="text"
                  name="meeting_point"
                  placeholder="Enter meeting point"
                  {...props.register("meeting_point")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.meeting_point ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.meeting_point?.message}
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Drop-off Point
                </label>
                <input
                  type="text"
                  name="drop_point"
                  placeholder="Enter drop-off point"
                  {...props.register("drop_point")}
                  // {...props.register("name", {
                  //   onChange: handleInputChange,
                  // })}
                  className={`form-control theme-controls   ${
                    props.errors.drop_point ? "is-invalid" : ""
                  } `}
                />
                <div className="invalid-feedback">
                  {props.errors.drop_point?.message}
                </div>
              </div>

              <div className="col-md-12">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Description
                </label>
                <textarea
                  type="text"
                  name="name"
                  rows={4}
                  placeholder="Enter description about your experience"
                  {...props.register("description")}
                  className={`form-control theme-controls ${
                    props.errors.description ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {props.errors.description?.message}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <UploadMedia
              multipleImages={props.multipleImages}
              mainImg={props.mainImg}
              selectMainImg={props.selectMainImg}
              setMainImg={props.setMainImg}
              errors={props.errors}
              handleChangeRemove={props.handleChangeRemove}
              changeMultipleFiles={props.changeMultipleFiles}
              mainImgType={props.mainImgType}
            />
            <div className="col-lg-12 text-center pt-4">
              {props.btndisable === true ? (
                <button
                  type="submit"
                  disabled={props.btndisable}
                  className="button-primary email-send-trigger w-100"
                >
                  <div className="d-flex justify-content-center">
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={props.btndisable}
                  className="button-primary email-send-trigger w-100"
                >
                  Save
                </button>
              )}
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="button-primary primary-outline w-100 mt-3"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
