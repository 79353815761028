import React from "react";
import booking_icon from "../../../../../assets/images/booking_icon.svg";
import transport_icon from "../../../../../assets/images/transport_icon.svg";
import destination_ic from "../../../../../assets/images/destination_ic.svg";
import "./WhyChoose.css";
import parse from "html-react-parser";

const WhyChoose = ({ qDataList, bDataList, eDataList }) => {
  return (
    <section className="choose_us">
      <div className="container">
        <div className="choose_us_heading">
          <h2 data-aos="fade-up">Why Choose Us</h2>
        </div>
        <div className="row choose_us_outer">
          <div className="col-md-4 choose_us_item">
            <div className="choose_us_item1" data-aos="fade-up">
              <div className="item_img">
                <img src={booking_icon} alt="icon" />
              </div>
              <div className="item_content">
                <h5>{qDataList && qDataList[0]?.value?.title}</h5>

                {parse(`${qDataList && qDataList[0]?.value?.description}`)}
              </div>
            </div>
          </div>
          <div className="col-md-4 choose_us_item">
            <div className="choose_us_item1" data-aos="fade-up">
              <div className="item_img">
                <img src={transport_icon} alt="icon" />
              </div>
              <div className="item_content">
                <h5>{bDataList && bDataList[0]?.value?.title}</h5>

                {parse(`${bDataList && bDataList[0]?.value?.description}`)}
              </div>
            </div>
          </div>
          <div className="col-md-4 choose_us_item">
            <div className="choose_us_item1" data-aos="fade-up">
              <div className="item_img">
                <img src={destination_ic} alt="icon" />
              </div>
              <div className="item_content">
                <h5>{eDataList && eDataList[0]?.value?.title}</h5>

                {parse(`${eDataList && eDataList[0]?.value?.description}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
