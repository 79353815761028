import React, { useEffect } from "react";
import "./dashboard.css";
import "./responsive.css";
import Layout from "../dashboard/sections/layout/Layout";
import Scrollbar from "smooth-scrollbar";
import AOS from "aos";
import { useLocation , useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';

const protectedSlugs = ['dashboard', 'xyz'];

const Dashboard = () => {
  const pathname= useLocation();
  
  const dispatch = useDispatch();

  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));

    if (pathname?.pathname.includes("/dashboard")) {
      document.body.classList.add("dash");
      document.body.classList.add("dashboard-module");
    
      document.body.classList.remove("website");
    }
    //  to dispatch notification from here
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 900,
      throttleDelay: 99,
      // startEvent: "load",
    });
  }, []);
 
  return <Layout />;
};

export default Dashboard;
