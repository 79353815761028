import React from "react";

function Terms() {
  return (
    <div className="container spacer px-3 px-md-0">
      <div className="row mb-3">
        <div className="col-md-12"></div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="toc-blk bg-white br-20 p-4">
            <h1 className="h4">Terms</h1>
            <p>
              Here at Siesta Travel! We respectfully ask that you read the
              following Terms & Conditions completely before using our website.
              You agree to abide by these conditions by accessing or using our
              website. Use of our services is strictly prohibited if you
              disagree with any part of these conditions.
            </p>

            <div className="toc-blk__content mb-5" id="future-updates">
              <h4 className="mb-4">Use of the Siesta Travel Platform</h4>
              <ul>
                <li className="mb-4">
                  <h5 className="h5 mb-1">Eligibility</h5>
                  <p>
                    Our platform is open to users who are at least 18 years old
                    or the applicable legal age of majority. You certify that
                    you are of legal age and possess the necessary capacity to
                    engage in this agreement by using Siesta Travel, and you
                    represent and declare that you are.
                  </p>
                </li>
                <li className="mb-4">
                  <h5 className="h5 mb-1">User Accounts</h5>
                  <p>
                    You might need to set up a user account to access some
                    services on our site. You are solely responsible for all
                    actions taken using your account, including safeguarding the
                    confidentiality of your login information.
                  </p>
                </li>
                <li className="mb-4">
                  <h5 className="h5 mb-1">Guide Listings</h5>
                  <p>
                    Siesta Travel gives tour guides a place to advertise their
                    skills and offerings to prospective customers. Although we
                    make every effort to provide correct and current
                    information, we do not support or warrant the caliber of any
                    guide's services. The research and decision-making are the
                    responsibility of the traveler.
                  </p>
                </li>
              </ul>
            </div>

            <div className="toc-blk__content mb-5" id="obligations">
              <h4 className="mb-4">Verification and screening of guides</h4>
              <ul>
                <li className="mb-4">
                  <h5 className="h5 mb-1">Verification of the tour guides</h5>
                  <p>
                    We take the verification and screening of the tour guides
                    very seriously. The relevant paperwork and data must be
                    submitted by guides for verification reasons. However, we
                    cannot guarantee the veracity of the information supplied by
                    guides, therefore we advise tourists to use caution while
                    making reservations.
                  </p>
                </li>
                <li>
                  <h5 className="h5 mb-1">Reviews and comments</h5>
                  <p>
                    Siesta Travel invites visitors to post reviews and comments
                    about their interactions with tour guides. The authenticity
                    or dependability of reviews submitted on our site is not
                    within our control, even though we monitor and regulate them
                    for objectionable content.
                  </p>
                </li>
              </ul>
            </div>

            <div className="toc-blk__content mb-5" id="your-rights-and-limits">
              <h4 className="mb-4">Traveler-Guide Engagement</h4>
              <ul>
                <li className="mb-4">
                  <h5 className="h5 mb-1">Reservations and Payments</h5>
                  <p>
                    Travelers and guides may discuss and settle on tour
                    specifics thanks to the assistance of the Siesta Travel. All
                    payments must be made via our platform and using authorized
                    payment methods. For each reservation, Siesta Travel will
                    levy a service fee.
                  </p>
                </li>
                <li>
                  <h5 className="h5 mb-1">Payment Structure</h5>
                  <p>
                    Our platform provides guides with a fair and transparent
                    compensation system. Based on the terms established with
                    tourists, guides are qualified to be paid for their
                    services. Any financial disagreements between tourists and
                    guides are not the responsibility of the Siesta Travel.
                  </p>
                </li>
              </ul>
            </div>

            <div
              className="toc-blk__content mb-5"
              id="third-party-content-Sites-and-apps"
            >
              <h4 className="mb-4">Intellectual property</h4>
              <ul>
                <li>
                  <h5 className="h5 mb-1">Ownership</h5>
                  <p>
                    Siesta Travel or its licensors are the exclusive owners of
                    all content on the Siesta Travel platform, including text,
                    pictures, logos, and trademarks, which are all protected by
                    relevant copyright laws. It is forbidden to use or reproduce
                    any content in any way without permission.
                  </p>
                </li>
              </ul>
            </div>

            <div
              className="toc-blk__content mb-5"
              id="intellectual-property-rights"
            >
              <h4 className="mb-3">Liability and Disclaimer</h4>
              <p>
                No information on the platform is guaranteed to be accurate,
                dependable, or comprehensive by Siesta Travel. The platform's
                users understand that they utilize it at their own risk.
              </p>
              <p>
                The use of our platform or the guides' services, or the
                inability to utilize them, may result in direct, indirect,
                incidental, or consequential losses, which are not the
                responsibility of Siesta Travel.
              </p>
            </div>

            <div className="toc-blk__content mb-5" id="limit-of-liability">
              <h4 className="mb-3">Changes to the Terms</h4>
              <p>
                These Terms and Conditions are subject to change at any moment,
                with or without notice from Siesta Travel. You have to
                frequently evaluate the terms. Your agreement with the updated
                terms is indicated by your continued usage of our platform
                following any adjustments.
              </p>
            </div>

            <div className="toc-blk__content mb-5" id="limit-of-liability">
              <h4 className="mb-3">Marketing and Promotional Text Message</h4>
              <p>
                I consent to Siesta Travel sending me marketing and promotional
                text messages. I understand that my consent is not a condition
                of any purchase and that I can unsubscribe at any time by
                replying STOP to any message received.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
