import React from "react";
import parse from "html-react-parser";

const Benefits = ({ dataList }) => {
  return (
    <section className="benifits">
      <div className="container spacer-y pt-0">
        <h2 className="primary__color p-0" data-aos="fade-up">
          {dataList && dataList[0]?.value?.title}
        </h2>
        <div className="divider"></div>
        <div className="row g-4">
          <div className="col-md-6" data-aos="fade-up">
            {parse(`${dataList && dataList[0]?.value?.description_left}`)}
          </div>
          <div className="col-md-6">
            <div
              className="benifit-list list list-unstyled"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {parse(`${dataList && dataList[0]?.value?.description_right}`)}

              {/* <li>
                Gives tour guides the freedom to set their own working hours,{" "}
                <b>tour guide rates</b>, and the excursions they wish to lead.
              </li>
              <li>
                Giving this opportunity, Siesta is making everyone able to earn
                money, no matter if you’ve prior experience or not, the only
                thing you need is familiarity with your locality.
              </li>
              <li>
                Having additional flexibility will help tour guides more
                successfully combine their personal and professional lives.
              </li>
              <li>
                Using our app, you may find tour options in less-traveled or
                rural areas where conventional tour operators would not be
                present.
              </li>
              <li>
                Assisting visitors with various interests, preferences, and
                language barriers, increasing their knowledge and exposure to
                many cultures.
              </li> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
