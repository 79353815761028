import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getApi, postApi } from "../../../../../../../../config/apiFunctions";
import {
  payment_method_list,
  traveller_Advance_Payment,
  traveller_final_payment,
} from "../../../../../../../../config/endpoints";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { ThreeDots } from "react-loader-spinner";
import { PAYMENT_METHOD_LIST } from "../../../../../../../../redux/slices/TravellerReducer/travellerSlice";

const PaymentModal = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const navigate = useNavigate();
  const [btndisabled, setBtnDisable] = useState(false);
  const [errorsMsg, setErrorsMsg] = useState([]);
  const [Finalamount, setFinalamount] = useState();

  const [selectedCard, setSelectedCard] = useState(null);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );

  const User = useSelector((state) => state.auth.SignupUser);
  const list_data = useSelector(
    (state) => state.travellerSlice.paymentMethodList
  );
  const dispatch = useDispatch();
  const {
    register,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(Schema),
  });

  const paymentMethodList = () => {
    getApi(payment_method_list, {}, access_token).then((res) => {
      dispatch(PAYMENT_METHOD_LIST(res?.data?.data.data));
    });
  };

  useEffect(() => {
    paymentMethodList();
  }, []);

  useEffect(() => {
    reset();
    setBtnDisable(false);
  }, []);

  const handleRadioChange = (value) => {
    setSelectedCard(value);
  };

  const onChangeStripe = (e, index) => {
    const temp = [...errorsMsg];
    if (e.error) {
      temp[index] = e.error.message;
      setErrorsMsg([...temp]);
    } else {
      temp[index] = "";
      setErrorsMsg([...temp]);
    }
  };

  const stripeSubmit = async (e) => {
    e.preventDefault();
    if (selectedCard === "new_card") {
      if (!Finalamount) {
        setFinalamount(null);
      }
      if (!stripe || !elements) {
        return false;
      }
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        if (error) {
          toast({
            title: error.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          props.onHide();
          // props.onHide2();
          return false;
        } else {
          if (paymentMethod) {
            setBtnDisable(true);
            let obj = {};
            let api;
            if (props.finalPayment === "yes") {
              api = traveller_final_payment;
              obj = {
                booking_id: props.ids,
                paymentToken: paymentMethod.id,
                remainingAmount: Finalamount,
              };
            } else {
              api = traveller_Advance_Payment;
              obj = {
                booking_id: props.ids,
                paymentToken: paymentMethod.id,
              };
            }
            postApi(api, obj, access_token)
              .then(async (res) => {
                const clientSecret = res.data.data["client_secret"];
                const clientSecretFinal = res.data.data["client_secret_final"];

                if (
                  // res.data.data.status === "requires_confirmation" ||
                  // res.data.data.status_final === "requires_confirmation"
                  true
                ) {
                  let result;
                  let finalResult;
                  if (clientSecret) {
                    try {
                      result = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: {
                          card: cardElement,
                          billing_details: {
                            email: User.email,
                          },
                        },
                      });
                    } catch (error) {
                      // console.log(error);
                    }
                  }
                  if (clientSecretFinal) {
                    try {
                      finalResult = await stripe.confirmCardPayment(
                        clientSecretFinal,
                        {
                          payment_method: {
                            card: cardElement,
                            billing_details: {
                              email: User.email,
                            },
                          },
                        }
                      );
                    } catch (error) {
                      // console.log(error);
                    }
                  }

                  if (result?.paymentIntent?.status === "succeeded") {
                    toast({
                      title: "Advance Payment completed successfully",
                      status: "success",
                      duration: 30000,
                      isClosable: true,
                      position: "top-right",
                      onCloseComplete: () => setBtnDisable(false),
                    });
                    props.onHide();
                    // props.onHide2();
                  } else if (result?.error) {
                    toast({
                      title: result?.error.message,
                      status: "error",
                      duration: 30000,
                      isClosable: true,
                      position: "top-right",
                      onCloseComplete: () => setBtnDisable(false),
                    });
                    props.onHide();
                    // props.onHide2();
                  }
                  if (
                    finalResult?.paymentIntent?.status === "requires_capture"
                  ) {
                    toast({
                      title: "Final Payment is on hold",
                      status: "success",
                      duration: 30000,
                      isClosable: true,
                      position: "top-right",
                      onCloseComplete: () => setBtnDisable(false),
                    });
                    props.onHide();
                    // props.onHide2();
                  } else if (finalResult?.error) {
                    toast({
                      title: finalResult?.error.message,
                      status: "error",
                      duration: 30000,
                      isClosable: true,
                      position: "top-right",
                      onCloseComplete: () => setBtnDisable(false),
                    });
                    props.onHide();
                    // props.onHide2();
                  }
                  navigate("/dashboard/transactions");
                } else {
                  reset();
                }
              })
              .catch((err) => {
                toast({
                  title: err.response.data.message,
                  status: "error",
                  duration: 30000,
                  isClosable: true,
                  position: "top-right",
                  onCloseComplete: () => setBtnDisable(false),
                });
                props.onHide();
                // props.onHide2();
              });
          }
        }
      }
    } else {
      setBtnDisable(true);
      let obj = {};
      let api;
      if (props.finalPayment === "yes") {
        api = traveller_final_payment;
        obj = {
          booking_id: props.ids,
          paymentToken: selectedCard,
          remainingAmount: Finalamount,
        };
      } else {
        api = traveller_Advance_Payment;
        obj = {
          booking_id: props.ids,
          paymentToken: selectedCard,
        };
      }
      postApi(api, obj, access_token)
        .then(async (res) => {
          const clientSecret = res.data.data["client_secret"];

          const clientSecretFinal = res.data.data["client_secret_final"];
          if (
            // res.data.data.status === "requires_confirmation" ||
            // res.data.data.status_final === "requires_confirmation"
            true
          ) {
            let result;
            let finalResult;
            if (clientSecret) {
              try {
                result = await stripe.confirmCardPayment(clientSecret, {
                  payment_method: selectedCard,
                });
              } catch (error) {
                // console.log(error);
              }
            }

            if (clientSecretFinal) {
              try {
                finalResult = await stripe.confirmCardPayment(
                  clientSecretFinal,
                  {
                    payment_method: selectedCard,
                  }
                );
              } catch (error) {
                // console.log(error);
              }
            }
            if (result?.paymentIntent?.status === "succeeded") {
              toast({
                title: "Advance Payment completed successfully",
                status: "success",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              props.onHide();
              // props.onHide2();
            } else if (result?.error) {
              toast({
                title: result?.error.message,
                status: "error",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              props.onHide();
              // props.onHide2();
            }
            if (finalResult?.paymentIntent?.status === "requires_capture") {
              toast({
                title: "Final Payment is on hold",
                status: "success",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              props.onHide();
              // props.onHide2();
            } else if (finalResult?.error) {
              toast({
                title: finalResult?.error.message,
                status: "error",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              props.onHide();
              // props.onHide2();
            }
          } else {
            reset();
          }
        })
        .catch((err) => {
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
          props.onHide();
          // props.onHide2();
        });
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="" id="payment_popup">
        <div className="modal-dialog-centered modal-dialog-scrollablse">
          <form className="w-100" onSubmit={stripeSubmit}>
            <div className="modal-content p-30">
              <div className="modal_header position-relative">
                <h3 className="text-grey-500 f-700 mb-2">Payment</h3>
                <p className="text-grey mb-2">Always Safe With Us</p>
                <button
                  type="button"
                  className="btn-close"
                  onClick={props.onHide}
                ></button>
              </div>
              <div className="row">
                {list_data?.map((data, i) => (
                  <label key={i} className="payment_radio_button">
                    <span>
                      <input
                        type="radio"
                        value="saved-card-1"
                        checked={selectedCard === data.id}
                        onChange={() => handleRadioChange(data.id)}
                      />
                      Pay with saved card
                    </span>
                    <div>**** **** **** {data?.card?.last4}</div>
                  </label>
                ))}
              </div>
              <div className="modal-body p-0 mt-3">
                <label className="payment_radio_button">
                  <span>
                    <input
                      type="radio"
                      value="saved-card-2"
                      checked={selectedCard === "new_card"}
                      onChange={() => handleRadioChange("new_card")}
                    />
                    Pay with new card
                  </span>
                </label>
                {selectedCard === "new_card" && (
                  <div className="form w-100">
                    <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4">
                      <label
                        className="small-text text-grey-scale mb-1 f-600"
                        htmlFor="Card"
                      >
                        Card
                      </label>
                      <div className="card-field position-relative w-100">
                        <div
                          {...register("Card")}
                          className={`position-relative  ${
                            errors.Card ? "is-invalid" : ""
                          }`}
                        >
                          <CardNumberElement
                            className={"form-control"}
                            onChange={(e) => onChangeStripe(e, 0)}
                            options={{
                              placeholder: "4511 1111 1111 1111",
                            }}
                          />
                        </div>
                        {errorsMsg[0] && (
                          <div className="invalid-feedback-error ">
                            {errorsMsg[0] && errorsMsg[0]}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="exp-date"
                          >
                            Expired Date
                          </label>
                          <CardExpiryElement
                            className={`small-text form-control ${
                              errors.expdate ? "is-invalid" : ""
                            }`}
                            accountSidaccountSid
                            onChange={(e) => onChangeStripe(e, 1)}
                            options={{
                              placeholder: "mm/yy",
                            }}
                          />
                          {errorsMsg[1] && (
                            <div className="invalid-feedback-error ">
                              {errorsMsg[1] && errorsMsg[1]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-box d-flex align-items-start justify-content-start flex-column mb-4 w-100">
                          <label
                            className="small-text text-grey-scale mb-1 f-600"
                            htmlFor="Enter cvv number"
                          >
                            CVV
                          </label>
                          <CardCvcElement
                            className={`small-text form-control ${
                              errors.cvvNumber ? "is-invalid" : ""
                            }`}
                            onChange={(e) => onChangeStripe(e, 2)}
                            options={{
                              placeholder: "cvc",
                            }}
                          />
                          {errorsMsg[2] && (
                            <div className="invalid-feedback-error ">
                              {errorsMsg[2] && errorsMsg[2]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="modal-footer d-flex align-items-center justify-content-between border-0 p-0 w-100">
                {btndisabled === true ? (
                  <button
                    className="btn-main btn-primary w-100 mw-50 p-15 "
                    disabled={btndisabled}
                    type="submit"
                  >
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    className="btn-main btn-primary w-100 mw-50 p-15 "
                    disabled={btndisabled}
                    type="submit"
                  >
                    Pay
                  </button>
                )}
                <Link
                  href=""
                  className="btn-main btn-red w-100 mw-50 p-15"
                  onClick={props.onHide}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
const Schema = Yup.object().shape({
  amount: Yup.string()
    .required("Price is required")
    .max(10, "Please enter valid amount")
    .test("Is positive?", "Please enter valid amount", (value) => value > 0),
  Card: Yup.string()
    .required("This field is required")
    .min(16, "Invalid card details")
    .max(16, "Invalid card details")
    .test(
      "Is positive?",
      "Please enter valid card details",
      (value) => value > 0
    )
    .matches(
      /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
      "not valid"
    ),
  expdate: Yup.string()
    .required("Expiration date is required")
    .typeError("Not a valid expiration date. Example: MM/YY")
    .max(5, "Not a valid expiration date. Example: MM/YY")
    .matches(
      /([0-9]{2})\/([0-9]{2})/,
      "Not a valid expiration date. Example: MM/YY"
    )
    .test(
      "test-credit-card-expiration-date",
      "Invalid Expiration Date has past",
      (expirationDate) => {
        if (!expirationDate) {
          return false;
        }

        const today = new Date();
        const monthToday = today.getMonth() + 1;
        const yearToday = today.getFullYear().toString().substr(-2);

        const [expMonth, expYear] = expirationDate.split("/");

        if (Number(expYear) < Number(yearToday)) {
          return false;
        } else if (
          Number(expMonth) < monthToday &&
          Number(expYear) <= Number(yearToday)
        ) {
          return false;
        }

        return true;
      }
    )
    .test(
      "test-credit-card-expiration-date",
      "Invalid Expiration Month",
      (expirationDate) => {
        if (!expirationDate) {
          return false;
        }
        const today = new Date().getFullYear().toString().substr(-2);

        const [expMonth] = expirationDate.split("/");
        if (Number(expMonth) > 12) {
          return false;
        }

        return true;
      }
    ),
  cvvNumber: Yup.string()
    .required("This field is required")
    .min(3, "Invalid cvv")
    .max(4, "Invalid cvv"),
});
