import moment from "moment";

export const minDateCalculated = (bookingInitialDate, PaymentDate) => {
  let initialDate = bookingInitialDate.split(" - ")[0];

  if (!PaymentDate) {
    return initialDate;
  }

  let a1 = moment(initialDate, "Do MMM, YYYY").format("YYYY-MM-DD");

  let a2 = moment(
    new Date(
      new Date(PaymentDate).setDate(new Date(PaymentDate).getDate() + 30)
    ).toISOString()
  ).format("YYYY-MM-DD");


  if (moment(a2).isSameOrBefore(moment(a1))) {
    return moment(a2).format("MM-DD-YYYY");
  } else {
    return moment(a1).format("MM-DD-YYYY");
  }
};
