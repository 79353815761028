import React from 'react';
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';
import { deleteApi, putApi } from '../../../../../../config/apiFunctions';
import { guide_delete_Id_Proof } from '../../../../../../config/endpoints';
import { SIGNUP_USER_DETAILS } from '../../../../../../redux/slices/auth/LoginSlice';

const DeleteIdProof = (props) => {
    const User = useSelector((state) => state.auth.SignupUser);
    const [btndisabled, setBtnDisable]=useState(false);
const toast = useToast();
const dispatch = useDispatch();

    const handleRemove = () => {
        setBtnDisable(true);
        // const obj={
        //     document_id:props.listDelete.id
        // }
        deleteApi(`${guide_delete_Id_Proof}?document_id=${props.listDelete.id}`,{}, User.access_token ).then(res=>{
            //update state
            const data = [...props.id_proofs];
            const newarr = data?.filter((item) => item.document_url !== props.listDelete.document_url);
            props.setId_proofs(newarr);
            setBtnDisable(false)
            props.onHide();
            props.setEdit();
            //update redux state
            const user = { ...User };
            const newobj =(user.userDocuments = newarr);
            dispatch(SIGNUP_USER_DETAILS(user));
            toast({
              title: res.data.message,
              status: "success",
               duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
        }).catch((err) => {
          toast({
            title: err.response.data.message,
            status: "error",
             duration: 30000,
            isClosable: true,
            position: "top-right",
            onCloseComplete: () => setBtnDisable(false),
          });
        });
       
      };

  return (
    <Modal
    {...props}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
<div className={props.show === true ? "": "modal fade"} id="cancel_trip" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className=" ">
    <div className="modal-content p-20">
        <div className="modal_header position-relative">
            <h3 className="text-grey-500 f-700 mt-5">Are you sure you want to delete your id proof ?</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={props.onHide}></button>
        </div>
        <div className="modal-body p-0">
                    <p className="text-grey mb-2 medium">if you choose to delete your ID proof that was previously submitted for verification, you will be unable to accept any new bookings until your identification document is re-submitted and successfully re-verified by our team</p>

        </div>
        <div className="modal-footer border-0 p-0">
            <a  className="btn-main text-red " onClick={props.onHide}>Not Now</a>
            <button disabled={btndisabled} className="btn-main btn-red" type="submit" onClick={handleRemove} >
            {btndisabled ? (
                      <ThreeDots
                        height="20"
                        width="80"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    ) : (
                      "Confirm"
                    )}
                </button>
        </div>
    </div>
</div>
</div>
</Modal>
  )
}

export default DeleteIdProof;
