import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer/combinedReducer/rootReducer";
import thunk from "redux-thunk";
import { applyMiddleware } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore(
  {
    reducer: persistedReducer,
    middleware: [thunk],
    // middleware: (getDefaultMiddleware) =>
    // getDefaultMiddleware({
    //   serializableCheck: {
    //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    //   }
    // })
  }

  // window.__REDUX_DEVTOOLS_EXTENSION__(applyMiddleware(thunk))
);

export const persistor = persistStore(store);

export default store;
