import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../../../../assets/images/profile-placeholder1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../../../config/apiFunctions";
import { single_guide, toprated_guide } from "../../../../../config/endpoints";
import location from "../../../../../assets/images/location.svg";
import {
  GUIDE_DETAILED_PAGE,
  TOPRATED_GUIDE,
} from "../../../../../redux/slices/TouristGuideReducer/guideSlice";
import { Star } from "../../../../../config/common/common";
import Pagination from "../../../../../config/pagination/Pagination";
import { Oval } from "react-loader-spinner";
import no_result from "../../../../../assets/images/no-result.png";
import SendEnquiriesModal from "../modal";
const TouristGuideList = ({
  pageCount,
  currentPage,
  setCurrentPage,
  show,
  isLoading,
  setLoading,
  setPagecount,
  squery,
  setShow,
}) => {
  const dispatch = useDispatch();
  const guide_List = useSelector(
    (state) => state.touristGuide.toprated_guide?.details
  );
  const User = useSelector((state) => state.auth.SignupUser);
  const pageNumberLimit = 4;
  const [maxPageLimit, setMaxPageLimit] = useState(4);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [recordsPerPage] = useState(10);
  const nPages = guide_List ? Math.ceil(pageCount / recordsPerPage) : [];
  const [showModal, setModalShow] = useState(false);

  const toprateddata = () => {
    setLoading(true);
    getApi(`${toprated_guide}?page_no=${currentPage}`).then((res) => {
      setLoading(false);
      setPagecount(res.data.data.counts);
      dispatch(TOPRATED_GUIDE(res.data.data));
      setShow();
    });
  };

  useEffect(() => {
    if (squery === null || squery === "") {
      toprateddata();
    }
  }, [currentPage, show]);

  return (
    <section className="tourist-list">
      <div className="container spacer-y pt-0">
        {isLoading === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
              margin: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        {isLoading === false && (
          <>
            {!guide_List ? (
              <section className="no-result">
                <div className="container spacer-y pt-0">
                  <div className="row justify-content-center text-center">
                    <div className="col-sm-6 col-lg-4">
                      <img
                        src={no_result}
                        className="mb-3"
                        alt=""
                        data-aos="fade-up"
                      />
                      <h2
                        className="primary__color mb-3 p-0"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        No Results Found
                      </h2>
                      <p data-aos="fade-up" data-aos-delay="200">
                        We couldn't find what you searched for. Try search
                        again.
                      </p>
                      <Link
                        onClick={() => {
                          setModalShow(true);
                        }}
                        className="button-primary"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        Send enquiry
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <>
                {guide_List?.map((list, i) => {
                  return (
                    <div key={i} className="tourist-wrapper" data-aos="fade-up">
                      <Link
                        to={`/guide_detailed_page/${list.user_id}`}
                        className="tour-guide-image"
                      >
                        <img
                          src={
                            !list.user.user_detail?.profile_picture
                              ? Icon
                              : list.user.user_detail?.profile_picture
                          }
                          alt=""
                        />
                      </Link>
                      <div className="tour-guide-content">
                        <div className="row gy-3 gy-md-0 mb-3 mb-md-0">
                          <div className="col-md-6 col-8">
                            <Link
                              to={`/guide_detailed_page/${list.user_id}`}
                              className="tour-guide-image"
                            >
                              <h5 className="primary__color mb-2 text-capitalize">
                                {list.user.last_name
                                  ? `${list.user.name} ${list.user.last_name}`
                                  : list.user.name}
                              </h5>
                            </Link>
                            <div
                              className="guide-location"
                              data-aos="fade-up"
                              data-aos-delay="100"
                            >
                              <div className="location-icon">
                                <img src={location} alt="" />
                              </div>
                              <div className="location-address dark-text text-capitalize">
                                {list?.user?.country}
                              </div>
                              <div className="location-address dark-text">
                                <p className="m-0 location-address dark-text">
                                  <span className="me-2">Zipcode:</span>
                                  {list?.user?.pincode}{" "}
                                </p>
                              </div>
                            </div>

                            {list?.avg_ratings !== "0" && (
                              <Star
                                stars={parseFloat(list.avg_ratings).toFixed(1)}
                              />
                            )}
                          </div>
                          <div className="col-md-6 col-4 text-end">
                            <p className="guide-cost">
                              {!list.user.user_detail?.price
                                ? "---"
                                : `$${list.user.user_detail?.price}/hr`}
                            </p>
                          </div>
                        </div>
                        <p>
                          {!list.user.user_detail?.bio
                            ? "no bio available"
                            : list.user.user_detail.bio}{" "}
                        </p>
                        {User.access_token &&
                          User.role_name === "TRAVELLER" && (
                            <Link
                              to="/dashboard/findeguide"
                              // state={{ id: list.user_id, show: true }}
                              className="button-primary small-button"
                            >
                              {/* Book Now */}
                              {/* Sample Itinerary */}
                              AI Itinerary
                            </Link>
                          )}
                        {User.access_token && User.role_name === "GUIDE" && (
                          <Link to="/" className="button-primary small-button">
                            Book Now
                          </Link>
                        )}
                        {!User.access_token && (
                          <Link
                            to="/login"
                            className="button-primary small-button"
                          >
                            Book Now
                          </Link>
                        )}
                      </div>
                    </div>
                  );
                })}

                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageNumberLimit={pageNumberLimit}
                  maxPageLimit={maxPageLimit}
                  minPageLimit={minPageLimit}
                  setMinPageLimit={setMinPageLimit}
                  setMaxPageLimit={setMaxPageLimit}
                />
              </>
            )}
          </>
        )}
      </div>
      <SendEnquiriesModal show={showModal} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default TouristGuideList;
