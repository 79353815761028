import React from "react";
// import {Link} from 'react-router-dom';

const ErrorPage = () => {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-sm-12 col-md-10 ">
            <div className="four_zero_four_bg">
              <h1 className="text-center ">404</h1>
            </div>

            <div className="contant_box_404">
              <h3 className="h2">Look like you're lost</h3>

              <p>the page you are looking for not available!</p>

              <a href="/" className="link_404">
                Go to Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ErrorPage;
