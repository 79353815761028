import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchlist: [],
  toprated_guide: [],
  guide_detailed_page: [],
  blog: [],
  singleBlog: [],
  addReview: [],
  guide_gallery: {},
  guide_received_bookings: [],
  guide_booking_history: [],
  traveler_notification: [],
  recieved_bookings: 0,
  booking_history: 0,
  payment: 0,
  unreadNotificationGuide: 0,
};
const guideSlice = createSlice({
  name: "guide_slice",
  initialState,
  reducers: {
    // SEARCH_GUIDE: (state, action) => {
    //   return { searchlist: action.payload };
    // },
    TOPRATED_GUIDE: (state, action) => {
      return { toprated_guide: action.payload };
    },
    GUIDE_DETAILED_PAGE: (state, action) => {
      return { ...state, guide_detailed_page: action.payload };
    },
    BLOG: (state, action) => {
      return { blog: action.payload };
    },
    SINGLE_BLOG: (state, action) => {
      return { ...state, singleBlog: action.payload };
    },
    ADDREVIEW: (state, action) => {
      return { ...state, addReview: action.payload };
    },
    Guide_Gallery: (state, action) => {
      return { ...state, guide_gallery: action.payload };
    },
    DELETE_GUIDE_GALLERY: (state, action) => {
      return { ...state, guide_gallery: action.payload };
    },
    GUIDE_RECEIVED_BOOKINGS: (state, action) => {
      return { ...state, guide_received_bookings: action.payload };
    },
    GUIDE_BOOKING_HISTORY: (state, action) => {
      return { ...state, guide_booking_history: action.payload };
    },
    RECIEVED_BOOKINGS: (state, action) => {
      return { ...state, recieved_bookings: action.payload };
    },
    BOOKING_HISTORY: (state, action) => {
      return { ...state, booking_history: action.payload };
    },
    TRAVELER_NOTIFICATION: (state, action) => {
      return { ...state, traveler_notification: action.payload };
    },
    PAYMENT_NOTIFICATIONS: (state, action) => {
      return { ...state, payment: action.payload };
    },
    UNREAD_NOTIFICATIONS_GUIDE: (state, action) => {
      return { ...state, unreadNotificationGuide: action.payload };
    },
  },
});
export const {
  TOPRATED_GUIDE,
  GUIDE_DETAILED_PAGE,
  BLOG,
  PAYMENT_NOTIFICATIONS,
  SINGLE_BLOG,
  ADDREVIEW,
  Guide_Gallery,
  DELETE_GUIDE_GALLERY,
  GUIDE_RECEIVED_BOOKINGS,
  GUIDE_BOOKING_HISTORY,
  TRAVELER_NOTIFICATION,
  RECIEVED_BOOKINGS,
  BOOKING_HISTORY,
  UNREAD_NOTIFICATIONS_GUIDE,
} = guideSlice.actions;
export default guideSlice.reducer;
