import React, { useEffect, useState } from "react";
// import galleryimg12 from "../../../../../../../assets/images/gallery-img-12.jpg";
// import galleryimg13 from "../../../../../../../assets/images/gallery-img-13.jpg";
// import galleryimg14 from "../../../../../../../assets/images/gallery-img-14.jpg";
// import galleryimg15 from "../../../../../../../assets/images/gallery-img-15.jpg";
// import galleryimg16 from "../../../../../../../assets/images/gallery-img-16.jpg";
// import galleryimg17 from "../../../../../../../assets/images/gallery-img-17.jpg";
// import galleryimg18 from "../../../../../../../assets/images/gallery-img-18.jpg";
// import galleryimg19 from "../../../../../../../assets/images/gallery-img-19.jpg";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Chart from "./Bar";
import { Oval, TailSpin } from "react-loader-spinner";
import { getApi } from "../../../../../../../config/apiFunctions";
import {
  guide_dashboard_count,
  guide_dashboard_gallery,
  guide_gallery,
  guide_todays_trip,
} from "../../../../../../../config/endpoints";
import moment from "moment";

const GuidedashboardPage = () => {
  const User = useSelector((state) => state.auth.SignupUser);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [data, setData] = useState({});
  const [gallery, setGallery] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [PageLoading, setPageLoading] = useState(true);
  const [count, setCount] = useState(null);

  useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 400);
  }, []);
  Fancybox.bind("#gallery a", {
    groupAll: true,
  });
  const ScheduledTrip = () => {
    setLoading(true);
    getApi(guide_todays_trip, {}, User.access_token).then((res) => {
      setLoading(false);
      setData(res.data.data);
    });
  };
  const getGallery = () => {
    getApi(guide_dashboard_gallery, {}, User.access_token).then((res) => {
      setGallery(res.data.data.rows);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);
    }, 300);
    ScheduledTrip();
    getGallery();
    getCounts();
  }, []);
  const getCounts = () => {
    getApi(guide_dashboard_count, {}, User.access_token).then((res) => {
      setCount(res.data.data);
    });
  };

  return (
    <>
      <div className={isLoading === true ? "loader_center" : ""}>
        {PageLoading === true && (
          <div
            className="loader_wrapper"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Oval
              height={80}
              width={80}
              color="#1f74ca"
              wrapperStyle={{}}
              wrapperclassName=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1f74ca8f"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}
        <div>
          {PageLoading === false && (
            <div className="custom_box">
              <div className="stats_boxes d-flex justify-content-start">
                <div
                  className="stats_box bg-green d-flex align-items-start justify-content-start flex-column me-3"
                  data-aos="fade-up"
                >
                  <div className="stats_icon ms-auto">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.92969 15.8792L15.8797 3.9292"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.1011 18.2791L12.3011 17.0791"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.793 15.5887L16.183 13.1987"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.60127 10.239L10.2413 3.599C12.3613 1.479 13.4213 1.469 15.5213 3.569L20.4313 8.479C22.5313 10.579 22.5213 11.639 20.4013 13.759L13.7613 20.399C11.6413 22.519 10.5813 22.529 8.48127 20.429L3.57127 15.519C1.47127 13.419 1.47127 12.369 3.60127 10.239Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 21.9985H22"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="stats_content">
                    <h6 className="small-text text-black mb-1">
                      Total withdraw amount
                    </h6>
                    <span className="stat_price text-black f-700">
                      ${count?.totalIncome}
                    </span>
                  </div>
                </div>
                <div
                  className="stats_box bg-pink d-flex align-items-start justify-content-start flex-column me-3"
                  data-aos="fade-up"
                >
                  <div className="stats_icon ms-auto">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 2V5"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 2V5"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4 9.08984H21"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21.5 8.5V17C21.5 20 20 22 16.5 22H8.5C5 22 3.5 20 3.5 17V8.5C3.5 5.5 5 3.5 8.5 3.5H16.5C20 3.5 21.5 5.5 21.5 8.5Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.1947 13.7002H16.2037"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.1947 16.7002H16.2037"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4955 13.7002H12.5045"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.4955 16.7002H12.5045"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.79431 13.7002H8.80329"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.79431 16.7002H8.80329"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="stats_content">
                    <h6 className="small-text text-black mb-1">
                      Total Bookings
                    </h6>
                    <span className="stat_price text-black f-700">
                      {count?.totalBooking}
                    </span>
                  </div>
                </div>
                <div
                  className="stats_box bg-purple d-flex align-items-start justify-content-start flex-column mb-0"
                  data-aos="fade-up"
                >
                  <div className="stats_icon ms-auto">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.4098 4C18.3498 4 19.9098 5.57 19.9098 7.5C19.9098 9.39 18.4098 10.93 16.5398 11C16.4598 10.99 16.3698 10.99 16.2798 11"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.16021 14.56C1.74021 16.18 1.74021 18.82 4.16021 20.43C6.91021 22.27 11.4202 22.27 14.1702 20.43C16.5902 18.81 16.5902 16.17 14.1702 14.56C11.4302 12.73 6.92021 12.73 4.16021 14.56Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.3398 20C19.0598 19.85 19.7398 19.56 20.2998 19.13C21.8598 17.96 21.8598 16.03 20.2998 14.86C19.7498 14.44 19.0798 14.16 18.3698 14"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="stats_content">
                    <h6 className="small-text text-black mb-1">Total Users</h6>
                    <span className="stat_price text-black f-700">
                      {count?.totalUsers}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5  mt-4 w-100">
                <div
                  className="white_box_wrapper p-fix w-100"
                  data-aos="fade-up"
                >
                  {isDisplayed === true ? (
                    <Chart />
                  ) : (
                    <div className="d-flex justify-content-center ">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperclassName=""
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 d-flex mb-3 mb-md-0">
                  {isLoading === true && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "auto",
                        marginBottom: "auto",
                        margin: "auto",
                      }}
                    >
                      <Oval
                        height={80}
                        width={80}
                        color="#1f74ca"
                        wrapperStyle={{}}
                        wrapperclassName=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#1f74ca8f"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}
                  {isLoading === false && (
                    <div
                      className="white_box_wrapper schedule_inner w-100"
                      data-aos="fade-up"
                    >
                      <div className="heading_box d-flex align-items-center justify-content-between">
                        <h5 className="text-secondary mb-0 f-700">
                          Today's Schedule
                        </h5>
                        <Link
                          className="text-grey small-text"
                          to="booking-history"
                        >
                          See All{" "}
                          <i className="extra-small-text ms-1 fa-solid fa-angle-right"></i>
                        </Link>
                      </div>
                      {Object.keys(data).length !== 0 ? (
                        <div className="schedule_list mt-4">
                          {data?.rows?.map((list, i) => {
                            return (
                              <div
                                key={i}
                                className="d-flex align-items-center justify-content-start mb-3"
                              >
                                <span className="space_bar"></span>
                                <div className="schedule_inner_content min-0">
                                  <h6 className="small-text text-dark-light mb-1 f-700">
                                    {list.last_name
                                      ? `${list.first_name} ${list.last_name}`
                                      : list.first_name}
                                  </h6>
                                  <p className="extra-small-text text-grey text-overflow mb-1">
                                    {/* {list.booking_start} */}
                                    {moment(
                                      list.booking_start,
                                      "dddd, DD MMMM, YYYY"
                                    ).format("MM-DD-YYYY")}
                                  </p>
                                  <p className="extra-small-text text-grey text-overflow mb-0 text-capitalize">
                                    {list?.country}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="schedule_list mt-4">
                          <div className="schedule_inner_content min-0">
                            <h6 className="large-text text-dark-light mb-1 f-700 d-flex justify-content-center mt-5">
                              There are no trips scheduled for today.
                            </h6>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* <div className="col-md-5 d-flex">
                  <div className="white_box_wrapper w-100" data-aos="fade-up">
                    <div className="heading_box d-flex align-items-center justify-content-between">
                      <h5 className="text-secondary mb-0 f-700">Gallery</h5>
                      <Link to="gallery" className="text-grey small-text">
                        View All{" "}
                        <i className="extra-small-text ms-1 fa-solid fa-angle-right"></i>
                      </Link>
                    </div>
                    <div className="mt-4">
                      <div
                        id="gallery"
                        className="gallery_inner d-flex align-items-center flex-wrap"
                      >
                        {gallery?.length > 0 ? (
                          <>
                            {gallery?.map((list, i) => {
                              return (
                                <div
                                  key={i}
                                  className="img-overlay-wrap image_box position-relative"
                                >
                                  <a
                                    href={list.file_url}
                                    data-fancybox="gallery"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={list.file_url}
                                      alt="gallery-img"
                                    />
                                  </a>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="schedule_list mt-4">
                            <div className="schedule_inner_content min-0">
                              <h6 className=" d-flex justify-content-center large-text text-dark-light mb-1 f-700 d-flex justify-content-center">
                                No Photos Added.
                              </h6>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GuidedashboardPage;
